<template>
  <div id="site-permissions-page" v-if="site != null">
    <div class="flex justify-between mt-10">
      <h2 class="intro-y text-lg font-medium">
        <span v-if="site.name != null">{{ site.name }}的</span>職能權限 (共{{ roles.length }}個)
      </h2>

      <router-link :to="`/management/sites/${site.site_id}/roles`" type="button" class="btn btn-outline-secondary w-24">返回</router-link>
    </div>

    <div v-if="checkPermission(permissions, 'system.role_permission.view')">
      <div class="grid grid-cols-12 gap-6 mt-5 mb-5">
        <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
          <div class="hidden md:block mx-auto text-slate-500">
          </div>
          <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
            <div class="w-56 relative text-slate-500">
              <input v-model="search" type="text" class="form-control w-56 box pr-10" placeholder="搜尋" @keyup.enter="onSearch()">
              <font-awesome-icon @click="onSearch()" icon="fa-solid fa-magnifying-glass" class="absolute my-auto w-4 h-4 mr-3 right-0 inset-y-0"/>
            </div>
          </div>
        </div>

        <!-- BEGIN: Data List -->
        <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="whitespace-nowrap"></th>
              <th class="whitespace-nowrap" v-for="(role, i) in roles" :key="i">
                  <div class="flex flex-column align-items-center">
                    <span>{{ role.name }}</span>
                    <div class="btn-container" v-if="!role.is_default && checkPermission(permissions, 'system.role_permission.edit')">
                        <button type="button" class="btn btn-primary mr-1" @click="selectAll(role)">全選</button>
                        <button type="button" class="btn btn-secondary" @click="unSelectAll(role)">清空</button>
                    </div>
                  </div>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr class="" v-for="(item, key) in filterPermissions" :key="key" :class="{'border-top-bold': item.isNew}">
              <td>{{ item.name }}</td>
              <td v-for="(role, i) in roles" :key="i">
                <div class="form-switch text-center">
                  <input v-model="role.permissions" :value="item.permission_id" type="checkbox" class="form-check-input" @change="onChange(role)" :disabled="role.is_default ||  !checkPermission(permissions, 'system.role_permission.edit')">
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- END: Data List -->
      </div>

    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>

  </div>
</template>

<script>
export default {
  props: ['auth', 'permissions', 'currentSite'],
  data() {
    return {
      mounted: false,
      site: null,
      allPermissions: [],
      filterPermissions: [],

      roles: [],
      search: null
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    async currentSite(val) {
      await this.init()
    },
    search(val) {
      if (val === '') {
        this.onSearch()
      }
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.loadSite()
        await this.loadPermissions()
        await this.loadRoles()

        setTimeout(() => {
          this.mounted = true
        }, 500)
      }
    },
    async loadSite() {
      this.site = await this.getSite(this.$route.params.siteId)
    },
    async loadPermissions() {
      const allPermissions = await this.getPermissions()
      this.allPermissions = allPermissions.filter(permission => {
        return this.site.site_id === 1 ||
          (
            permission.code === 'site.manage' ||
            permission.code.startsWith('site.manage.') ||
            permission.code.startsWith('site.user-group.') ||
            // permission.code.startsWith('site.banner.') ||
            permission.code.startsWith('site.media-library.') ||
            permission.code.startsWith('site.menu-shortcut.') ||
            permission.code.startsWith('centre.performance-report.') ||
            (this.site.is_announcement_enabled && (permission.code.startsWith('site.announcement-type.') || permission.code.startsWith('site.announcement.'))) ||
            (this.site.is_calendar_enabled && (permission.code.startsWith('site.calendar.') || permission.code.startsWith('site.calendar-event.'))) ||
            (this.site.is_phone_directory_enabled && (permission.code.startsWith('site.phone_directory.'))) ||
            (this.site.is_form_enabled && (permission.code.startsWith('site.form.'))) ||
            (this.site.is_resource_booking_enabled && (permission.code.startsWith('site.resource.') || permission.code.startsWith('site.resource-booking.'))) ||
            (this.site.is_knowledge_base_enabled && (permission.code.startsWith('site.knowledge-type.') || permission.code.startsWith('site.knowledge_base.'))) ||
            (this.site.is_forum_enabled && (permission.code.startsWith('site.forum-category.') || permission.code.startsWith('site.forum.') || permission.code.startsWith('site.forum-post.')))
          )
      })

      for (let i = 1; i < this.allPermissions.length; i++) {
        const code = this.allPermissions[i].code.split('.')
        const prevCode = this.allPermissions[i - 1].code.split('.')
        this.allPermissions[i].isNew = (code[0] !== prevCode[0] || code[1] !== prevCode[1])
      }

      this.filterPermissions = this.allPermissions
    },
    async loadRoles() {
      this.roles = await this.getRoles(this.$route.params.siteId)

      for (let i = 0; i < this.roles.length; i++) {
        this.loadRole(i)
      }
    },
    async loadRole(i) {
      const role = await this.getRole(this.roles[i].role_id)
      this.$set(this.roles[i], 'permissions', role.permissions.map(a => a.permission_id))
    },
    onSearch() {
      const val = this.search.toLowerCase()

      if (val == null || val === '') {
        this.filterPermissions = this.allPermissions
      } else {
        this.filterPermissions = this.allPermissions.filter(o => (o.name != null && o.name.toLowerCase().includes(val)) || (o.description != null && o.description.toLowerCase().includes(val)))
      }
    },
    selectAll(role) {
      const permissions = this.allPermissions.map((a) => { return a.permission_id })
      role.permissions = permissions
      this.onChange(role)
    },
    unSelectAll(role) {
      role.permissions = []
      this.onChange(role)
    },
    async onChange(role) {
      const permissions = role.permissions.map((a) => { return { permission_id: a } })

      const response = await this.updateRolePermission(role.role_id, { permissions })

      let option = {
        title: '成功',
        message: `${role.name}的權限已被更改`,
        type: 'success'
      }

      if (response.code !== 0) {
        option = {
          title: `未能更改${role.name}的權限`,
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-container {
    button {
        padding: 2px 10px;
    }
}
.border-top-bold {
  border-top: 2px solid #a1a1a1
}
</style>
