<template>
  <div id="create-data-page">
    <h2 class="intro-y text-lg font-medium mt-10">
      遞交iManage 帳戶申請/更新/刪除
    </h2>

    <div v-if="checkPermission(permissions, 'system.workflow.account-workflow')" class="grid grid-cols-12 gap-6 mt-5">
      <div class="col-span-12 lg:col-span-6">
        <account-workflow-form :permissions="permissions" :all-sites="allSites" :current-site="currentSite"></account-workflow-form>
      </div>
    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'allSites', 'currentSite', 'permissions'],
  data() {
    return {
      mounted: false
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    permissions() {
      this.init()
    },
    currentSite() {
      this.init()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }
    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        this.mounted = true
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
