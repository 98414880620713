<template>
  <div id="performance-report-page">

    <div class="intro-y flex justify-between sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        執行報告回答記錄
      </h2>

      <router-link type="button" to="/management/performance-reports" class="btn btn-outline-secondary mr-2">返回</router-link>
    </div>

    <div v-if="checkPermission(permissions, 'system.performance-report.manage') && version != null" class="mt-4">

      <div v-if="section">
        <h3>
          {{ section.section_name_zh }}<br>
          {{ section.section_name_en }}
        </h3>

        <div v-for="(table, tableKey) in section.tables" :key="tableKey">
          <div class="tab-content border-l border-r border-b mb-5 mt-5">
            <div class="tab-pane leading-relaxed p-5 active">
              <div class="flex justify-evenly align-items-start mb-4">
                <div class="flex-1 text-center mb-2">
                  <h3>{{ table.table_name_zh }}</h3>
                  <h5>{{ table.table_name_en }}</h5>
                </div>
              </div>

              <div class="overflow-x-auto">
                <table class="table table-striped">
                  <tbody>
                  <template v-for="(row, key) in table.rows">
                    <tr :key="key" :class="{'font-bold': row.is_header}">
                      <td v-for="(field, i) in row.fields" :key="i" :colspan="field.colspan" :rowspan="field.rowspan">
                        <div v-html="field.value"></div>
                        <div class="text-center" v-if="!row.is_header && !field.is_default">{{ field.data != null && field.data.value != null ? field.data.value : '-'}}</div>
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>

                <div class="mt-4" v-html="table.footer"></div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div v-if="!loading && !finish" class="flex justify-between align-items-center">
        <button v-if="sectionIndex > 0" class="btn btn-secondary" type="button" @click="changeSection(-1)"> 上一部分
        </button>
        <div v-else></div>

        <button v-if="sectionIndex < sections.length - 1" class="btn btn-secondary" type="button" @click="changeSection(1)"> 下一部分
        </button>
      </div>
    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'permissions'],
  data() {
    return {
      mounted: false,

      version: null,
      sections: [],
      section: null,

      sectionIndex: 0,

      errors: {},

      loading: false,
      finish: false
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    permissions() {
      this.init()
    }
  },
  async mounted() {
    window.tailwind.svgLoader()

    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.permissions) {
        await this.loadVersion()
        await this.loadSections()
        await this.loadSection()
        this.mounted = true
      }
    },
    async loadVersion() {
      const response = await this.getPerformanceReportVersion(this.$route.params.versionId ?? 0)
      if (response.code === 0) {
        this.version = response.data
      }
    },
    async loadSections() {
      const response = await this.getPerformanceReportSections()
      this.sections = response.data
    },
    async loadSection() {
      if (this.sections.length > 0) {
        this.section = await this.getPerformanceReportSection(this.sections[this.sectionIndex].section_id, this.version.version_id)
      }
    },
    async changeSection(num) {
      this.section = null
      this.sectionIndex += num
      await this.loadSection()
    }
  }
}
</script>
<style lang="scss" scoped>
#performance-report-page {
  h3 {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  h4 {
    font-size: 16px;
  }

  .tab-content {
    background-color: #FFF;

    table {
      table-layout: fixed;

      tbody {
        tr {
          td {
            height: inherit;
            background-color: #fff;
            border-right-width: 1px;
            vertical-align: top;

            .content-action {
              flex: 0 0 104px;
            }

            .link {
              color: blue;
              text-decoration: underline;
            }
          }

          &:last-child {
            td {
              border-bottom-width: 0;

              &:last-child {
                border-right-width: 0;
              }
            }
          }

          &.font-bold td {
            text-align: center;
            border-right-width: 2px;
            background-color: rgb(var(--color-slate-100) / var(--tw-bg-opacity));
          }
        }
      }

      tr, td {
        height: 0;
      }

      @-moz-document url-prefix() {
        tr, th, td {
          height: 100%;
        }
      }
    }
  }
}
</style>
