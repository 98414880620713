<template>
  <!-- BEGIN: Library Modal -->
  <div id="library-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="font-medium text-base mr-auto">
            選擇檔案
          </h2>
        </div>
        <div class="modal-body p-0">
          <library-page :auth="auth" :current-site="currentSite" :permissions="permissions" :profile="profile" :modal="true" :modal-filter="modalFilter" :modal-folder-code="folderCode" @select="onSelectFile"></library-page>
          <div class="px-5 pb-8 text-center mt-4">
            <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">關閉
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Library Modal -->
</template>

<script>
import LibraryPage from '@/page/library/LibraryPage.vue'

export default {
  components: { LibraryPage },
  props: ['auth', 'currentSite', 'permissions', 'profile', 'modalFilter', 'folderCode'],
  data() {
    return {
    }
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    onShow() {
      const el = document.querySelector('#library-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    onSelectFile(item) {
      this.$emit('on-select', item)

      const el = document.querySelector('#library-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()
    }
  }
}
</script>
<style lang="scss" scoped>

#file-insector {
}
</style>
