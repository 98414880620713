<template>
  <div id="resource-booking-page">

    <div class="flex justify-end mt-8">
      <router-link to="/resource-bookings" type="button" class="btn btn-outline-secondary w-24">返回</router-link>
    </div>

    <div v-if="checkPermission(permissions, ['site.resource-booking.view'])">
      <div v-if="data != null" class="intro-y xl:w-3/5 p-5 box">
        <div class="mt-3">
          <label for="user" class="form-label">員工</label>
          <div class="w-full">{{ data.user ? data.user.display_name : '-' }}</div>
        </div>

        <div class="mt-3">
          <label for="resource" class="form-label">資源</label>
          <div class="w-full">
            <router-link :to="`/resources/${data.resource_id}`" class="text-primary">{{ data.resource ? data.resource.name : '-' }}</router-link>
          </div>
        </div>

        <div class="mt-3">
          <label for="description" class="form-label">簡介</label>
          <div class="w-full">{{ data.description ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label for="last_name" class="form-label">備註</label>
          <div class="w-full">{{ data.remark ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label for="is_whole_day" class="form-label">全日</label>
          <div class="w-full">{{ data.is_whole_day ? '是' : '否' }}</div>
        </div>

        <div class="mt-3">
          <label for="from_time" class="form-label">借用日期時間</label>
          <div class="w-full">{{ data.from_time ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label for="to_time" class="form-label">歸還日期時間</label>
          <div class="w-full">{{ data.to_time ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label for="people_num" class="form-label">人數</label>
          <div class="w-full">{{ data.people_num ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label for="prepare_tea" class="form-label">備茶</label>
          <div class="w-full">{{ data.prepare_tea ? '是' : '否' }}</div>
        </div>

        <div class="mt-3">
          <label for="borrower" class="form-label">借用人/單位</label>
          <div class="w-full">{{ data.borrower ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label for="prepare_cup" class="form-label">備杯</label>
          <div class="w-full">{{ data.prepare_cup ? '是' : '否' }}</div>
        </div>

        <div class="mt-3">
          <label for="host" class="form-label">會議主持人</label>
          <div class="w-full">{{ data.host ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label for="conditioner" class="form-label">冷氣</label>
          <div class="w-full">{{ data.conditioner ?? '-' }}</div>
        </div>

        <div v-if="false" class="mt-3">
          <label for="booking_status" class="form-label">狀態</label>
          <div class="w-full" v-if="data">{{ getObjectValue(allStatus, 'value', data.booking_status, 'name') }}</div>
        </div>

        <div class="flex mt-5 pt-5 border-t border-slate-200/60 dark:border-darkmode-400">
          <div class="intro-y flex flex-1 text-xs sm:text-sm flex-col sm:flex-row items-center">
            <div class="flex items-center">
              <div v-if="data.user != null" class="w-12 h-12 flex-none image-fit">
                <img alt="" class="rounded-full" :src="data.user.avatar_url != null && data.user.avatar_url !== '' ? data.user.avatar_url : '/images/user.png'">
              </div>
              <div class="ml-3 mr-auto">
                <div v-if="data.user != null" class="font-medium">{{ data.user.display_name }}</div>
                <div class="text-slate-500 text-xs">建立日期：{{ $moment(data.created_at).format('YYYY-MM-DD HH:mm') }}</div>
                <div class="text-slate-500 text-xs">上次修改日期：{{ $moment(data.updated_at).format('YYYY-MM-DD HH:mm') }}<span v-if="data.updatedUser != null">，修改者：{{ data.updatedUser.display_name }}</span></div>
              </div>
            </div>
          </div>
          <div class="intro-y flex justify-end items-end">
            <router-link v-if="profile?.user_id === data.user_id || checkPermission(permissions, ['site.resource-booking.edit'])" :to="`/update-resource-booking/${data.booking_id}`" class="flex items-center mr-3">
              <font-awesome-icon icon="fa-solid fa-pen-to-square" />
              &nbsp;更改 </router-link>
            <button v-if="profile?.user_id === data.user_id || checkPermission(permissions, ['site.resource-booking.delete'])" class="flex items-center text-danger" @click="openDeleteModal()">
              <font-awesome-icon icon="fa-regular fa-trash-can" />
              &nbsp;刪除 </button>
          </div>
        </div>

      </div>
    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>

    <!-- BEGIN: Delete Confirmation Modal -->
    <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger" />
              <div class="text-3xl mt-5">你確定嗎？</div>
              <div class="text-slate-500 mt-2">
                你是否要刪除此預借？
                <br>
                這動作不能還原。
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
              <button type="button" class="btn btn-danger w-24" @click="onDelete">確定</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
  </div>
</template>

<script>
export default {
  props: ['auth', 'currentSite', 'permissions', 'profile'],
  data() {
    return {
      mounted: false,
      data: null,
      resource: null,
      user: null,

      allStatus: [
        { name: '全部', value: 'ALL' },
        { name: '已批准', value: 'Accepted' },
        { name: '已拒絕', value: 'Rejected' },
        { name: '等待審批', value: 'Pending' }
      ]
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    async currentSite(val) {
      await this.init()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.loadData()

        this.mounted = true
      }
    },
    async loadData() {
      this.data = await this.getSiteResourceBooking(this.currentSite.site_id, this.$route.params.bookingId)
    },
    openDeleteModal() {
      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDelete() {
      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      const response = await this.deleteSiteResourceBooking(this.currentSite.site_id, this.data.booking_id)

      let option = {
        title: '已成功刪除預借',
        message: '請稍後，將會轉至預借頁面',
        type: 'success'
      }

      if (response.code === 0) {
        setTimeout(() => {
          this.$router.push('/resource-bookings')
        }, 1000)
      } else {
        option = {
          title: '未能刪除預借',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    }
  }
}
</script>

<style lang="scss" scoped>
#resource-booking-page {
  .leading-relaxed {
    min-height: 400px;
  }
}
</style>
