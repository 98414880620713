<template>
  <div id="form-form">
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">

          <div class="">
            <label for="name" class="form-label"><span class="text-danger">*</span> 名稱</label>
            <input v-model="form.name" id="description" type="text" class="form-control w-full" placeholder="請輸入名稱">
            <div v-if="'name' in errors" class="text-danger mt-2" v-html="errors.name"></div>
          </div>

          <div class="mt-3">
            <label for="description" class="form-label">簡介</label>
            <textarea v-model="form.description" id="description" type="text" class="form-control w-full" placeholder="請輸入簡介" rows="4"></textarea>
            <div v-if="'description' in errors" class="text-danger mt-2" v-html="errors.description"></div>
          </div>

          <div class="mt-3">
            <label for="is_published" class="form-label">公開</label>
            <div class="form-switch mt-2">
              <input id="is_published" v-model="form.is_published" type="checkbox" class="form-check-input">
            </div>

            <div v-if="'is_published' in errors" class="text-danger mt-2" v-html="errors.is_published"></div>
          </div>

          <div class="mt-3">
            <label for="display_at" class="form-label">開始日期時間</label>
            <div id="display_at">
              <date-picker v-model="from_time" type="datetime" placeholder="請選擇問卷開始日期時間" input-class="form-control w-full" :disabled-date="disabledStartDate" :disabled-time="disabledStartTime" :time-picker-options="{start: '00:00', step:'00:30' , end: '23:00', format: 'HH:mm'}" format="YYYY-MM-DD HH:mm"></date-picker>
            </div>
            <div v-if="'from_time' in errors" class="text-danger mt-2" v-html="errors.from_time"></div>
          </div>

          <div class="mt-3">
            <label for="end_at" class="form-label">完結日期時間</label>
            <div id="end_at">
              <date-picker v-model="to_time" type="datetime" placeholder="請選擇問卷結束日期時間" input-class="form-control w-full" :disabled-date="disabledEndDate" :disabled-time="disabledEndTime" :time-picker-options="{start: '00:00', step:'00:30' , end: '23:30', format: 'HH:mm'}" format="YYYY-MM-DD HH:mm"></date-picker>
            </div>
            <div v-if="'to_time' in errors" class="text-danger mt-2" v-html="errors.to_time"></div>
          </div>

          <div class="mt-3">
            <div class="sm:grid grid-cols-2 gap-2">
              <div>
                <label class="form-label">只限特定用戶/組別回覆</label>
                <div class="form-switch mt-2">
                  <input v-model="form.is_private" type="checkbox" class="form-check-input">
                </div>

                <div v-if="'is_private' in errors" class="text-danger mt-2" v-html="errors.is_private"></div>
              </div>
            </div>
          </div>

          <div class="mt-3" v-if="form.is_private">
            <label class="form-label">用戶</label>
            <span v-show="userOptions == null"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
            <multi-select v-show="userOptions != null" v-model="users" :options="userOptions ?? []" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true" placeholder="以名搜尋" selectLabel="按下Enter以選擇" label="searchLabel" track-by="user_id" :preselect-first="false">
              <template slot="noResult"><span class="multiselect__option">沒有結果，請輸入其他字</span></template>
            </multi-select>
            <div v-if="'users' in errors" class="text-danger mt-2" v-html="errors.users"></div>
          </div>

          <div class="mt-3" v-if="form.is_private">
            <label class="form-label">組別</label>
            <span v-show="groupOptions == null"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
            <multi-select v-show="groupOptions != null" v-model="groups" :options="groupOptions ?? []" :multiple="true" :close-on-select="false"  placeholder="以名搜尋" selectLabel="按下Enter以選擇" label="display_name" track-by="group_id" :preselect-first="false">
              <template slot="noResult"><span class="multiselect__option">沒有結果，請輸入其他字</span></template>
            </multi-select>
            <div v-if="'groups' in errors" class="text-danger mt-2" v-html="errors.groups"></div>
          </div>

          <div class="mt-3">
            <div class="sm:grid grid-cols-2 gap-2">
              <div>
                <label class="form-label">允許多次回覆</label>
                <div class="form-switch mt-2">
                  <input v-model="form.is_multi_submit" type="checkbox" class="form-check-input">
                </div>

                <div v-if="'is_multi_submit' in errors" class="text-danger mt-2" v-html="errors.is_multi_submit"></div>

                <div class="mt-3" v-if="form.is_multi_submit">
                  <label for="description" class="form-label">可回覆次數（空置或0代表沒上限）</label>
                  <input v-model="form.quota" id="description" type="number" class="form-control w-full" placeholder="請輸入次數">
                  <div v-if="'quota' in errors" class="text-danger mt-2" v-html="errors.quota"></div>
                </div>
              </div>

              <div>
                <label class="form-label">允許修改回覆</label>
                <div class="form-switch mt-2">
                  <input v-model="form.is_response_editable" type="checkbox" class="form-check-input">
                </div>

                <div v-if="'is_response_editable' in errors" class="text-danger mt-2" v-html="errors.is_response_editable"></div>
              </div>
            </div>
          </div>

          <div class="flex justify-end align-items-center mt-5">
            <router-link v-if="!loading && !finish" to="/forms" type="button" class="btn btn-outline-secondary w-24 mr-1">取消</router-link>
            <button v-if="!loading && !finish" type="button" class="btn btn-primary w-24" @click="onSave">儲存</button>
            <span v-show="loading && !finish"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['site', 'data'],
  data() {
    return {
      mounted: false,

      mode: 'create',
      resources: [],
      form: {
        name: null,
        description: null,
        is_published: true,
        from_time: null,
        to_time: null,
        quota: null,
        is_private: false,
        is_multi_submit: true,
        is_response_editable: true
      },
      users: [],
      groups: [],

      userOptions: null,
      groupOptions: null,

      from_time: null,
      to_time: null,

      errors: {},
      loading: false,
      finish: false
    }
  },
  watch: {
    site() {
      this.init()
    },
    userOptions() {
      if (this.data) {
        this.users = this.userOptions.filter((x) => {
          for (let i = 0; i < this.data.users.length; i++) {
            if (this.data.users[i].user_id === x.user_id && !this.data.users[i].is_permission_from_group) {
              return true
            }
          }
          return false
        })
      }
    },
    groupOptions() {
      if (this.data) {
        this.groups = this.groupOptions.filter((x) => {
          for (let i = 0; i < this.data.groups.length; i++) {
            if (this.data.groups[i].group_id === x.group_id) {
              return true
            }
          }
          return false
        })
      }
    },
    data(val) {
      if (val != null) {
        this.init()
      }
    },
    'form.is_private'(val) {
      if (val) {
        setTimeout(() => {
          window.tailwind.svgLoader()
        }, 1)
      }
    }
  },
  async mounted() {
    window.tailwind.svgLoader()

    this.init()
  },
  methods: {
    async init() {
      if (this.data) {
        this.form = this.data
        this.from_time = this.form.from_time != null ? this.$moment(this.form.from_time).toDate() : null
        this.to_time = this.form.to_time != null ? this.$moment(this.form.to_time).toDate() : null

        this.mode = 'update'
      }

      if (this.site && !this.mounted) {
        this.mounted = true

        this.loadUsers()
        this.loadUserGroups()
      }
    },
    async loadUsers() {
      const users = await this.getAllUsers()

      for (let i = 0; i < users.length; i++) {
        users[i].searchLabel = this.getUserSearchLabel(users[i])
      }

      this.userOptions = users
    },
    async loadUserGroups() {
      this.groupOptions = await this.getUserGroups(this.site.site_id)
    },
    disabledStartDate(date) {
      return this.datepickerDisabledStartDate(date, this.to_time)
    },
    disabledStartTime(date) {
      return this.datepickerDisabledStartTime(date, this.to_time)
    },
    disabledEndDate(date) {
      return this.datepickerDisabledEndtDate(date, this.from_time)
    },
    disabledEndTime(date) {
      return this.datepickerDisabledEndTime(date, this.from_time)
    },
    async onSave() {
      this.loading = true
      this.errors = {}

      this.form.from_time = this.from_time != null ? this.$moment(this.from_time).format() : null
      this.form.to_time = this.to_time != null ? this.$moment(this.to_time).format() : null

      this.form.users = this.users
      this.form.groups = this.groups

      let response

      if (this.mode === 'create') {
        response = await this.createSiteForm(this.site.site_id, this.form)
      } else {
        response = await this.updateSiteForm(this.site.site_id, this.data.form_id, this.form)
      }

      if ('errors' in response.data) {
        this.errors = response.data.errors
        this.loading = false
      } else {
        let option = {
          title: (this.mode === 'create') ? '已成功新增' : '已成功更改問巻/電子表格',
          message: '請稍後，將會轉至問巻/電子表格頁面',
          type: 'success'
        }

        if (response.code === 0) {
          this.finish = true

          setTimeout(() => {
            this.$router.push('/forms')
          }, 1000)
        } else {
          this.loading = false
          option = {
            title: (this.mode === 'create') ? '未能新增問巻/電子表格' : '未能更改問巻/電子表格',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)
      }
    }
  }
}
</script>

<style scoped lang="scss">
#form-form {
  .info {
    .space {
      border: 1px solid #ccc;
      width: 90px;
      height: 32px;
    }
  }
}
</style>
