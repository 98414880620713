<template>
  <div id="update-submit-form-page">

    <div v-if="checkPermission(permissions, 'site.form.view') || checkPermission(permissions, 'site.form.edit')">

      <div v-if="data != null && response != null" class="flex justify-between mt-10">
        <h2 class="intro-y text-lg font-medium">
          {{ data.name }}<br>
          <small>修改於 {{ response.created_at }} 的回覆</small>
        </h2>

        <div>
          <router-link :to="`/forms/${$route.params.formId}/response`" type="button" class="btn btn-outline-secondary w-24">返回</router-link>
        </div>
      </div>

      <submit-form-form :current-site="currentSite" :data="data" :response="response"></submit-form-form>

    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>

  </div>
</template>

<script>
export default {
  props: ['auth', 'currentSite', 'permissions'],
  data() {
    return {
      mounted: false,
      data: null,
      response: null
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentSite() {
      this.init()
    }
  },
  async mounted() {
    window.tailwind.svgLoader()

    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite != null) {
        await this.loadData()
        await this.loadResponse()

        this.mounted = true
      }
    },
    async loadData() {
      this.data = await this.getSiteForm(this.currentSite.site_id, this.$route.params.formId)
    },
    async loadResponse() {
      this.response = await this.getResponse(this.currentSite.site_id, this.$route.params.formId, this.$route.params.responseId)
    }
  }
}
</script>

<style lang="scss" scoped>
#form-page {
  .leading-relaxed {
    min-height: 400px;
  }

  .type {
    font-size: 16px;
  }
}
</style>
