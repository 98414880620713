<template>
  <div id="form-response-page">

    <div v-if="!blocked" class="flex justify-between mt-10">
      <h2 v-if="form != null" class="intro-y text-lg font-medium">
        {{ form.name }}
        <br>
        <span v-if="data?.length > 0">{{ data[0]?.user.display_name }}的回覆</span>
      </h2>

      <router-link v-if="form != null" :to="(userId != null && userId != '') ? `/forms/${form.form_id}` : '/forms'" type="button" class="btn btn-outline-secondary w-24">返回</router-link>
    </div>

    <div v-if="data?.length > 0 && !blocked" class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <div class="flex justify-between">
          <select v-model="currentResponseId" class="form-select" :class="{'mr-2': self === true && form.is_response_editable}">
            <option v-for="(item, key) in data" :key="key" :value="item.response_id">於{{ item.created_at }}的回覆</option>
          </select>

          <router-link v-if="self && form.is_response_editable" :to="`/forms/${$route.params.formId}/update-response/${currentResponseId}`" class="btn btn-pending w-20">修改</router-link>
          <button v-if="self && form.is_response_editable" class="btn btn-danger w-20 ml-2" @click="openDeleteModal()">刪除</button>
        </div>

        <!-- BEGIN: Form Layout -->
        <div v-if="response" class="intro-y box p-5 mt-4">

          <div v-for="(section, i) in response.response" :key="i" :class="{'mt-4 pt-4': i > 0}">
            <div class="intro-y col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6" :class="{'mt-8': i > 0}">
              <span>{{ section.display_order }}. {{ section.section_name }}</span>
            </div>

            <div v-for="(item, key) in section.items" :key="key" :class="{'mt-4 border-t pt-4': key > 0}">
              <label class="form-label font-medium text-primary">{{ section.display_order + '.' + item.display_order }} {{ item.title }}</label>

              <div v-if="item.form_item_type === 'text' || item.form_item_type === 'long-text' ||item.form_item_type === 'date'" class="font-medium">
                <template v-if="item.responses?.length > 0">{{ item.responses[0].content }}</template>
                <template v-else>-</template>
              </div>

              <div v-else-if="item.form_item_type === 'file'">
                <template v-if="item.responses?.length > 0"><button class="btn border-1 border-primary" @click="downloadData(item.responses[0])">下載 ({{ item.responses[0].content.substring(11) }})</button></template>
                <template v-else>-</template>
              </div>

              <div v-else-if="item.form_item_type === 'centre'">
                <template v-if="item.responses?.length > 0">{{ getObjectValue(centers, 'centre_id', item.responses[0].option_id, 'name_zh') }}</template>
                <template v-else>-</template>
              </div>

              <div v-else-if="item.form_item_type === 'centrecodes'">
                <template v-if="item.responses?.length > 0">
                  <template v-for="(option, i) in item.responses"><template v-if="i > 0">, </template>{{ getObjectValue(centers, 'centre_id', option.option_id, 'cost_centre_code') ?? getObjectValue(centers, 'centre_id', option.option_id, 'abbrev') }}</template>
                </template>
                <template v-else>-</template>
              </div>

              <div v-else-if="item.form_item_type === 'post'">
                <template v-if="item.responses?.length > 0">{{ getObjectValue(positions, 'position_id', item.responses[0].option_id, 'name') }}</template>
                <template v-else>-</template>
              </div>

              <div v-else>
                <div v-for="(option, i) in item.options" :key="i" :class="{'inactive': checkSelected(option, item.responses)}" class="font-medium">
                  {{ option.title }} {{ option.is_custom ? `${getContent(option, item.responses)}` : '' }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>

    <div v-else-if="data?.length === 0">
      <div class="alert alert-warning show mt-2" role="alert">沒有回覆</div>
    </div>

    <div v-else-if="blocked">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>

    <!-- BEGIN: Delete Confirmation Modal -->
    <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger" />
              <div class="text-3xl mt-5">你確定嗎？</div>
              <div class="text-slate-500 mt-2">
                你是否要刪除此回覆？
                <br>
                這動作不能還原。
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
              <button type="button" class="btn btn-danger w-24" @click="onDelete">確定</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
  </div>
</template>

<script>
export default {
  props: ['auth', 'currentSite', 'permissions'],
  data() {
    return {
      mounted: false,
      userId: null,
      form: null,
      centers: null,
      positions: null,
      data: null,
      response: null,
      blocked: false,
      self: false,

      currentResponseId: null,
      currentResponseKey: null,
      errors: {},
      loading: false,
      finish: false
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    async currentSite(val) {
      await this.init()
    },
    async currentResponseId() {
      await this.loadResponse()
    }
  },
  async mounted() {
    window.tailwind.svgLoader()

    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.loadData()

        this.mounted = true
      }
    },
    async loadCenters() {
      if (this.centers === null) {
        this.centers = await this.getCenters()
      }
    },
    async loadPositions() {
      if (this.positions === null) {
        this.positions = await this.getPositions()
      }
    },
    async loadData() {
      this.form = await this.getSiteForm(this.currentSite.site_id, this.$route.params.formId)
      for (let i = 0; i < this.form.sections.length; i++) {
        for (let j = 0; j < this.form.sections[i].items.length; j++) {
          switch (this.form.sections[i].items[j].form_item_type) {
            case 'centre':
            case 'centrecodes':
              await this.loadCenters()
              break
            case 'post':
              await this.loadPositions()
              break
          }
        }
      }

      this.userId = this.$route.query.userId ?? null

      if (this.userId != null && this.userId !== '') {
        if (this.checkPermission(this.permissions, 'site.form.view')) {
          this.data = await this.getUserResponse(this.currentSite.site_id, this.$route.params.formId, this.userId)
        } else {
          this.blocked = true
        }
      } else {
        this.data = await this.getMyResponse(this.currentSite.site_id, this.$route.params.formId)
        this.self = true
      }

      if (this.data.length > 0) {
        this.currentResponseId = this.data[0].response_id
      }
    },
    async loadResponse() {
      this.response = await this.getResponse(this.currentSite.site_id, this.$route.params.formId, this.currentResponseId)
    },
    async downloadData(item) {
      const response = await this.getResponseData(this.currentSite.site_id, this.$route.params.formId, this.currentResponseId, item.content)
      if (response.status === 200) {
        const blob = new Blob([response.data])
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = (item.content.substring(11))
        link.click()

        URL.revokeObjectURL(link.href)
      } else {
        this.$notify({
          title: '未能下載檔案',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        })
      }
    },
    checkSelected(option, responses) {
      for (let i = 0; i < responses.length; i++) {
        if (responses[i].form_item_id === option.form_item_id && responses[i].option_id === option.option_id) {
          return false
        }
      }
      return true
    },
    getContent(option, responses) {
      for (let i = 0; i < responses.length; i++) {
        if (responses[i].form_item_id === option.form_item_id && responses[i].option_id === option.option_id) {
          return `(${responses[i].content})`
        }
      }

      return ''
    },
    openDeleteModal() {
      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDelete() {
      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      const response = await this.deleteResponse(this.currentSite.site_id, this.$route.params.formId, this.currentResponseId)

      let option = {
        title: '已成功刪除回覆',
        message: '',
        type: 'success'
      }

      if (response.code === 0) {
        if (this.data.length > 1) {
          await this.loadData()
        } else {
          option.message = '請稍後，將會轉至問巻/電子表格頁面'

          setTimeout(() => {
            this.$router.push('/forms')
          }, 1000)
        }
      } else {
        option = {
          title: '未能刪除消息',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    }
  }
}
</script>

<style lang="scss" scoped>
#form-response-page {
  .leading-relaxed {
    min-height: 400px;
  }

  .type {
    font-size: 16px;
  }

  .inactive {
    color: #bdbdbd;
  }
}
</style>
