<template>
  <div id="form-page">

    <div v-if="data != null" class="flex justify-between mt-10">
      <h2 class="intro-y text-lg font-medium">
        {{ data.name ?? '-' }}
      </h2>

      <router-link to="/forms" type="button" class="btn btn-outline-secondary w-24">返回</router-link>
    </div>

    <div class="flex mt-4">
      <div class="w-3/5">
        <div v-if="data != null" class="intro-y p-5 box">
          <div class="">
            <label for="user" class="form-label">名稱</label>
            <div class="w-full">{{ data.name ?? '-' }}</div>
          </div>

          <div class="mt-3">
            <label for="description" class="form-label">簡介</label>
            <div class="w-full text-nl2br">{{ data.description ?? '-' }}</div>
          </div>

          <div class="mt-3">
            <label for="last_name" class="form-label">公開</label>
            <div class="w-full">{{ data.is_published ? '是' : '否' }}</div>
          </div>

          <div class="mt-3">
            <label for="from_time" class="form-label">開始日期時間</label>
            <div class="w-full">{{ data.from_time != null ? $moment(data.from_time).format('YYYY-MM-DD HH:mm:ss') : '-' }}</div>
          </div>

          <div class="mt-3">
            <label for="to_time" class="form-label">完結日期時間</label>
            <div class="w-full">{{ data.to_time != null ? $moment(data.to_time).format('YYYY-MM-DD HH:mm:ss') : '-' }}</div>
          </div>

          <div class="flex justify-end mt-5 pt-5 border-t border-slate-200/60 dark:border-darkmode-400">
            <div class="flex justify-end items-end">
              <router-link v-if="checkPermission(permissions, 'site.form.edit')" :to="`/update-form/${data.form_id}`" class="flex items-center mr-3">
                <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                &nbsp;更改 </router-link>
            </div>
          </div>
        </div>

        <div v-show="checkPermission(permissions, 'site.form.edit') && (data != null || users.length > 0)" class="mt-6">
          <div class="flex justify-between align-items-center">
            <h2 class="text-lg font-medium">
              已提交用戶 ({{ users.length }}名)
            </h2>

            <button type="button" class="btn btn-success text-white" @click="downloadResult" v-if="!downloading && users.length > 0">下載所有回覆</button>
            <span v-show="downloading"><i data-loading-icon="oval" class="w-8 h-8"></i></span>

            <button type="button" class="btn btn-danger" @click="openClearModal" v-if="users.length > 0">清空所有回覆</button>
          </div>

          <div v-if="checkPermission(permissions, 'site.form.edit') && (data != null || users.length > 0)" id="basic-tab" class="box mt-2">
            <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
              <table class="table table-striped">
                <thead>
                <tr>
                  <th class="text-center whitespace-nowrap">檢視回答</th>
                  <th class="whitespace-nowrap">
                    用戶名稱
                  </th>
                  <th class="whitespace-nowrap">
                    修改時間
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr class="" v-for="(item, key) in users" :key="key">
                  <td v-if="checkPermission(permissions, 'site.form.edit')" class="table-report__action text-center" >
                    <router-link :to="`/forms/${$route.params.formId}/response?userId=${item.user_id}`" class="btn btn-success">
                      <font-awesome-icon icon="fa-regular fa-eye" class="text-white"/>
                    </router-link>
                  </td>
                  <td>{{ item.display_name }}</td>
                  <td>{{ $moment(item.created_at).format('YYYY/MM/DD HH:mm') }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div v-if="checkPermission(permissions, 'site.form.edit') && data != null" class="flex-1 ml-5">
        <div class="flex justify-start align-items-center">
          <h2 class="text-lg font-medium">
            題目
          </h2>

          <button type="button" class="btn btn-primary ml-4" @click="openSectionModal('create')">新增分段</button>
        </div>

        <div class="box mt-5">
          <draggable v-model="sections" @end="onUpdateSectionSequence" handle=".section-handle" v-bind="dragOptions" group="section">
            <transition-group type="transition" name="flip-list">
              <div v-for="(section, i) in sections" :key="i" class="border-slate-200 dark:border-darkmode-400 bg-white p-5"
                   :class="{'pb-5': i === 0, 'border-b': i !== sections.length - 1, 'pt-5': i === sections.length - 1, 'py-5': i > 0 && i < sections.length - 1}">
                <div>
                  <div class="flex justify-between align-items-center">
                    <div class="text-slate-500 flex">
                    <span class="type text-primary font-medium mr-2">
                      {{ section.section_name }}</span>
                      <span v-if="section.is_required" class="px-2 py-0.5 bg-slate-200 text-slate-600 dark:bg-darkmode-300 dark:text-slate-400 text-xs rounded-md">必填</span>
                    </div>

                    <div class="flex">
                      <button type="button" class="text-success" @click="openModal('create', null, section)">
                        <font-awesome-icon icon="fa-solid fa-plus"/>
                      </button>

                      <button type="button" class="text-slate-500 ml-3" @click="openSectionModal('update', section)">
                        <font-awesome-icon icon="fa-solid fa-pen-to-square"/>
                      </button>

                      <button type="button" class="text-danger ml-3" @click="openDeleteSectionModal(section.section_id, i)">
                        <font-awesome-icon icon="fa-regular fa-trash-can"/>
                      </button>

                      <button type="button" class="text-slate-500 ml-3 section-handle">
                        <font-awesome-icon icon="fa-solid fa-up-down-left-right"/>
                      </button>
                    </div>

                  </div>

                  <div class="mt-1">{{ section.description ?? '-' }}</div>

                  <div class="mt-2">
                    <draggable v-model="section.items" @end="onUpdateSequence" handle=".handle" v-bind="dragOptions" group="item">
                      <transition-group type="transition" name="flip-list" class="list-group">
                        <div v-for="(item, key) in section.items" :key="key" class="border-slate-200 dark:border-darkmode-400 bg-white p-5 border-2"
                             :class="{'pb-5': key === 0, 'border-b': key !== section.items.length - 1, 'pt-5': key === section.items.length - 1, 'py-5': key > 0 && key < section.items.length - 1}">
                          <div>
                            <div class="flex justify-between align-items-center">
                              <div class="text-slate-500 flex">
                    <span class="type text-primary font-medium mr-2">
                      {{ getObjectValue(formItemTypes, 'value', item.form_item_type, 'key') }}</span>
                                <span v-if="item.is_required" class="px-2 py-0.5 bg-slate-200 text-slate-600 dark:bg-darkmode-300 dark:text-slate-400 text-xs rounded-md">必填</span>
                              </div>

                              <button type="button" class="text-slate-500 handle">
                                <font-awesome-icon icon="fa-solid fa-arrows-up-down-left-right"/>
                              </button>
                            </div>

                            <div class="mt-2">
                              {{ item.title }}
                            </div>
                            <div class="mt-2">{{ item.description ?? '-' }}</div>
                            <div class="mt-2 flex justify-between">
                              最後更新：{{ $moment(item.updated_at).format('YYYY-MM-DD HH:mm:ss') }}

                              <div class="flex">
                                <button type="button" class="text-slate-500" @click="openModal('update', item)">
                                  <font-awesome-icon icon="fa-solid fa-pen-to-square"/>
                                </button>

                                <button type="button" class="text-danger ml-3" @click="openDeleteModal(item.form_item_id, i, key)">
                                  <font-awesome-icon icon="fa-regular fa-trash-can"/>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </transition-group>
                    </draggable>
                  </div>
                </div>
              </div>
            </transition-group>
          </draggable>

          <div v-if="sections.length === 0" class="px-4 py-4">
            未有分段
          </div>
        </div>
      </div>
    </div>

    <!-- BEGIN: Section Modal -->
    <div id="section-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">
              {{ mode === 'create' ? '新增分段' : '更改分段' }}
            </h2>
          </div>
          <div class="modal-body p-0">
            <div class="p-5">
              <div class="text-slate-500">
                <div class="">
                  <label class="form-label">分段編號</label>
                  <input type="text" v-model="sectionForm.section_code" placeholder="請輸入分段編號" class="form-control w-full"/>
                  <div v-if="'section_code' in errors" class="text-danger mt-2" v-html="errors.section_code"></div>
                </div>

                <div class="mt-2">
                  <label class="form-label"><span class="text-danger">*</span> 分段名稱</label>
                  <input type="text" v-model="sectionForm.section_name" placeholder="請輸入分段名稱"
                         class="form-control w-full"/>
                  <div v-if="'section_name' in errors" class="text-danger mt-2" v-html="errors.section_name"></div>
                </div>

                <div class="mt-2">
                  <label class="form-label">分段簡介</label>
                  <input type="text" v-model="sectionForm.description" placeholder="請輸入分段簡介"
                         class="form-control w-full"/>
                  <div v-if="'description' in errors" class="text-danger mt-2" v-html="errors.description"></div>
                </div>

                <div class="mt-2">
                  <label class="form-label"><span class="text-danger">*</span> 分段次序</label>
                  <input type="number" v-model="sectionForm.display_order" placeholder="請輸入分段次序" readonly
                         class="form-control w-full"/>
                  <div v-if="'display_order' in errors" class="text-danger mt-2" v-html="errors.display_order"></div>
                </div>

                <div class="mt-2">
                  <label for="is_required"><span class="text-danger">*</span> 必填</label>
                  <div class="form-switch mt-2">
                    <input id="is_required" v-model="sectionForm.is_required" type="checkbox" class="form-check-input">
                  </div>

                  <div v-if="'is_required' in errors" class="text-danger mt-2" v-html="errors.is_required"></div>
                </div>
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消
              </button>
              <button v-if="!loading" type="button" class="btn btn-primary w-24 text-white" @click="onSectionSubmit">確定
              </button>
              <span v-show="loading"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Section Modal -->

    <!-- BEGIN: Delete Section Confirmation Modal -->
    <div id="delete-section-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger"/>
              <div class="text-3xl mt-5">你確定嗎？</div>
              <div class="text-slate-500 mt-2">
                你是否要刪除此分段？
                <br>
                這動作不能還原。
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
              <button type="button" class="btn btn-danger w-24" @click="onSectionDelete">確定</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Section Confirmation Modal -->

    <!-- BEGIN: Item Modal -->
    <div id="item-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">
              {{ mode === 'create' ? '新增題目' : '更改題目' }}
            </h2>
          </div>
          <div class="modal-body p-0">
            <div class="p-5">
              <div class="text-slate-500">
                <div class="">
                  <label for="section_id" class="form-label"> 分段</label>
                  <div>
                    <strong>{{ getObjectValue(sections, 'section_id', form.section_id, 'section_name') }}</strong>
                  </div>
                  <div v-if="'section_id' in errors" class="text-danger mt-2" v-html="errors.section_id"></div>
                </div>

                <div class="mt-2" v-if="false">
                  <label for="jump_section_id" class="form-label">答題後跳至</label>
                  <select id="jump_section_id" v-model="form.jump_section_id" class="form-select"
                          aria-label="請選擇題目類型">
                    <option value="">不用跳</option>
                    <option v-for="(item, key) in sections" :key="key" :value="item.section_id">{{ item.section_name }}</option>
                  </select>
                  <div v-if="'jump_section_id' in errors" class="text-danger mt-2" v-html="errors.jump_section_id"></div>
                </div>

                <div class="mt-2">
                  <label for="form_item_type" class="form-label"><span class="text-danger">*</span> 題目類型</label>
                  <select id="form_item_type" v-model="form.form_item_type" class="form-select"
                          aria-label="請選擇題目類型" :disabled="mode === 'update' && data.is_published">
                    <option v-for="(item, key) in formItemTypes" :key="key" :value="item.value">{{ item.key }}</option>
                  </select>
                  <div v-if="'form_item_type' in errors" class="text-danger mt-2" v-html="errors.form_item_type"></div>
                </div>

                <div class="mt-2">
                  <label class="form-label"><span class="text-danger">*</span> 題目</label>
                  <input type="text" v-model="form.title" placeholder="請輸入題目" class="form-control w-full"/>
                  <div v-if="'title' in errors" class="text-danger mt-2" v-html="errors.title"></div>
                </div>

                <div class="mt-2">
                  <label class="form-label">簡介</label>
                  <input type="text" v-model="form.description" placeholder="請輸入簡介"
                         class="form-control w-full"/>
                  <div v-if="'description' in errors" class="text-danger mt-2" v-html="errors.description"></div>
                </div>

                <div class="mt-2"
                     v-if="form.form_item_type === 'radio' || form.form_item_type === 'checkbox' || form.form_item_type === 'dropdown'">
                  <div class="flex justify-between align-items-center">
                    <label for="remark" class="form-label"><span class="text-danger">*</span> 選項</label>
                    <button type="button" class="btn btn-primary"
                            @click="form.options.push({ title: null, display_order: form.options.length + 1, is_custom: false, option_id: null })">
                      <font-awesome-icon :icon="['fas', 'plus']"/>
                    </button>
                  </div>
                  <draggable v-model="form.options" @end="onUpdateOptionSequence" v-bind="dragOptions" handle=".option-handle">
                    <transition-group type="transition" name="flip-list">
                      <div class="flex align-items-end mb-1 bg-white" v-for="(option, key) in form.options" :key="key">
                        <div class="pb-2">
                          <button type="button" class="text-slate-500 option-handle mr-2">
                            <font-awesome-icon icon="fa-solid fa-grip"/>
                          </button>
                        </div>

                        <div class="flex-1 mr-1">
                          <label class="form-label">回答</label>
                          <input v-model="option.title" type="text" class="form-control w-full" placeholder="請輸入回答">
                        </div>
                        <div class="flex-1 ml-1 mr-1">
                          <label class="form-label">次序</label>
                          <input v-model="option.display_order" type="number" class="form-control w-full" readonly
                                 placeholder="請輸入次序">
                        </div>
                        <div class="flex-1 ml-1 mr-1">
                          <label class="form-label">容許填上其他回答</label>
                          <div class="form-switch mt-2">
                            <input id="is_custom" v-model="option.is_custom" type="checkbox" class="form-check-input">
                          </div>
                        </div>
                        <div class="ml-1">
                          <button type="button" class="btn btn-danger btn-remove" @click="onRemoveOption(key)">
                            <font-awesome-icon :icon="['fas', 'xmark']"/>
                          </button>
                        </div>
                      </div>
                    </transition-group>
                  </draggable>
                  <div v-if="'options' in errors" class="text-danger mt-2" v-html="errors.options"></div>
                </div>

                <div class="mt-2">
                  <label class="form-label"><span class="text-danger">*</span> 次序</label>
                  <input type="number" v-model="form.display_order" placeholder="請輸入次序" readonly
                         class="form-control w-full"/>
                  <div v-if="'display_order' in errors" class="text-danger mt-2" v-html="errors.display_order"></div>
                </div>

                <div class="mt-2">
                  <label for="is_required"><span class="text-danger">*</span> 必填</label>
                  <div class="form-switch mt-2">
                    <input id="is_required" v-model="form.is_required" type="checkbox" class="form-check-input">
                  </div>

                  <div v-if="'is_required' in errors" class="text-danger mt-2" v-html="errors.is_required"></div>
                </div>
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消
              </button>
              <button v-if="!loading" type="button" class="btn btn-primary w-24 text-white" @click="onSubmit">確定
              </button>
              <span v-show="loading"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Rename Modal -->

    <!-- BEGIN: Delete Confirmation Modal -->
    <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger"/>
              <div class="text-3xl mt-5">你確定嗎？</div>
              <div class="text-slate-500 mt-2">
                你是否要刪除此題目？
                <br>
                這動作不能還原。
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
              <button type="button" class="btn btn-danger w-24" @click="onDelete">確定</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->

    <!-- BEGIN: Clear Confirmation Modal -->
    <div id="clear-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger"/>
              <div class="text-3xl mt-5">你確定嗎？</div>
              <div class="text-slate-500 mt-2">
                你是否要清空所有用戶回覆？
                <br>
                這動作不能還原。
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
              <button type="button" class="btn btn-danger w-24" @click="onClear">確定</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Clear Confirmation Modal -->
  </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
  props: ['auth', 'currentSite', 'permissions'],
  components: {
    draggable
  },
  data() {
    return {
      mounted: false,
      data: null,

      mode: 'create',
      sections: [],
      items: [],
      users: [],
      sectionForm: {
        form_id: null,
        section_code: null,
        section_name: null,
        description: null,
        display_order: 0,
        is_required: true
      },
      form: {
        item_id: null,
        section_id: '',
        jump_section_id: '',
        form_item_type: 'text',
        title: null,
        description: null,
        display_order: 0,
        is_required: true,
        options: []
      },

      errors: {},
      loading: false,
      updatting: false,

      formItemTypes: [
        {
          value: 'text',
          key: '文字'
        },
        {
          value: 'long-text',
          key: '長文字'
        },
        {
          value: 'radio',
          key: '單選'
        },
        {
          value: 'checkbox',
          key: '多選'
        },
        {
          value: 'dropdown',
          key: '選單'
        },
        {
          value: 'file',
          key: '檔案'
        },
        {
          value: 'date',
          key: '日期'
        },
        {
          value: 'centre',
          key: '部/組/服務單位'
        },
        {
          value: 'centrecodes',
          key: '部/組/服務單位 (單位編號)'
        },
        {
          value: 'post',
          key: '職位'
        }
      ],

      deleteId: null,
      deleteKey: null,
      deleteSectionKey: null,

      downloading: false
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    'form.form_item_type'(val) {
      if (val !== 'radio' && val !== 'checkbox' && val !== 'dropdown') {
        this.form.options = []
      }
    },
    async currentSite(val) {
      await this.init()
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  async mounted() {
    window.tailwind.svgLoader()

    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.loadData()
        this.loadSubmittedUsers()
        await this.loadItems()
        await this.loadSections()

        this.mounted = true
      }
    },
    async loadData() {
      this.data = await this.getSiteForm(this.currentSite.site_id, this.$route.params.formId)

      if (this.data.code) {
        this.$router.push('/forms')
      }
    },
    async loadSections() {
      const sections = await this.getSiteFormSections(this.currentSite.site_id, this.$route.params.formId)

      for (let i = 0; i < sections.length; i++) {
        sections[i].items = []

        for (let j = 0; j < this.items.length; j++) {
          if (this.items[j].section_id === sections[i].section_id) {
            sections[i].items.push(this.items[j])
          }
        }
      }

      this.sections = sections
    },
    async loadItems() {
      this.items = await this.getSiteFormItems(this.currentSite.site_id, this.$route.params.formId)
    },
    async loadSubmittedUsers() {
      this.users = await this.getAllSubmittedUsers(this.currentSite.site_id, this.$route.params.formId)
    },
    openSectionModal(mode, data = null) {
      this.mode = mode

      if (this.mode === 'create') {
        this.clearSectionForm()
        const sections = this.sections
        this.sectionForm.display_order = sections.length > 0 ? (sections[sections.length - 1].display_order + 1) : 1
      } else {
        this.sectionForm = JSON.parse(JSON.stringify(data))
      }

      const el = document.querySelector('#section-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onSectionSubmit() {
      this.loading = true
      this.errors = {}

      let response

      if (this.mode === 'create') {
        response = await this.createSiteFormSection(this.currentSite.site_id, this.$route.params.formId, this.sectionForm)
      } else {
        response = await this.updateSiteFormSection(this.currentSite.site_id, this.$route.params.formId, this.sectionForm.section_id, this.sectionForm)
      }

      this.loading = false

      if ('errors' in response.data) {
        this.errors = response.data.errors
      } else {
        let option = {
          title: '成功',
          message: (this.mode === 'create') ? '已新增分段' : '已更改分段',
          type: 'success'
        }

        if (response.code === 0) {
          const el = document.querySelector('#section-modal')
          const modal = window.tailwind.Modal.getOrCreateInstance(el)
          modal.hide()

          await this.loadItems()
          await this.loadSections()
        } else {
          option = {
            title: (this.mode === 'create') ? '未能新增分段' : '未能更改分段',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)
      }
    },
    async onUpdateSectionSequence() {
      for (let i = 0; i < this.sections.length; i++) {
        this.sections[i].display_order = i + 1
        await this.updateSiteFormSection(this.currentSite.site_id, this.$route.params.formId, this.sections[i].section_id, this.sections[i])
      }
    },
    clearSectionForm() {
      this.sectionForm = {
        form_id: null,
        section_code: null,
        section_name: null,
        description: null,
        display_order: 0,
        is_required: true
      }
    },
    openModal(mode, data = null, section) {
      this.mode = mode

      if (this.mode === 'create') {
        this.clearForm()
        this.form.section_id = section.section_id
        this.form.display_order = section.items.length > 0 ? (section.items[section.items.length - 1].display_order + 1) : 1
      } else {
        this.form = JSON.parse(JSON.stringify(data))
      }

      const el = document.querySelector('#item-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onSubmit() {
      this.loading = true
      this.errors = {}

      let response

      if (this.mode === 'create') {
        response = await this.createSiteFormItem(this.currentSite.site_id, this.$route.params.formId, this.form)
      } else {
        response = await this.updateSiteFormItem(this.currentSite.site_id, this.$route.params.formId, this.form.form_item_id, this.form)
      }

      this.loading = false

      if ('errors' in response.data) {
        this.errors = response.data.errors
      } else {
        let option = {
          title: '成功',
          message: (this.mode === 'create') ? '已新增問題' : '已更改問題',
          type: 'success'
        }

        if (response.code === 0) {
          const el = document.querySelector('#item-modal')
          const modal = window.tailwind.Modal.getOrCreateInstance(el)
          modal.hide()

          await this.loadItems()
          await this.loadSections()
        } else {
          option = {
            title: (this.mode === 'create') ? '未能新增問題' : '未能更改問題',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)
      }
    },
    openDeleteSectionModal(id, key) {
      this.deleteId = id
      this.deleteKey = key

      const el = document.querySelector('#delete-section-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onSectionDelete() {
      const el = document.querySelector('#delete-section-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      const response = await this.deleteSiteFormSection(this.currentSite.site_id, this.$route.params.formId, this.deleteId)

      let option = {
        title: '成功',
        message: '分段已被刪除',
        type: 'success'
      }

      if (response.code === 0) {
        this.$delete(this.sections, this.deleteKey)
        await this.onUpdateSectionSequence()
      } else {
        option = {
          title: '未能刪除分段',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    },
    clearForm() {
      this.form = {
        item_id: null,
        section_id: '',
        jump_section_id: '',
        form_item_type: 'text',
        title: null,
        description: null,
        display_order: 0,
        is_required: true,
        options: []
      }
    },
    openClearModal() {
      const el = document.querySelector('#clear-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onClear() {
      const el = document.querySelector('#clear-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      const response = await this.clearFormResponse(this.currentSite.site_id, this.$route.params.formId, this.deleteId)

      let option = {
        title: '成功',
        message: '所有回覆已被清空',
        type: 'success'
      }

      if (response.code === 0) {
        await this.loadSubmittedUsers()
      } else {
        option = {
          title: '未能清空回覆',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    },
    openDeleteModal(id, sectionKey, key) {
      this.deleteId = id
      this.deleteSectionKey = sectionKey
      this.deleteKey = key

      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDelete() {
      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      const response = await this.deleteSiteFormItem(this.currentSite.site_id, this.$route.params.formId, this.deleteId)

      let option = {
        title: '成功',
        message: '題目已被刪除',
        type: 'success'
      }

      if (response.code === 0) {
        this.$delete(this.sections[this.deleteSectionKey].items, this.deleteKey)
        await this.onUpdateSequence()
      } else {
        option = {
          title: '未能刪除題目',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    },
    async onUpdateSequence() {
      if (this.updatting) {
        return
      }

      this.updatting = true

      for (let i = 0; i < this.sections.length; i++) {
        for (let j = 0; j < this.sections[i].items.length; j++) {
          this.sections[i].items[j].section_id = this.sections[i].section_id
          this.sections[i].items[j].display_order = j + 1
          await this.updateSiteFormItem(this.currentSite.site_id, this.$route.params.formId, this.sections[i].items[j].form_item_id, this.sections[i].items[j])
        }
      }

      this.updatting = false
    },
    onRemoveOption(key) {
      this.$delete(this.form.options, key)
      this.onUpdateOptionSequence()
    },
    onUpdateOptionSequence() {
      for (let i = 0; i < this.form.options.length; i++) {
        this.form.options[i].display_order = i + 1
      }
    },
    async downloadResult() {
      this.downloading = true

      const date = this.$moment(this.downloadDate).format('YYYY-MM-DD')
      const response = await this.downloadFormResult(this.currentSite.site_id, this.$route.params.formId)
      const blob = new Blob([response], { type: 'application/zip' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `${this.data.name} Result (${date}).zip`
      link.click()
      URL.revokeObjectURL(link.href)

      this.downloading = false
    }
  }
}
</script>

<style lang="scss" scoped>
#form-page {
  .leading-relaxed {
    min-height: 400px;
  }

  .type {
    font-size: 16px;
  }

  .flip-list-move {
    transition: transform 0.5s;
  }

  .no-move {
    transition: transform 0s;
  }

  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }

  .list-group {
    display: block;
    min-height: 20px;
    background-color: #eeeeee;
  }
}
</style>
