<template>
  <div id="forum-category-form">
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">

          <div class="mt-3">
            <label for="site_id" class="form-label">分站</label>
            <div id="site_id" class="w-full" v-if="site">{{ site.name }}</div>
            <div v-if="'site_id' in errors" class="text-danger mt-2" v-html="errors.site_id"></div>
          </div>

          <div class="mt-3">
            <label for="name" class="form-label"><span class="text-danger">*</span> 類別名稱</label>
            <input v-model="form.name" id="name" type="text" class="form-control w-full" placeholder="請輸入類別名稱">
            <div v-if="'name' in errors" class="text-danger mt-2" v-html="errors.name"></div>
          </div>

          <div class="mt-3">
            <label for="description" class="form-label">簡介</label>
            <input v-model="form.description" id="description" type="text" class="form-control w-full" placeholder="請輸入簡介">
            <div v-if="'description' in errors" class="text-danger mt-2" v-html="errors.description"></div>
          </div>

          <div class="mt-3">
            <label for="display_order" class="form-label"><span class="text-danger">*</span> 顯示次序</label>
            <input v-model="form.display_order" id="display_order" type="number" class="form-control w-full" placeholder="請輸入顯示次序">
            <div v-if="'display_order' in errors" class="text-danger mt-2" v-html="errors.display_order"></div>
          </div>

          <div class="mt-3">
            <label for="postable">可發佈主題</label>
            <div class="form-switch mt-2">
              <input id="postable" v-model="form.postable" type="checkbox" class="form-check-input">
            </div>

            <div v-if="'postable' in errors" class="text-danger mt-2" v-html="errors.postable"></div>
          </div>

          <div class="flex justify-end align-items-center mt-5">
            <router-link v-if="!loading && !finish" to="/management/forum-categories" type="button" class="btn btn-outline-secondary w-24 mr-1">取消</router-link>
            <button v-if="!loading && !finish" type="button" class="btn btn-primary w-24" @click="onSave">儲存</button>
            <span v-show="loading && !finish"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['site', 'data'],
  data() {
    return {
      mode: 'create',
      sites: [],
      form: {
        name: null,
        description: null,
        display_order: 0,
        postable: false
      },
      errors: {},
      loading: false,
      finish: false
    }
  },
  watch: {
    data(val) {
      if (val != null) {
        this.form = this.data
        this.mode = 'update'
      }
    }
  },
  mounted() {
    window.tailwind.svgLoader()
    this.loadSites()
  },
  methods: {
    async loadSites() {
      this.sites = await this.getSites()
    },
    onSave: async function() {
      this.loading = true
      this.errors = {}

      let response

      if (this.mode === 'create') {
        response = await this.createSiteForumCategory(this.site.site_id, this.form)
      } else {
        response = await this.updateSiteForumCategory(this.site.site_id, this.data.category_id, this.form)
      }

      if ('errors' in response.data) {
        this.errors = response.data.errors
        this.loading = false
      } else {
        let option = {
          title: (this.mode === 'create') ? '已成功新增類別' : '已成功更新類別',
          text: `請稍後，將會轉至${this.site.name}的討論區類別頁面`,
          type: 'success'
        }

        if (response.code === 0) {
          this.finish = true

          setTimeout(() => {
            this.$router.push('/management/forum-categories')
          }, 1000)
        } else {
          this.loading = false
          option = {
            title: (this.mode === 'create') ? '未能新增類別' : '未能更新類別',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
