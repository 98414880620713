<template>
  <div id="profile-page">
    <div class="mt-10">
      <div v-if="data != null" class="intro-y xl:w-3/5 p-5 box">
        <div class="flex justify-center">
          <div class="profile-image image-fit">
            <img alt="" :src="data.avatar_url ?? '/images/user.png'" class="tooltip rounded-full">
          </div>
        </div>

        <div class="mt-14">
          <div class="sm:grid grid-cols-2 gap-2">
            <div>
              <label for="staff-number" class="form-label">職員編號</label>
              <div class="w-full">{{ data.staff_number }}</div>
            </div>

            <div>
              <label class="form-label">用戶登入名稱</label>
              <div class="w-full">{{ data.username }}</div>
            </div>
          </div>
        </div>

        <div class="mt-5">
          <label class="form-label">密碼</label>
          <div class="w-full"><font-awesome-icon icon="fa-solid fa-pen-to-square" /> <router-link to="update-password" class="text-primary">更改密碼</router-link></div>
        </div>

        <div class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6 mt-6" role="alert">
          <span class="font-medium">基本資料</span>
        </div>

        <div class="mt-5">
          <div class="sm:grid grid-cols-2 gap-2">
            <div>
              <label for="centre_id" class="form-label">所屬部/組/服務單位</label>
              <div class="w-full">{{ data.centre ? data.centre.abbrev : '-' }}</div>
            </div>

            <div>
              <label for="position_id" class="form-label">職位</label>
              <div class="w-full">{{ data.position ? data.position.name : '-' }}</div>
            </div>
          </div>
        </div>

        <div class="mt-3">
          <div class="flex">
            <div class="flex-1 mr-1">
              <label class="form-label">主任級或以上</label>
              <div class="w-full">{{ data.is_high_level ? '是' : '否' }}</div>
            </div>
            <div class="flex-1 ml-1">
              <label class="form-label">註冊社工</label>
              <div class="w-full">{{ data.is_register_social_worker ? '是' : '否' }}</div>
            </div>
          </div>
        </div>

        <div class="mt-5">
          <label class="form-label">稱謂</label>
          <div class="w-full">{{ data.honorific_title ? getObjectValue(titles, 'value', data.honorific_title, 'key') : '-' }}</div>
        </div>

        <div class="flex justify-between mt-5">
          <div class="flex-1">
            <label class="form-label">中文名稱</label>
            <div class="w-full">{{ isEmpty(data.full_name) }}</div>
          </div>

          <div class="flex-1">
            <label class="form-label">英文名稱</label>
            <div class="w-full">{{ isEmpty(data.full_name_en) + ' ' + checkTextEmpty(data.other_name_en) }}</div>
          </div>
        </div>

        <div class="mt-3">
          <label class="form-label">顯示名稱</label>
          <div class="w-full">{{ isEmpty(data.display_name) }}</div>
        </div>

        <div class="flex justify-between mt-5">
          <div class="flex-1">
            <label class="form-label">電郵</label>
            <div class="w-full">{{ isEmpty(data.email)}}</div>
            <div v-if="false && data.deleted_at == null && data.email_verified_at == null && !verified" class="mt-2">
              <div v-if="sent" class="mb-2">請輸入發送到電郵的認證碼，如沒有收到，請檢查垃圾郵箱</div>
              <div class="flex align-items-center verify-email">
                <input v-if="sent" class="form-control flex-1 mr-2" type="text" v-model="code"/>
                <button v-if="sent" class="btn btn-primary mr-2" @click="onVerify">{{ '確認' }}</button>
                <button class="btn btn-pending" @click="onSendVerifyEmail">{{ sent ? '重新發送' : '發送認證電郵' }}</button>
              </div>
            </div>
          </div>

          <div v-if="false" class="flex-1">
            <label class="form-label">狀態</label>

            <div v-if="!data.is_effective || data.deleted_at != null" class="flex items-center justify-start text-warning">
              <font-awesome-icon icon="fa-regular fa-circle-xmark" />
              <div class="ml-2">無效</div>
            </div>
            <div v-else class="flex items-center justify-start text-success">
              <font-awesome-icon icon="fa-regular fa-square-check" />
              <div class="ml-2">生效</div>
            </div>
          </div>
        </div>

        <div class="mt-3 flex">
          <div class="flex-1">
            <label for="staff_number" class="form-label">辦公室電話</label>
            <div class="w-full">{{ data.office_number ?? '-' }}</div>
          </div>
          <div class="flex-1">
            <label for="cost_centre_code" class="form-label">傳真</label>
            <div class="w-full">{{ data.fax ?? '-' }}</div>
          </div>
        </div>

        <div class="mt-3 flex">
          <div class="flex-1">
            <label for="staff_number" class="form-label">手提電話</label>
            <div class="w-full">{{ data.contact_number ?? '-' }}</div>
          </div>
          <div class="flex-1">
            <label for="cost_centre_code" class="form-label">其他電話</label>
            <div class="w-full">{{ data.other_number ?? '-' }}</div>
          </div>
        </div>

        <div class="mt-3">
          <label class="form-label">出生日期</label>
          <div class="w-full">{{ data.birthdate ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">專長</label>
          <div class="w-full">{{ data.special_skill ?? '-' }}</div>
        </div>

        <div class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6 mt-6" role="alert">
          <span class="font-medium">其他資料</span>
        </div>

        <div class="mt-5">
          <label class="form-label">個人網站</label>
          <a v-if="data.personal_website" :href="getValidUrl(data.personal_website)" target="_blank" class="w-full block text-primary">{{ data.personal_website }}</a>
          <div v-else class="w-full">-</div>
        </div>

        <div class="mt-5">
          <label class="form-label">博客網站</label>
          <a v-if="data.blog_website" :href="getValidUrl(data.blog_website)" target="_blank" class="w-full block text-primary">{{ data.blog_website }}</a>
          <div v-else class="w-full">-</div>
        </div>

        <div class="mt-5">
          <label class="form-label">其他網站</label>
          <a v-if="data.other_website" :href="getValidUrl(data.other_website)" target="_blank" class="w-full block text-primary">{{ data.other_website }}</a>
          <div v-else class="w-full">-</div>
        </div>

        <div class="mt-5">
          <label class="form-label">團體及組織</label>
          <div class="w-full">{{ data.group ?? '-' }}</div>
        </div>

        <div class="mt-5">
          <label class="form-label">榮譽及獎項</label>
          <div class="w-full">{{ data.honors ?? '-' }}</div>
        </div>

        <div class="mt-5">
          <label class="form-label">榮譽及獎項</label>
          <div class="w-full">{{ data.honors ?? '-' }}</div>
        </div>

        <div class="mt-5">
          <label class="form-label">興趣</label>
          <div class="w-full">{{ data.interest ?? '-' }}</div>
        </div>

        <div class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6 mt-6" role="alert">
          <span class="font-medium">教育</span>
        </div>

        <div class="mt-5">
          <label class="form-label">就讀學校</label>
          <div class="w-full">{{ data.school ?? '-' }}</div>
        </div>

        <div class="mt-5 flex">
          <div class="flex-1">
            <label for="academic" class="form-label">獲取的學歷</label>
            <div class="w-full">{{ data.academic ?? '-' }}</div>
          </div>

          <div class="flex-1">
            <label for="academic_department" class="form-label">學系</label>
            <div class="w-full">{{ data.academic_department ?? '-' }}</div>
          </div>

          <div class="flex-1">
            <label for="academic_other" class="form-label">其他資料</label>
            <div class="w-full">{{ data.academic_other ?? '-' }}</div>
          </div>
        </div>

        <div class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6 mt-6" role="alert">
          <span class="font-medium">專業資格</span>
        </div>

        <div class="mt-5 flex">
          <div class="flex-1">
            <label for="professional_qualifications" class="form-label">持有的專業資格</label>
            <div class="w-full">{{ data.professional_qualifications ?? '-' }}</div>
          </div>

          <div class="flex-1">
            <label for="qualification_authority" class="form-label">頒發機構全名</label>
            <div class="w-full">{{ data.qualification_authority ?? '-' }}</div>
          </div>

          <div class="flex-1">
            <label for="qualification_level" class="form-label">已達到的程度</label>
            <div class="w-full">{{ data.qualification_level ?? '-' }}</div>
          </div>
        </div>

        <div class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6 mt-6" role="alert">
          <span class="font-medium">工作經驗</span>
        </div>

        <div class="mt-5 flex">
          <div class="flex-1">
            <label for="past_company" class="form-label">公司名稱</label>
            <div class="w-full">{{ data.past_company ?? '-' }}</div>
          </div>

          <div class="flex-1">
            <label for="past_position" class="form-label">職位</label>
            <div class="w-full">{{ data.past_position ?? '-' }}</div>
          </div>

          <div class="flex-1">
            <label for="past_intro" class="form-label">描述</label>
            <div class="w-full">{{ data.past_intro ?? '-' }}</div>
          </div>
        </div>

        <div class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6 mt-6" role="alert">
          <span class="font-medium">設定</span>
        </div>

        <div class="flex justify-between mt-5">
          <div class="flex-1">
            <label class="form-label">系統通知</label>
            <div class="form-switch mt-2 flex align-items-center">
              <div class="flex-1">報告板變更</div>
              <div class="flex-1">
                <input v-model="form.system.announcement_change" type="checkbox" class="form-check-input" @change="updateSetting">
              </div>
            </div>
            <div class="form-switch mt-2 flex align-items-center">
              <div class="flex-1">行事曆活動</div>
              <div class="flex-1">
                <input v-model="form.system.calendar_event" type="checkbox" class="form-check-input" @change="updateSetting">
              </div>
            </div>
            <div class="form-switch mt-2 flex align-items-center">
              <div class="flex-1">檔案櫃變更</div>
              <div class="flex-1">
                <input v-model="form.system.library_change" type="checkbox" class="form-check-input" @change="updateSetting">
              </div>
            </div>
            <div class="form-switch mt-2 flex align-items-center">
              <div class="flex-1">會議室及資源預借</div>
              <div class="flex-1">
                <input v-model="form.system.resource_booking" type="checkbox" class="form-check-input" @change="updateSetting">
              </div>
            </div>
            <div class="form-switch mt-2 flex align-items-center">
              <div class="flex-1">問卷到期</div>
              <div class="flex-1">
                <input v-model="form.system.survey_due" type="checkbox" class="form-check-input" @change="updateSetting">
              </div>
            </div>
          </div>

          <div class="flex-1">
            <label class="form-label font-bold font-bold">電郵通知</label>
            <div class="form-switch mt-2 flex align-items-center">
              <div class="flex-1">報告板變更</div>
              <div class="flex-1">
                <input v-model="form.email.announcement_change" type="checkbox" class="form-check-input" @change="updateSetting">
              </div>
            </div>
            <div class="form-switch mt-2 flex align-items-center">
              <div class="flex-1">行事曆活動</div>
              <div class="flex-1">
                <input v-model="form.email.calendar_event" type="checkbox" class="form-check-input" @change="updateSetting">
              </div>
            </div>
            <div class="form-switch mt-2 flex align-items-center">
              <div class="flex-1">檔案櫃變更</div>
              <div class="flex-1">
                <input v-model="form.email.library_change" type="checkbox" class="form-check-input" @change="updateSetting">
              </div>
            </div>
            <div class="form-switch mt-2 flex align-items-center">
              <div class="flex-1">會議室及資源預借</div>
              <div class="flex-1">
                <input v-model="form.email.resource_booking" type="checkbox" class="form-check-input" @change="updateSetting">
              </div>
            </div>
            <div class="form-switch mt-2 flex align-items-center">
              <div class="flex-1">問卷到期</div>
              <div class="flex-1">
                <input v-model="form.email.survey_due" type="checkbox" class="form-check-input" @change="updateSetting">
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth'],
  data() {
    return {
      mounted: false,

      data: null,
      sent: false,
      verified: false,
      code: null,

      form: {
        system: {
          library_change: false,
          survey_due: false,
          announcement_change: false,
          calendar_event: false,
          resource_booking: false
        },
        email: {
          library_change: false,
          survey_due: false,
          announcement_change: false,
          calendar_event: false,
          resource_booking: false
        }
      }
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      this.data = this.$cookies.get('profile')

      if (this.data == null) {
        const response = await this.getProfile()
        await this.$cookies.set('profile', response.profile)
        this.data = response.profile
      }

      this.form.system = this.data.notification_settings.system
      this.form.email = this.data.notification_settings.email
      this.mounted = true
    },
    isEmpty(val) {
      return (val != null && val !== '' ? val : '-')
    },
    async updateSetting() {
      const response = await this.updateNotificationSetting(this.form)

      let option = {
        title: '成功',
        message: '已更改設定',
        type: 'success'
      }

      if (response.code !== 0) {
        option = {
          title: '未能更改設定',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)

      this.data.email_notification_enabled = this.form.email_notification_enabled
      this.data.system_notification_enabled = this.form.system_notification_enabled
      await this.$cookies.set('profile', this.data)
    },
    async onSendVerifyEmail() {
      const response = await this.sendEmailVerifyCode()

      let option = {
        title: '成功',
        message: '已發送認證電郵',
        type: 'success'
      }

      if (response.code === 0) {
        this.sent = true
      } else {
        if (response.data.code === 201) {
          this.sent = true
        }

        option = {
          title: '未能發送證認電郵',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    },
    async onVerify() {
      const response = await this.verifyEmail(this.code)

      let option = {
        title: '成功',
        message: '已認證電郵',
        type: 'success'
      }

      if (response.code === 0) {
        this.verified = true
      } else {
        this.code = null
        option = {
          title: '未能認證電郵',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    }
  }
}
</script>

<style lang="scss" scoped>
#profile-page {
  .leading-relaxed {
    min-height: 400px;
  }

  .profile-image {
    width: 100px;
    height: 100px;
  }

  h3 {
    font-size: 18px;
  }

  .verify-email {
    width: 300px;
  }
}
</style>
