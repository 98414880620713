<template>
  <div id="home-page" v-if="currentSite">

    <div class="fixed box event-info text-white" v-if="hoverEvent" :style="`background-color: ${hoverEvent.type == 'resource' ? 'rgb(245, 151, 20)' : getObjectValue(calendars, 'calendar_id', hoverEvent.calendar_id, 'color')}; top: ${hoverRect.top + 2 + hoverRect.height}px; left: ${hoverRect.left}px;`">
      日曆：{{ hoverEvent.calendar_name ?? getObjectValue(calendars, 'calendar_id', hoverEvent.calendar_id, 'name') }}<br>
      名稱：{{ hoverEvent.name }}<br>
      日期：{{
        $moment(hoverEvent.from_time).format('YYYY年MM月DD日 HH:mm')
      }}{{ hoverEvent.is_whole_day || hoverEvent.to_time == null ? '' : (' - ' + $moment(hoverEvent.to_time).format('YYYY年MM月DD日 HH:mm')) }}<br>
      地點：{{ hoverEvent.location ?? '-' }}
    </div>

    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12 2xl:col-span-9">
        <div class="grid grid-cols-12 gap-6 mt-8">

          <!-- BEGIN: Slider -->
          <div class="slider" v-if="currentSite.site_id == 1 && banners.length > 0">
            <div class="mx-6 pb-8">
              <div class="single-item">
                <div v-for="(item, key) in banners" :key="key" class="banner">
                  <div class="h-full image-fit rounded-md overflow-hidden">
                    <a :href="item.url" target="_blank" class="d-block h-full">
                      <img :alt="item.title" :src="item.image"/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END: Slider -->

          <!-- BEGIN: Announcement -->
          <div v-if="currentSite.is_announcement_enabled && checkPermission(permissions, 'site.announcement.view') && announcementTypes[0].announcements.length > 0" class="announcement col-span-12 xl:row-start-auto">
            <div class="intro-y flex items-center h-10">
              <h2 class="text-lg font-medium truncate mr-5">
                <font-awesome-icon icon="fa-solid fa-rss" class="mr-1" /> 報告板
              </h2>
              <router-link to="/announcements" class="more ml-auto text-primary truncate">更多</router-link>
            </div>
            <div class="box mt-5">
              <ul class="nav nav-boxed-tabs announcement-button-container flex" role="tablist">
                <template v-for="(item, key) in announcementTypes">
                  <li :key="key" :id="`announcement-${key}-tab`" class="nav-item flex-1" role="presentation">
                    <button class="nav-link w-full py-2 announcement-button" :class="{'active': key === 0}" data-tw-toggle="pill"
                            :data-tw-target="`#announcement-tab-${key}`" type="button" role="tab"
                            :aria-controls="`announcement-tab-${key}`" aria-selected="true"> {{ item.name }} <span v-if="item.unread_no != null">({{ item.unread_no }})</span>
                    </button>
                  </li>
                </template>
              </ul>

              <div class="tab-content flex-1 announcement-title-container">
                <div v-for="(item, key) in announcementTypes" :key="key" :id="`announcement-tab-${key}`" class="tab-pane leading-relaxed p-5"
                     :class="{'active': key === 0}" role="tabpanel" :aria-labelledby="`announcement-${key}-tab`">
                  <ul>
                    <li v-for="(row, i) in item.announcements" :key="i">
                      <router-link :to="`announcements/${row.announcement_id}`">
                        <div class="ml-2 announcement-date">{{ $moment(row.created_at).format('DD.MM.YYYY') }}</div>
                        <div class="ml-2 announcement-title" :class="{'unread': !row.is_read}">
                          <font-awesome-icon icon="fa-solid fa-thumbtack" class="mr-1" v-if="row.is_pinned"/> {{ row.title }}
                        </div>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- END: Announcement -->

          <!-- BEGIN: Calendar -->
          <div v-if="currentSite.site_id != 1 && currentSite.is_calendar_enabled && checkPermission(permissions, 'site.calendar.view')" class="calendar col-span-12 xl:row-start-auto">
            <div class="intro-y flex items-center h-10">
              <h2 class="text-lg font-medium truncate mr-5">
                <font-awesome-icon icon="fa-regular fa-calendar" class="mr-1" /> 行事曆
              </h2>
              <router-link to="/calendar" class="more ml-auto text-primary truncate">更多</router-link>
            </div>
            <div class="box mt-5 p-2">
              <FullCalendar :options="calendarOptions"/>
            </div>
          </div>
          <!-- END: Calendar -->

          <!-- BEGIN: Library -->
          <div v-if="currentSite.site_id != 1" class="library col-span-12 xl:row-start-auto">
            <div class="intro-y flex items-center h-10">
              <h2 class="text-lg font-medium truncate mr-5">
                <font-awesome-icon icon="fa-regular fa-hard-drive" class="mr-1" /> 檔案櫃
              </h2>
              <router-link to="/library" class="more ml-auto text-primary truncate">更多</router-link>
            </div>
            <div class="box mt-5">

              <!-- BEGIN: Data List -->
              <div class="intro-y col-span-12">
                <div class="">
                  <table class="table table-striped">
                    <thead>
                    <tr>
                        <th class="whitespace-nowrap">類型</th>
                        <th class="whitespace-nowrap w-1/2">名稱</th>
                        <th class="whitespace-nowrap">建立日期</th>
                        <th class="whitespace-nowrap">上次修改日期</th>
                        <th class="whitespace-nowrap">版本</th>
                        <th class="whitespace-nowrap">檔案大小</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr class="" @click="onOpenLibraryItem(item)" v-for="(item, key) in libraryItems" :key="key" :class="{'item-invisible': item.is_hidden || item.isHiddenFromAll}">
                      <td class="">
                        <div class="item-icon bg-contain"
                            :class="{'folder': item.item_type === 'DIRECTORY', 'file': item.item_type === 'FILE'}"><span
                          v-if="item.item_type === 'FILE'">{{ getExtension(item) }}</span></div>
                      </td>
                      <td class="w-1/2">
                        {{ item.item_name ?? '-' }}
                      </td>
                      <td>{{ item.created_at ?? '-' }}</td>
                      <td>{{ item.updated_at ?? '-' }}</td>
                      <td>{{ item.file_versions_count != null ? (item.file_versions_count) : '-' }}</td>
                      <td>{{ item.item_type === 'FILE' ? getFileSize(item.file.file_size) : '' }}</td>
                    </tr>
                  </tbody>
                </table>
                </div>
              </div>
              <!-- END: Data List -->
            </div>
          </div>
          <!-- END: Library -->

          <!-- BEGIN: Essay -->
          <div v-if="currentSite.site_id == 1 && checkPermission(permissions, 'site.announcement.view')" class="essay col-span-12">
            <div class="intro-y flex items-center h-10">
              <h2 class="text-lg font-medium truncate mr-5">
                <font-awesome-icon icon="fa-solid fa-pen" class="mr-1" /> 社服隨筆
              </h2>
              <router-link to="/social-essays?isManager=1" class="more ml-auto text-primary truncate">更多</router-link>
            </div>
            <div class="box overflow-x-auto mt-5">
              <table class="table table-striped">
                <tbody>
                <tr v-for="(item, key) in managerEssays" :key="key" class="pointer" @click="$router.push(`/social-essays/${item.essay_id}`)">
                  <td>{{ $moment(item.created_at).format('YYYY/MM/DD') }}</td>
                  <td>{{ item.title }}</td>
                </tr>
                </tbody>
              </table>

              <div v-if="managerEssays.length === 0" class="p-3">
                <i>沒有資料</i>
              </div>
            </div>
          </div>
          <!-- END: Essay -->

          <!-- BEGIN: Essay -->
          <div v-if="currentSite.site_id == 1 && checkPermission(permissions, 'site.announcement.view')" class="essay col-span-12">
            <div class="intro-y flex items-center h-10">
              <h2 class="text-lg font-medium truncate mr-5">
                <font-awesome-icon icon="fa-solid fa-pen" class="mr-1" /> 新中心/新計劃服務巡禮
              </h2>
              <router-link to="/social-essays?isManager=0" class="more ml-auto text-primary truncate">更多</router-link>
            </div>
            <div class="box overflow-x-auto mt-5">
              <table class="table table-striped">
                <tbody>
                <tr v-for="(item, key) in essays" :key="key" class="pointer" @click="$router.push(`/social-essays/${item.essay_id}`)">
                  <td>{{ $moment(item.created_at).format('YYYY/MM/DD') }}</td>
                  <td>{{ item.title }}</td>
                </tr>
                </tbody>
              </table>

              <div v-if="essays.length === 0" class="p-3">
                <i>沒有資料</i>
              </div>
            </div>
          </div>
          <!-- END: Essay -->

          <!-- BEGIN: Recommend -->
          <div v-if="currentSite.site_id == 1 && currentSite.is_announcement_enabled && recommends.length > 0" class="col-span-12 md:col-span-6 lg:col-span-4">
            <div class="intro-y block sm:flex items-center h-10">
              <h2 class="text-lg font-medium truncate mr-5">
                <font-awesome-icon icon="fa-regular fa-thumbs-up" class="mr-1" /> 推介消息
              </h2>
              <router-link to="/announcements?type=24" class="more ml-auto text-primary truncate">更多</router-link>
            </div>
            <div class="box overflow-x-auto mt-5">
              <table class="table table-striped">
                <tbody>
                <tr v-for="(item, key) in recommends" :key="key" class="pointer" @click="goToAnnouncement(item)">
                  <td>{{ item.title }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- END: Recommend -->

          <!-- BEGIN: Workflow -->
          <div v-if="currentSite.site_id == 1 && currentSite.is_workflow_enabled && checkPermission(permissions, ['system.workflow.email-workflow', 'system.workflow.account-workflow'])" class="col-span-12 md:col-span-6 lg:col-span-4">
            <div class="intro-y block sm:flex items-center h-10">
              <h2 class="text-lg font-medium truncate mr-5">
                <font-awesome-icon icon="fa-regular fa-file-lines" class="mr-1" /> 內聯網申請表
              </h2>
              <router-link to="/workflows" class="more ml-auto text-primary truncate">更多</router-link>
            </div>
            <div class="box overflow-x-auto mt-5">
              <table class="table table-striped">
                <tbody>
                <tr v-for="(item, key) in workflows" :key="key">
                  <td v-if="checkPermission(permissions, `system.workflow.${item.type}-workflow`)">
                    <router-link :to="`/${item.url}`">{{ item.title }}</router-link>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- END: Workflow -->

          <!-- BEGIN: Form -->
          <div v-if="currentSite.site_id == 1 && currentSite.is_form_enabled && checkPermission(permissions, 'site.form.view')" class="col-span-12 md:col-span-6 lg:col-span-4">
            <div class="intro-y block sm:flex items-center h-10">
              <h2 class="text-lg font-medium truncate mr-5">
                <font-awesome-icon icon="fa-solid fa-clipboard-question" class="mr-1" /> 問卷調查
              </h2>
              <router-link to="/forms" class="more ml-auto text-primary truncate">更多</router-link>
            </div>
            <div class="box overflow-x-auto mt-5">
              <table class="table table-striped">
                <tbody>
                <tr v-for="(item, key) in forms" :key="key" class="pointer" @click="goToForm(item)">
                  <td>{{ item.name }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- END: Form -->

          <!-- BEGIN: Forum -->
          <div v-if="currentSite.site_id == 1 && currentSite.is_forum_enabled && checkPermission(permissions, 'site.forum.view')" class="col-span-12 xl:col-span-6">
            <div class="intro-y flex items-center h-10">
              <h2 class="text-lg font-medium truncate mr-5">
                <font-awesome-icon icon="fa-regular fa-comment" class="mr-1" /> 討論區
              </h2>
              <router-link to="/forum" class="more ml-auto text-primary truncate">更多</router-link>
            </div>
            <div class="mt-5">
              <router-link v-for="(item, key) in threads" :key="key" class="d-block intro-y" :to="`/forum/threads/${item.thread_id}`">
                <div class="box px-4 py-4 mb-3 flex items-center zoom-in">
                  <div class="flex-none text-success">
                    <font-awesome-icon icon="fa-regular fa-message" class="fa-2x" />
                  </div>
                  <div class="ml-4 mr-auto">
                    <div class="font-medium">{{ item.title }}</div>
                    <div class="text-slate-500 text-xs mt-0.5">{{ item.updated_at }}</div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <!-- END: Forum -->

          <!-- BEGIN: FAQ -->
          <div v-if="currentSite.site_id == 1 && currentSite.is_knowledge_base_enabled && checkPermission(permissions, 'site.knowledge_base.view')" class="faq col-span-3">
            <div class="intro-y flex items-center h-10">
              <h2 class="text-lg font-medium truncate mr-5">
                <font-awesome-icon icon="fa-regular fa-circle-question" class="mr-1" /> FAQ
              </h2>
            </div>
            <div class="mt-5">
              <router-link to="/faq" class="btn btn-pending w-full d-blcok">
                <font-awesome-icon icon="fa-solid fa-question" />
              </router-link>
            </div>
          </div>
          <!-- END: FAQ -->

          <!-- BEGIN: Report -->
          <div class="report col-span-3" v-if="currentSite.site_id == 1 && checkPermission(permissions, 'centre.performance-report.fill')">
            <div class="intro-y flex items-center h-10">
              <h2 class="text-lg font-medium truncate mr-5">
                <font-awesome-icon icon="fa-solid fa-file" class="mr-1" /> 執行報告
              </h2>
            </div>
            <div class="mt-5">
              <router-link to="/performance-report" class="btn btn-dark w-full d-blcok">
                <font-awesome-icon icon="fa-solid fa-file" />
              </router-link>
            </div>
          </div>
          <!-- END: Report -->

        </div>
      </div>

      <div class="col-span-12 2xl:col-span-3">
        <div class="2xl:border-l -mb-10 pb-10">
          <div class="2xl:pl-6 grid grid-cols-12 gap-x-6 2xl:gap-x-0 gap-y-6 2xl:mt-8">

            <!-- BEGIN: Weather -->
            <div class="weather col-span-12 sm:col-span-6 xl:col-span-4 2xl:col-span-12">
              <div class="box p-3">
                <div class="flex justify-evenly align-items-center">
                  <div v-if="weather" class="icon-container flex-1">
                    <img alt="" :src="`https://www.hko.gov.hk/images/HKOWxIconOutline/pic${weather.icon}.png`"/>
                  </div>

                  <div v-if="weather" class="text-4xl font-medium flex-1 text-center">{{ weather.temperature.data[1].value }}°C</div>

                  <div class="font-medium flex-1" v-if="weather">
                    <div v-if="weather.humidity">
                      濕度：{{ weather.humidity.data[0].value }}%
                    </div>
                    <div v-if="weather.uvindex">
                      紫外線指數：{{ weather.uvindex.data[0].value + ` (${weather.uvindex.data[0].desc})` }}
                    </div>
                  </div>
                </div>

                <div class="text-base text-slate-500 text-left mt-2">{{ weather != null ? (formatDate(weather.temperature.recordTime, 'YYYY年M月D日') + ' ' + getWeekday(weather.temperature.recordTime)) : '' }}</div>
              </div>
            </div>
            <!-- END: Weather -->

            <!-- BEGIN: Active User -->
            <div v-if="currentSite.site_id == 1 && userCount != null" class="active-user col-span-12 md:col-span-6 xl:col-span-4 2xl:col-span-12">
              <div class="box p-5 bg-primary flex align-items-end">
                <div class="mr-auto">
                  <div class="font-medium text-base text-white"> 總用戶人數 </div>
                  <div class="text-white relative text-2xl font-medium leading-5 mt-3.5"> {{ userCount }} </div>
                </div>

                <div>
                  <font-awesome-icon icon="fa-solid fa-user" class="text-white" />
                </div>
              </div>
            </div>
            <!-- END: Active User -->

            <!-- BEGIN: Form Status -->
            <div v-if="currentSite.site_id == 1 && pendingWorkflow > 0 && currentSite.is_form_enabled && (checkPermission(permissions, 'system.workflow.account-workflow') || checkPermission(permissions, 'system.workflow.email-workflow'))" class="col-span-12 md:col-span-6 xl:col-span-4 2xl:col-span-12">
              <div class="box flex">
                <div class="px-4 py-4 flex flex-col justify-between flex-1">
                  <div class="mb-4 font-medium text-base text-center">電子表格<br />需核准</div>
                  <router-link to="/workflows?applicationStatus=PENDING&isHighLevel=" class="relative text-3xl font-medium text-center">{{ pendingWorkflow }}</router-link>
                </div>
              </div>
            </div>
            <!-- END: Form Status -->

            <!-- BEGIN: Hot Search -->
            <div class="hot-search col-span-12 md:col-span-6 xl:col-span-4 2xl:col-span-12">
              <div class="box bg-primary p-5 text-white">
                <div class="flex items-center border-b border-slate-200/60 dark:border-darkmode-400 pb-2 mb-2">
                  <div class="font-medium text-base">熱搜</div>
                </div>
                <div class="flex flex-wrap">
                  <button type="button" v-for="(item, key) in hots" :key="key" @click="getHotLink(item)" class="text-left mt-1 ml-1" :style="`font-size: ${getHotFontSize(item)}px`">{{ getHotTitle(item) }}</button>
                </div>

                <div v-if="hots.length === 0" class="">
                  <i>沒有資料</i>
                </div>
              </div>
            </div>
            <!-- END: Hot Search -->

            <!-- BEGIN: Schedules -->
            <div v-if="currentSite.is_calendar_enabled && checkPermission(permissions, 'site.calendar.view')" class="schedules col-span-12 md:col-span-6 xl:col-span-4 2xl:col-span-12">
              <div class="intro-x flex items-center h-10">
                <h2 class="text-lg font-medium truncate mr-5">
                  <font-awesome-icon icon="fa-regular fa-calendar" class="mr-1" /> 行事暦
                </h2>
              </div>
              <div class="mt-5">
                <div class="box">
                  <v-calendar
                    :attributes="attrs"
                    ref="calendar"
                    :is-required="false"
                    is-expanded
                    trim-weeks
                    :first-day-of-week="0"
                    locale="zh-HK"
                    @update:to-page="loadSideCalendars"
                    @dayclick="selectDay"
                  >
                    <template #day-popover="{ dayTitle, attributes }">
                      <div>
                        <div class="text-xs text-gray-300 font-semibold text-center">
                          {{ dayTitle }}
                        </div>
                        <div v-for="attr in attributes" :key="attr.key">
                          <div class="flex justify-start align-items-center">
                            <div class="custom-dot" :style="`background-color: ${attr.dot.base.style.backgroundColor};`"></div> ({{attr.popover.site}}) {{ attr.popover.label }}
                          </div>
                        </div>
                    </div>
                    </template>
                    </v-calendar>

                  <div class="box overflow-x-auto mt-5">
                    <table class="table table-striped">
                      <tbody>
                      <tr v-for="(item, key) in dayEvents" :key="key" class="pointer" @click="goToCalendar(item)">
                        <td>
                          <div class="flex justify-start align-items-center">
                            <div class="custom-dot" :style="`background-color: ${item.customData.color};`"></div>
                            <strong :style="`color: ${item.customData.color};`">{{ item.customData.label }}</strong>
                          </div>
                          <div class="mt-1">
                            <span v-if="$moment(item.customData.start).format('yyyy-MM-DD') === $moment(item.customData.end).format('yyyy-MM-DD') && $moment(item.customData.start).format('HH:mm') === '00:00' && $moment(item.customData.end).format('HH:mm') === '23:59'">全日</span>
                            <span v-else>{{ $moment(item.customData.start).format('HH:mm') }} 至 {{ $moment(item.customData.end).format('HH:mm') }}</span>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>

                    <div v-if="dayEvents.length === 0" class="p-3 text-center">
                      <i>沒有活動 - 請選擇其他日期</i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- END: Schedules -->

            <!-- BEGIN: Address Book -->
            <div v-if="currentSite.is_phone_directory_enabled && checkPermission(permissions, 'site.phone_directory.view')" class="col-span-12 md:col-span-6 xl:col-span-4 2xl:col-span-12">
              <div class="intro-x flex items-center h-10">
                <h2 class="text-lg font-medium truncate mr-5">
                  <font-awesome-icon icon="fa-regular fa-address-book" class="mr-1" /> 通訊錄
                </h2>
              </div>
              <div class="mt-5">
                <div class="intro-x box p-5">
                  <div class="">
                    <label for="address-book-name" class="form-label">姓名</label>
                    <input id="address-book-name" type="text" class="form-control" placeholder="姓名" v-model="search">
                  </div>
                  <div class="mt-3">
                    <label for="address-book-centre" class="form-label">服務單位</label>
                    <select id="address-book-centre" v-model="centreId" class="form-select mt-2 sm:mr-2" aria-label="請選擇服務單位">
                      <option value="">--- 請選擇 ---</option>
                      <option v-for="(item, key) in centers" :key="key" :value="item.centre_id">{{ item.abbrev }}</option>
                    </select>
                  </div>
                  <div class="mt-3">
                    <router-link class="btn btn-primary w-full" :to="`/address-books?search=${search}&centreId=${centreId}`">搜尋</router-link>
                  </div>
                </div>
                </div>
            </div>
            <!-- END: Address Book -->

            <!-- BEGIN: Booking -->
            <div v-if="currentSite.is_resource_booking_enabled && checkPermission(permissions, ['site.resource-booking.view'])" class="booking col-span-12 md:col-span-6 xl:col-span-4 2xl:col-span-12">
              <div class="intro-y flex items-center h-10">
                <h2 class="text-lg font-medium truncate mr-5">
                  <font-awesome-icon icon="fa-solid fa-clipboard-check" class="mr-1" /> 會議室及資源預借
                </h2>
              </div>
              <div class="mt-5">
                <router-link to="/resource-bookings" class="btn btn-secondary w-full d-blcok">
                  <font-awesome-icon icon="fa-solid fa-clipboard-check" />
                </router-link>
              </div>
            </div>
            <!-- END: Booking -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import interactionPlugin from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'

export default {
  props: ['auth', 'allSites', 'permissions', 'currentSite'],
  components: {
    FullCalendar
  },
  data() {
    return {
      mounted: false,

      profile: null,
      search: '',
      centreId: '',

      centers: [],
      banners: [],
      announcementTypes: [{ name: '最新', type_id: 0, announcements: [], unread_no: null }],
      isAnnouncementTypesLoaded: false,
      managerEssays: [],
      essays: [],
      recommends: [],
      workflows: [
        { title: 'iManage 帳戶申請／更新/刪除', type: 'account', url: 'workflows?type=account' },
        { title: '東華三院電郵系統帳戶設定申請表', type: 'email', url: 'workflows?type=email' }
      ],
      forms: [],
      userCount: null,
      hots: [],
      minViews: 0,
      maxViews: 0,
      threads: [],
      weather: null,
      hotSearches: [
        { title: 'Financial Matters', url: '', fontSize: 20 },
        { title: '保險事項', url: '', fontSize: 13 },
        { title: 'Green Project', url: '', fontSize: 17 },
        { title: '個人資料(私陽)條例', url: '', fontSize: 17 },
        { title: '年報相片收集', url: '', fontSize: 14 },
        { title: 'Purchasing and Supplies', url: '', fontSize: 10 },
        { title: 'Staff Movement', url: '', fontSize: 15 },
        { title: '培訓', url: '', fontSize: 12 },
        { title: '福利', url: '', fontSize: 20 },
        { title: 'Emergency Relief Fund', url: '', fontSize: 17 },
        { title: 'Charitable Fund', url: '', fontSize: 20 }
      ],
      attrs: [],
      dayEvents: [],

      isApprover: false,
      isHighLevelApprover: false,
      pendingWorkflow: 0,
      pendingForm: 0,

      libraryItems: [],

      calendars: [],
      calendarCurrentMonth: '',
      hoverEvent: null,
      hoverRect: null,
      calendarOptions: {
        height: 'auto',
        plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
        eventDisplay: 'block',
        initialView: 'dayGridMonth',
        selectable: true,
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,listWeek'
        },
        buttonText: {
          today: '今天',
          month: '月',
          week: '周',
          day: '日',
          list: '表'
        },
        locale: 'zh-HK',
        events: [],
        initialDate: this.$moment(this.$route.query.date).isValid() ? this.$route.query.date : this.$moment().format('YYYY-MM-DD'),
        dateClick: (info) => {
        },
        eventClick: (info) => {
          this.$router.replace({ path: '/calendar', query: { date: this.calendarCurrentMonth, event_id: info.event.extendedProps.event_id } })
        },
        eventContent: (arg) => {
          const event = arg.event
          let customHtml = ''
          customHtml += `<span class='r10 font-xxs font-bold' style='overflow: hidden;' id='event-${event.extendedProps.event_id}'>${arg.timeText} ${event.title}</span>`
          return { html: customHtml }
        },
        eventMouseEnter: (event, jsEvent, view) => {
          this.hoverRect = event.el.getBoundingClientRect()
          this.hoverEvent = event.event._def.extendedProps
        },
        eventMouseLeave: (event, jsEvent, view) => {
          this.hoverEvent = null
          this.hoverRect = null
        },
        datesSet: (event) => {
          // console.log(event)
          const midDate = new Date((event.start.getTime() + event.end.getTime()) / 2)
          this.calendarCurrentMonth = this.$moment(midDate).format('YYYY-MM')
          // if (!this.$router.currentRoute.fullPath.startsWith(`/calendar?date=${this.currentMonth}`)) {
          //   this.$router.replace({ path: '/calendar', query: { date: this.currentMonth } })
          // }
          this.loadCalendarEvents()
        }
      }
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentSite(val) {
      if (val) {
        this.init()
      }
    },
    allSites(val) {
      if (val) {
        this.init()
      }
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite && this.allSites && !this.mounted) {
        this.profile = await this.$cookies.get('profile')

        if (this.profile == null) {
          const response = await this.getProfile()
          await this.$cookies.set('profile', response.profile)
          this.profile = response.profile
        }

        this.loadBanners()
        this.loadAnnouncements()
        this.loadStatHots()
        this.loadCenters()
        this.loadSideCalendars()

        if (this.currentSite.site_id === 1) {
          this.loadUserCount()
          this.loadRecommends()
          this.loadForms()
          this.loadForumThreads()
          this.loadManagerSocialEssays()
          this.loadSocialEssays()
          await this.loadPendingWorkflows()
          await this.loadConfigs()
          // await this.loadPendingForms()
        } else {
          this.loadLibraryItems()
        }

        this.weather = await this.getWeather()

        setInterval(async() => {
          this.weather = await this.getWeather()
        }, 60000)

        this.mounted = true
      }
    },
    goTo(item) {
      window.open(item.url, '_blank')
    },
    goToAnnouncement(item) {
      this.$router.push(`/announcements/${item.announcement_id}`)
    },
    goToForm(item) {
      if (item.is_submitted) {
        this.$router.push(`/forms/${item.form_id}/response`)
      } else {
        this.$router.push(`/submit-form/${item.form_id}`)
      }
    },
    async loadCenters() {
      this.centers = await this.getCenters()
    },
    async loadRecommends() {
      const response = await this.getSiteAnnouncements(this.currentSite.site_id, '', 24, 20, 1, 'PUBLISHED', false, '')
      this.recommends = response?.data
    },
    async loadBanners() {
      if (this.currentSite == null) {
        return
      }

      this.banners = await this.getSiteBanners(this.currentSite.site_id)

      this.$nextTick(() => {
        this.tinySlider()
      })
    },
    async loadAnnouncements() {
      if (this.currentSite == null) {
        return
      }

      if (!this.isAnnouncementTypesLoaded) {
        const types = await this.getSiteAnnouncementTypes(this.currentSite.site_id)
        this.announcementTypes.push(...types)
        this.isAnnouncementTypesLoaded = true
      }

      for (let i = 0; i < this.announcementTypes.length; i++) {
        const item = this.announcementTypes[i]
        let unreadNo = 0

        if (i === 0) {
          // Get latest 5 Announcements from all types
          const response = await this.getSiteLatestAnnouncements(this.currentSite.site_id)
          item.announcements = response.data

          // Get unread Announcement count
          response.data.forEach((item) => {
            if (!item.is_read) {
              unreadNo++
            }
          })
        } else {
          // Get latest 5 Announcements for each type
          const response = await this.getSiteAnnouncements(this.currentSite.site_id, '', item.type_id, 5, 1, 'PUBLISHED', false, '', 'DESC')
          item.announcements = response.data

          // Get unread Announcement count
          const unreadResponse = await this.getSiteAnnouncements(this.currentSite.site_id, '', item.type_id, 1, 1, 'PUBLISHED', false, true, 'DESC')
          unreadNo = unreadResponse.meta.page.total
        }

        item.unread_no = unreadNo

        this.$set(this.announcementTypes, i, item)
      }

      // Count unread in latest
      let unreadCount = 0
      this.announcementTypes[0].announcements.forEach((item) => {
        if (!item.is_read) {
          unreadCount++
        }
      })

      this.announcementTypes[0].unread_no = unreadCount
    },
    async loadManagerSocialEssays() {
      if (this.currentSite == null) {
        return
      }

      const response = await this.getSiteEssays(this.currentSite.site_id, '', '', 5, 1, 'PUBLISHED', '', '', 1)
      this.managerEssays = response.data
    },
    async loadSocialEssays() {
      if (this.currentSite == null) {
        return
      }

      const response = await this.getSiteEssays(this.currentSite.site_id, '', '', 5, 1, 'PUBLISHED', '', '', 0)
      this.essays = response.data
    },
    async loadForumThreads() {
      if (this.currentSite == null) {
        return
      }

      const response = await this.getSiteForumThreads(this.currentSite.site_id)
      this.threads = response.data
    },
    async loadForms() {
      if (this.currentSite == null) {
        return
      }

      const response = await this.getSiteForms(this.currentSite.site_id, '', 3, 1, 'created_at', 'DESC', 'PUBLISHED')
      this.forms = response.data
    },
    async loadUserCount() {
      const response = await this.getUsers('', '', '', '', true, 1, 1)
      this.userCount = response.meta.page.total
    },
    async loadStatHots() {
      if (this.currentSite == null) {
        return
      }

      this.hots = await this.getStatHots(this.currentSite.site_id)

      let minViews = 2147483647
      let maxViews = 0

      for (let i = 0; i < this.hots.length; i++) {
        minViews = Math.min(minViews, this.hots[i].views)
        maxViews = Math.max(maxViews, this.hots[i].views)
      }

      this.minViews = minViews
      this.maxViews = maxViews
    },
    async loadConfigs() {
      this.configs = await this.getSystemConfig('workflow')

      if (this.configs == null) {
        return
      }

      this.isApprover = parseInt(this.configs.approver_site_id) === parseInt(this.currentSite.site_id) || this.checkPermission(this.permissions, 'system.workflow.account-workflow.approval')
      this.isHighLevelApprover = parseInt(this.configs.high_level_approver_site_id) === parseInt(this.currentSite.site_id)
    },
    async loadPendingWorkflows() {
      if (this.isApprover || this.isHighLevelApprover) {
        const response = await this.getWorkflows('account', 1, 1, 'PENDING', '', true, '', '', '', '', false)
        this.pendingWorkflow = response.meta.page.total
      }
    },
    async loadPendingForms() {
      const response = await this.getSiteForms(this.currentSite.site_id, '', 9999999, 1, this.orderBy, this.order, 'PUBLISHED')

      let count = 0

      response.data.map((item) => {
        if (((!item.is_submitted) || (item.is_submitted && item.is_multi_submit && (item.quota == null || item.quota === 0 || item.quota > item.responses_count))) && (item.to_time == null || this.$moment().isBefore(item.to_time))) {
          count++
        }
        return null
      })

      this.pendingForm = count
    },
    async getHotLink(item) {
      switch (item.module) {
        case 'forum-thread':
          this.$router.push(`/forum/threads/${item.target_id}`)
          break
        case 'library-item':
          if (item.target.item_type === 'DIRECTORY') {
            this.$router.push(`/library/${item.target.item_code}`)
            break
          } else {
            const response = await this.downloadLibraryItem(this.currentSite.site_id, item.target.item_code)
            const blob = new Blob([response])
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = item.target.item_name
            link.click()
            URL.revokeObjectURL(link.href)
            break
          }
        default:
          this.$router.push(`/${item.module}s/${item.target_id}`)
          break
      }
    },
    getHotFontSize(item) {
      const range = this.maxViews - this.minViews
      let percentage = 0
      if (range !== 0) {
        percentage = (item.views - this.minViews) / range
      }

      return percentage * 10 + 12
    },
    getHotTitle(item) {
      if (!item.target) {
        return
      }
      switch (item.module) {
        case 'announcement':
        case 'forum-thread':
          return item.target.title
        case 'library-item':
          return item.target.item_name
        default: return item.target.title ?? item.target.name
      }
    },
    async loadSideCalendars(yearMonth) {
      let selectedDate = this.$moment().format('yyyy-MM-01')

      if (yearMonth != null && yearMonth !== '') {
        selectedDate = `${yearMonth.year}-${yearMonth.month}-01`
      }

      let siteIds = null

      if (this.currentSite.site_id === 1) {
        siteIds = this.allSites.map(item => item.site_id)
      } else {
        siteIds = [this.currentSite.site_id]
      }

      const calendars = await this.getCalendars('month', selectedDate, siteIds)

      for (let i = 0; i < calendars.length; i++) {
        calendars[i].dates = { start: this.$moment(calendars[i].from_time).toDate(), end: this.$moment(calendars[i].to_time).toDate() }
        calendars[i].customData = { start: this.$moment(calendars[i].from_time).toDate(), end: this.$moment(calendars[i].to_time).toDate(), color: calendars[i].calendar.color, label: `(${calendars[i].calendar.site.name}) ` + calendars[i].name, event_id: calendars[i].event_id, site: calendars[i].calendar.site }
        calendars[i].dot = {
          style: {
            backgroundColor: calendars[i].calendar.color
          }
        }

        calendars[i].popover = {
          label: calendars[i].name,
          site: calendars[i].calendar.site.description
        }
      }

      calendars.push({
        key: 'today',
        highlight: {
          color: 'blue',
          fillMode: 'outline'
        },
        dates: new Date()
      })

      this.attrs = calendars
      this.dayEvents = calendars.filter(item => item.dot && this.$moment().isSame(item.customData.start, 'day'))
    },
    async selectDay(day) {
      this.dayEvents = day.attributes.filter(item => item.dot)
    },
    async goToCalendar(item) {
      if (this.currentSite.site_id !== item.customData.site.site_id) {
        // Update CurrentSite in App.vue
        await this.$root.$children[0].updateCurrentSiteData(item.customData.site.site_id)

        setTimeout(() => {
          this.$router.push(`/calendar?date=${this.$moment(item.customData.start).format('yyyy-MM')}&event_id=${item.customData.event_id}`)
        }, 100)
      } else {
        this.$router.push(`/calendar?date=${this.$moment(item.customData.start).format('yyyy-MM')}&event_id=${item.customData.event_id}`)
      }
    },
    async loadCalendars() {
      this.calendars = await this.getSiteCalendars(this.currentSite.site_id)
    },
    async loadCalendarEvents() {
      await this.loadCalendars()

      let events = []

      for (let i = 0; i < this.calendars.length; i++) {
        const data = await this.getSiteCalendarEvents(this.currentSite.site_id, this.calendars[i].calendar_id, 'month', this.calendarCurrentMonth)
        for (let k = 0; k < data.length; k++) {
          data[k].color = this.calendars[i].color
        }
        events = events.concat(data)
      }

      for (let i = 0; i < events.length; i++) {
        events[i].title = events[i].name
        events[i].start = events[i].from_time
        events[i].end = events[i].to_time
        // events[i].type = 'event'
      }

      if (this.checkPermission(this.permissions, 'site.resource-booking.view')) {
        await this.loadResourceBookings()

        for (let i = 0; i < this.resourceBookings.length; i++) {
          const item = {
            title: this.resourceBookings[i].resource.name,
            name: this.resourceBookings[i].resource.name,
            calendar_name: '會議室及資源預借',
            start: this.resourceBookings[i].from_time,
            end: this.resourceBookings[i].to_time,
            bookingId: this.resourceBookings[i].booking_id,
            color: 'orange',
            type: 'resource'
          }

          events.push(item)
        }
      }
      this.calendarOptions.events = events
    },
    async loadLibraryItems() {
      const response = await this.getLibraryItems(this.currentSite.site_id, 0, '', '')

      if (response.code === 0) {
        this.libraryItems = response.data
      } else {
        this.$notify({
          title: '未能載入資料夾',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        })
      }
    },
    onOpenLibraryItem(item) {
      if (item.item_type === 'DIRECTORY') {
        this.$router.push(`/library/${item.item_code}`)
      } else if (item.item_type === 'FILE') {
        this.$router.push(`/library/file/${item.item_code}`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#home-page {
  h2 {
    text-shadow: 2px 4px 4px rgba(200, 200, 200, 1);
  }

  a.more {
    font-size: 16px;
    font-weight: 500;
  }

  .slider {
    grid-column: 1 / -1;
    display: flex;
    justify-content: center;

    > div {
      /* width: 1048px;
      height: 230px; */

      .banner {
        aspect-ratio: 1455 / 450;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .announcement {
    .box {
      min-height: 190px;

      .announcement-title-container {
        overflow-x: hidden;
        overflow-y: scroll;

        ul {
          li {
            margin: 4px 0;

            .announcement-date {
              font-size: 15px;
              color: rgb(var(--color-primary) / var(--tw-bg-opacity));
            }

            .announcement-title {
              position: relative;
              width: 100%;
              font-size: 17px;
              color: rgb(var(--color-primary) / var(--tw-bg-opacity));
              font-weight: bold;
              border-bottom: 1px solid rgb(var(--color-slate-200));

              &.unread::before {
                content: "";
                width: 8px;
                height: 8px;
                position: absolute;
                top: 10px;
                left: -18px;
                border-radius: 9999px;
                --tw-bg-opacity: 1;
                background-color: rgb(var(--color-danger) / var(--tw-bg-opacity));
              }
            }

            &:last-child {
              .announcement-title {
                border: none;
              }
            }
          }
        }
      }
    }
  }

  .essay {
    table {
      tr {
        td:first-child {
          width: 100px;
        }
      }
    }
  }

  .faq, .report {
    a {
      aspect-ratio: 1 / 1;

      svg {
        width: 80%;
        height: 80%;
      }
    }
  }

  .weather {
    .icon-container {
      flex: 0 0 60px;
      padding: 4px;
      border-radius: 8px;
      background-color: #9ca3af;

      img {
        aspect-ratio: 1 / 1;
        border-radius: 10px;
      }
    }

    .report-box__indicator {
      font-size: 12px;
    }
  }

  .active-user {
    svg {
      width: 40px;
      height: 40px;
    }
  }

  .hot-search {
    a {
      display: inline-block;
      margin-right: 8px;
      margin-top: 8px;
    }
  }

  .schedules {
    .vc-container {
      border: 0;
    }
  }

  .booking {
    a {
      aspect-ratio: 3 / 1;

      svg {
        width: 80%;
        height: 80%;
      }
    }
  }

  ::v-deep {
    .custom-dot {
      width: 6px;
      height: 6px;
      flex: 0 0 6px;
      border-radius: 6px;
      margin-right: 10px;
    }
  }

  .event-info {
    padding: 12px;
    z-index: 50;
  }

  .library table {
    tr {
      &:hover {
        td {
          background-color: rgb(var(--color-primary) / 0.3);
        }
      }

      td {
        padding: 4px 4px 4px 1.25rem;
        cursor: pointer;
      }
    }
  }
  .item-icon {
    width: 26px;
    height: 26px;
  }
  .bg-contain {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .folder {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='786' height='786' viewBox='0 0 786 786'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%238a97ac'/%3E%3Cstop offset='1' stop-color='%235d6c83'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg id='Group_3' data-name='Group 3' transform='translate(-567 -93)'%3E%3Crect id='Rectangle_4' data-name='Rectangle 4' width='418' height='681' rx='40' transform='translate(896 109)' fill='%2395a5b9'/%3E%3Crect id='Rectangle_3' data-name='Rectangle 3' width='433' height='681' rx='40' transform='translate(606 93)' fill='%23a0aec0'/%3E%3Crect id='Rectangle_2' data-name='Rectangle 2' width='742' height='734' rx='40' transform='translate(590 145)' fill='%23bec8d9'/%3E%3Crect id='Rectangle_5' data-name='Rectangle 5' width='786' height='692' rx='40' transform='translate(567 187)' fill='url(%23linear-gradient)'/%3E%3C/g%3E%3C/svg%3E%0A");
  }
  .file {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='628.027' height='786.012' viewBox='0 0 628.027 786.012'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%238a97ac'/%3E%3Cstop offset='1' stop-color='%235d6c83'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg id='Group_5' data-name='Group 5' transform='translate(-646 -92.988)'%3E%3Cpath id='Union_2' data-name='Union 2' d='M40,786A40,40,0,0,1,0,746V40A40,40,0,0,1,40,0H501V103h29v24h98V746a40,40,0,0,1-40,40Z' transform='translate(646 93)' fill='url(%23linear-gradient)'/%3E%3Cpath id='Intersection_2' data-name='Intersection 2' d='M.409,162.042l.058-109.9c31.605,29.739,125.37,125.377,125.37,125.377l-109.976.049A20.025,20.025,0,0,1,.409,162.042Z' transform='translate(1147 42)' fill='%23bec8d9' stroke='%23bec8d9' stroke-width='1'/%3E%3C/g%3E%3C/svg%3E%0A");

    span {
      color: #fff;
      font-size: 8px;
      text-decoration: none;
    }
  }
  .item-invisible td:not(.column-item-icon) {
    text-decoration: line-through;
  }
}
@media screen and (max-width: 992px) {
  .announcement-button-container {
    display: flex;
    flex-wrap: wrap;

    .nav-item {
      flex: 1 0 33%;
    }
  }
}
@media screen and (max-width: 767px) {
  #home-page {
    .slider {
      > div {
        grid-column: 1 / 13;
      }
    }
  }
  .announcement-button-container {
    .nav-item {
      flex: 1 0 50%;
    }
  }
}
</style>
