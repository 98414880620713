<template>
    <div>
        <div class="el-tooltip el-tiptap-editor__command-button" aria-describedby="el-tooltip-1942" tabindex="0" @click="loadFileInsertor" data-tooltip="插入檔案">
          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128z"/></svg>
        </div>

        <file-insertor :auth="auth" :current-site="currentSite" :permissions="permissions" :profile="profile" :folder-code="folderCode" @on-select="onSelectInsertFile" ref="fileInsertor"></file-insertor>
    </div>
</template>

<script>
export default {
  name: 'InsertFileButton',
  props: ['isActive', 'auth', 'currentSite', 'permissions', 'profile', 'folderCode'],
  mounted() {
  },

  methods: {
    loadFileInsertor() {
      this.$refs.fileInsertor.onShow()
    },
    async onSelectInsertFile(file) {
      if (file.file.format.mime_type.startsWith('image/')) {
        const image = await this.getLibraryItem(this.currentSite.site_id, file.item_code)
        this.$emit('insertImage', image.data)
      } else {
        this.$emit('insertFile', file)
      }
    }
  }
}
</script>
