<template>
  <div id="performance-reports-page">
    <h2 class="intro-y text-lg font-medium mt-10">
      執行報告
    </h2>

    <div v-if="checkPermission(permissions, 'system.performance-report.manage')">
      <h5 class="intro-y text-lg font-medium mt-4">
        部分
      </h5>

      <div>
        <div class="grid grid-cols-12 gap-6 mt-5">
          <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
            <router-link v-if="checkPermission(permissions, 'system.performance-report.manage')"
                        to="/management/create-performance-report-section" class="btn btn-primary shadow-md mr-2">新增部分
            </router-link>
            <div class="hidden md:block mx-auto text-slate-500">正在顯示第 {{ sections.length !== 0 ? 1 : 0 }}
              至 {{ sections.length ?? 0 }} 個部分
            </div>
          </div>

          <!-- BEGIN: Data List -->
          <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
            <table class="table table-report -mt-2">
              <thead>
              <tr>
                <th class="whitespace-nowrap">中文名稱</th>
                <th class="whitespace-nowrap">英文名稱</th>
                <th class="text-center whitespace-nowrap">表格數目</th>
                <th class="text-center whitespace-nowrap">檢視</th>
                <th
                    v-if="checkPermission(permissions, 'system.performance-report.manage')"
                    class="text-center whitespace-nowrap">動作
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, key) in sections" :key="key" class="intro-x">
                <td>{{ item.section_name_zh }}</td>
                <td>{{ item.section_name_en }}</td>
                <td class="text-center">{{ item.tables.length }}</td>
                <td class="table-report__action text-center w-56">
                  <router-link :to="`/management/performance-reports/${item.section_id}`" class="btn btn-success">
                    <font-awesome-icon icon="fa-regular fa-eye" class="text-white"/>
                  </router-link>
                </td>
                <td v-if="checkPermission(permissions, 'system.performance-report.manage')"
                    class="table-report__action w-56">
                  <div class="flex justify-center items-center">
                    <router-link
                        :to="`/management/update-performance-report-section/${item.section_id}`"
                        class="flex items-center mr-3">
                      <font-awesome-icon icon="fa-solid fa-pen-to-square"/>
                      &nbsp;更改
                    </router-link>
                    <button class="flex items-center text-danger"
                            @click="openDeleteModal('section', item.section_id)">
                      <font-awesome-icon icon="fa-regular fa-trash-can"/>
                      &nbsp;刪除
                    </button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!-- END: Data List -->
        </div>
      </div>

      <hr class="mt-10">

      <h5 class="intro-y text-lg font-medium mt-10">
        回答版本
      </h5>

      <div>
        <div class="grid grid-cols-12 gap-6 mt-5">
          <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
            <router-link v-if="checkPermission(permissions, 'system.performance-report.manage')"
                        to="/management/create-performance-report-version" class="btn btn-primary shadow-md mr-2">新增版本
            </router-link>
            <div class="hidden md:block mx-auto text-slate-500">正在顯示第 {{ versions.length !== 0 ? 1 : 0 }}
              至 {{ versions.length ?? 0 }} 個版本
            </div>
          </div>

          <!-- BEGIN: Data List -->
          <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
            <table class="table table-report -mt-2">
              <thead>
              <tr>
                <th class="whitespace-nowrap">名稱</th>
                <th class="whitespace-nowrap">開始日期時間</th>
                <th class="whitespace-nowrap">結束日期時間</th>
                <th class="whitespace-nowrap text-center">查看回答</th>
                <th
                    v-if="checkPermission(permissions, 'system.performance-report.manage')"
                    class="text-center whitespace-nowrap">動作
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, key) in versions" :key="key" class="intro-x">
                <td>{{ item.name ?? '-' }}</td>
                <td>{{ item.start_at }}</td>
                <td>{{ item.end_at }}</td>
                <td class="table-report__action text-center w-56">
                  <router-link :to="`/management/performance-report-versions/${item.version_id}`" class="btn btn-success">
                    <font-awesome-icon icon="fa-regular fa-eye" class="text-white"/>
                  </router-link>

                  <button type="button" class="btn btn-primary ml-1" @click="onDownload(item)" v-if="!downloading">
                    <font-awesome-icon icon="fa-regular fa-file" class="text-white" />
                  </button>
                  <loading-ring v-if="downloading && currentDownload === item.version_id"></loading-ring>
                </td>
                <td v-if="checkPermission(permissions, 'system.performance-report.manage')"
                    class="table-report__action w-56">
                  <div class="flex justify-center items-center">
                    <router-link :to="`/management/update-performance-report-version/${item.version_id}`"
                                class="flex items-center mr-3">
                      <font-awesome-icon icon="fa-solid fa-pen-to-square"/>
                      &nbsp;更改
                    </router-link>
                    <button class="flex items-center text-danger" @click="openDeleteModal('version', item.version_id)">
                      <font-awesome-icon icon="fa-regular fa-trash-can"/>
                      &nbsp;刪除
                    </button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!-- END: Data List -->
        </div>
      </div>
    </div>
    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>

    <!-- BEGIN: Delete Confirmation Modal -->
    <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger"/>
              <div class="text-3xl mt-5">你確定嗎？</div>
              <div class="text-slate-500 mt-2">
                你是否要刪除此{{ deleteType === 'section' ? '部分' : '版本' }}？
                <br>
                這動作不能還原。
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消
              </button>
              <button type="button" class="btn btn-danger w-24" @click="onDelete">確定</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
  </div>
</template>

<script>
export default {
  props: ['auth', 'permissions'],
  data() {
    return {
      mounted: false,

      sections: [],
      versions: [],

      deleteType: null,
      deleteId: null,

      downloading: false,
      currentDownload: null
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    permissions() {
      this.init()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
    window.tailwind.svgLoader()
  },
  methods: {
    async init() {
      if (this.permissions) {
        await this.loadSections()
        await this.loadVersions()

        this.mounted = true
      }
    },
    async loadSections() {
      const response = await this.getPerformanceReportSections()
      this.sections = response.data
    },
    async loadVersions() {
      const response = await this.getPerformanceReportVersions()
      this.versions = response.data
    },
    openDeleteModal(type, id) {
      this.deleteType = type
      this.deleteId = id

      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDelete() {
      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      let response = ''

      if (this.deleteType === 'section') {
        response = await this.deletePerformanceReportSection(this.deleteId)
      } else {
        response = await this.deletePerformanceReportVersion(this.deleteId)
      }

      let option = {
        title: '成功',
        message: (this.deleteType === 'section' ? '部分' : '版本') + '已被刪除',
        type: 'success'
      }

      if (response.code === 0) {
        if (this.deleteType === 'section') {
          this.loadSections()
        } else {
          this.loadVersions()
        }
      } else {
        option = {
          title: '未能刪除' + (this.deleteType === 'section' ? '部分' : '版本'),
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    },
    async onDownload(item) {
      this.downloading = true
      this.currentDownload = item.version_id

      const response = await this.exportPerformanceReport(item.version_id)
      const blob = new Blob([response], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `f1-${item.version_id}.pdf`
      link.click()
      URL.revokeObjectURL(link.href)
      this.downloading = false
    }
  }
}
</script>

<style lang="scss" scoped>
#performance-reports-page {
  h5 {
    font-size: 16px;
  }
}
</style>
