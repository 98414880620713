import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies'
import VueResource from 'vue-resource'
import VueMoment from 'vue-moment'
import VCalendar from 'v-calendar'
import Multiselect from 'vue-multiselect'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import ElementUI from 'element-ui'
import { ElementTiptapPlugin } from 'element-tiptap'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-tiptap/lib/index.css'
import vueSignature from 'vue-signature'
import Dropzone from 'dropzone'
import VTextMarquee from 'vue-text-marquee'
import infiniteScroll from 'vue-infinite-scroll'
// import Editor from '@tinymce/tinymce-vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

// Import theme component
import mobileMenu from '@/mixin/mobile-menu.js'
import sideMenu from '@/mixin/side-menu.js'
import tinySlider from '@/mixin/tiny-slider.js'
import dropZone from '@/mixin/drop-zone'

import mixin from '@/mixin/mixin.js'
import helper from '@/mixin/helper.js'
import network from '@/mixin/network.js'

import './assets/scss/main.scss'
import './assets/js/app.js'

// Import Page
import App from './App.vue'
import LoginPage from './page/LoginPage.vue'
import HomePage from './page/HomePage.vue'
import ManagementPage from './page/management/ManagementPage.vue'
import UsersPage from './page/management/UsersPage.vue'
import CreateUserPage from './page/management/CreateUserPage.vue'
import UpdateUserPage from './page/management/UpdateUserPage.vue'
import SitesPage from './page/management/SitesPage.vue'
import CreateSitePage from './page/management/CreateSitePage.vue'
import UpdateSitePage from './page/management/UpdateSitePage.vue'
import SiteUsersPage from './page/management/SiteUsersPage.vue'
import SiteGroupsPage from './page/management/SiteGroupsPage.vue'
import AnnouncementTypesPage from './page/management/AnnouncementTypesPage.vue'
import CreateAnnouncementTypePage from './page/management/CreateAnnouncementTypePage'
import UpdateAnnouncementTypePage from './page/management/UpdateAnnouncementTypePage'
import AnnouncementsPage from './page/announcement/AnnouncementsPage'
import CreateAnnouncementPage from '@/page/announcement/CreateAnnouncementPage'
import UpdateAnnouncementPage from '@/page/announcement/UpdateAnnouncementPage'
import AnnouncementPage from '@/page/announcement/AnnouncementPage'
import SiteRolesPage from '@/page/management/SiteRolesPage'
import CreateRolePage from '@/page/management/CreateRolePage'
import UpdateRolePage from '@/page/management/UpdateRolePage'
import SitePermissionsPage from '@/page/management/SitePermissionsPage'
import ForumCategoriesPage from '@/page/management/ForumCategoriesPage'
import CreateForumCategoryPage from '@/page/management/CreateForumCategoryPage'
import UpdateForumCategoryPage from '@/page/management/UpdateForumCategoryPage'
import ForumPage from '@/page/forum/ForumPage'
import CreateThreadPage from '@/page/forum/CreateThreadPage'
import UpdateThreadPage from '@/page/forum/UpdateThreadPage'
import ThreadPage from '@/page/forum/ThreadPage'
import FaqPage from '@/page/faq/FaqPage.vue'
import FaqTypesPage from '@/page/management/FaqTypesPage.vue'
import CreateFaqTypePage from '@/page/management/CreateFaqTypePage.vue'
import UpdateFaqTypePage from '@/page/management/UpdateFaqTypePage.vue'
import CreateKnowledgePage from '@/page/faq/CreateKnowledgePage.vue'
import UpdateKnowledgePage from '@/page/faq/UpdateKnowledgePage.vue'
import ContactsPage from '@/page/contact/ContactsPage.vue'
import ContactPage from '@/page/contact/ContactPage.vue'
import SystemConfigPage from '@/page/management/SystemConfigPage.vue'
import WorkflowsPage from '@/page/workflow/WorkflowsPage.vue'
import CreateEmailWorkflowPage from '@/page/workflow/CreateEmailWorkflowPage.vue'
import UpdateEmailWorkflowPage from '@/page/workflow/UpdateEmailWorkflowPage.vue'
import CreateAccountWorkflowPage from '@/page/workflow/CreateAccountWorkflowPage.vue'
import UpdateAccountWorkflowPage from '@/page/workflow/UpdateAccountWorkflowPage.vue'
import EmailWorkflowPage from '@/page/workflow/EmailWorkflowPage.vue'
import AccountWorkflowPage from '@/page/workflow/AccountWorkflowPage.vue'
import ResourcesPage from '@/page/management/ResourcesPage.vue'
import CreateResourcePage from '@/page/management/CreateResourcePage.vue'
import UpdateResourcePage from '@/page/management/UpdateResourcePage.vue'
import ResourceBookingsPage from '@/page/resource/ResourceBookingsPage.vue'
import CreateResourceBookingPage from '@/page/resource/CreateResourceBookingPage.vue'
import UpdateResourceBookingPage from '@/page/resource/UpdateResourceBookingPage.vue'
import ResourceBookingPage from '@/page/resource/ResourceBookingPage.vue'
import ResultPage from '@/page/ResultPage.vue'
import LibraryPage from '@/page/library/LibraryPage.vue'
import LibraryTagsPage from '@/page/management/LibraryTagsPage.vue'
import CentersPage from '@/page/management/CentersPage.vue'
import CenterPage from '@/page/management/CenterPage.vue'
import CreateCenterPage from '@/page/management/CreateCenterPage.vue'
import UpdateCenterPage from '@/page/management/UpdateCenterPage.vue'
import FilePage from '@/page/FilePage.vue'
import CenterCategoriesPage from '@/page/management/CenterCategoriesPage.vue'
import UpdateCenterCategoryPage from '@/page/management/UpdateCenterCategoryPage.vue'
import CreateCenterCategoryPage from '@/page/management/CreateCenterCategoryPage.vue'
import CalendarPage from '@/page/calendar/CalendarPage.vue'
import CreateCalendarPage from '@/page/calendar/CreateCalendarPage.vue'
import UpdateCalendarPage from '@/page/calendar/UpdateCalendarPage.vue'
import FormsPage from '@/page/form/FormsPage.vue'
import FormPage from '@/page/form/FormPage.vue'
import CreateFormPage from '@/page/form/CreateFormPage.vue'
import UpdateFormPage from '@/page/form/UpdateFormPage.vue'
import SubmitFormPage from '@/page/form/SubmitFormPage.vue'
import FormResponsePage from '@/page/form/FormResponsePage.vue'
import ProfilePage from '@/page/ProfilePage.vue'
import ResourcePage from '@/page/resource/ResourcePage.vue'
import SocialEssaysPage from '@/page/essay/SocialEssaysPage.vue'
import SocialEssayPage from '@/page/essay/SocialEssayPage.vue'
import CreateSocialEssayPage from '@/page/essay/CreateSocialEssayPage.vue'
import UpdateSocialEssayPage from '@/page/essay/UpdateSocialEssayPage.vue'
import CreatePositionPage from '@/page/management/CreatePositionPage.vue'
import UpdatePositionPage from '@/page/management/UpdatePositionPage.vue'
import PositionsPage from '@/page/management/PositionsPage.vue'
import UpdateSubmitFormPage from '@/page/form/UpdateSubmitFormPage.vue'
import LineOfSupervisionsPage from '@/page/management/LineOfSupervisionsPage.vue'
import CreateLineOfSupervisionPage from '@/page/management/CreateLineOfSupervisionPage.vue'
import UpdateLineOfSupervisionPage from '@/page/management/UpdateLineOfSupervisionPage.vue'
import LibraryItemPage from '@/page/library/LibraryItemPage.vue'
import PerformanceReportsPage from '@/page/management/PerformaceReports.vue'
import CreatePerformanceReportSectionPage from '@/page/management/CreatePerformanceReportSectionPage.vue'
import UpdatePerformanceReportSectionPage from '@/page/management/UpdatePerformanceReportSectionPage.vue'
import PerformanceReportTablesPage from '@/page/management/PerformanceReportTablesPage.vue'
import CreatePerformanceReportTablePage from '@/page/management/CreatePerformanceReportTablePage.vue'
import UpdatePerformanceReportTablePage from '@/page/management/UpdatePerformanceReportTablePage.vue'
import UpdatePerformanceReportVersionPage from '@/page/management/UpdatePerformanceReportVersionPage.vue'
import CreatePerformanceReportVersionPage from '@/page/management/CreatePerformanceReportVersionPage.vue'
import PerformanceReportPage from '@/page/PerformanceReportPage.vue'
import UserPage from '@/page/UserPage.vue'
import PerformanceReportVersionPage from '@/page/management/PerformanceReportVersionPage.vue'
import UpdatePasswordPage from '@/page/UpdatePasswordPage.vue'
import BannersPage from '@/page/management/BannersPage.vue'
import CreateBannerPage from '@/page/management/CreateBannerPage.vue'
import UpdateBannerPage from '@/page/management/UpdateBannerPage.vue'
import KnowledgePage from '@/page/faq/KnowledgePage.vue'
import ForgotPasswordPage from '@/page/ForgotPasswordPage.vue'
import ResetPasswordPage from '@/page/ResetPasswordPage.vue'
import LibrarySharePage from '@/page/library/LibrarySharePage.vue'
import UserGroupsPage from '@/page/management/UserGroupsPage.vue'
import CreateUserGroupPage from '@/page/management/CreateUserGroupPage.vue'
import UpdateUserGroupPage from '@/page/management/UpdateUserGroupPage.vue'
import UserGroupUsersPage from '@/page/management/UserGroupUsersPage.vue'
import ContactOthersPage from '@/page/management/ContactOthersPage.vue'
import CreateContactOtherPage from '@/page/management/CreateContactOtherPage.vue'
import UpdateContactOtherPage from '@/page/management/UpdateContactOtherPage.vue'
import ContactOtherUsersPage from '@/page/management/ContactOtherUsersPage.vue'
import MenuShortcutsPage from '@/page/management/MenuShortcutsPage.vue'
import CreateMenuShortcutPage from '@/page/management/CreateMenuShortcutPage.vue'
import UpdateMenuShortcutPage from '@/page/management/UpdateMenuShortcutPage.vue'
import AuditLogsPage from '@/page/management/AuditLogsPage.vue'

// Import Component
const files = require.context('./components', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.use(VueRouter)
Vue.use(VueCookies, { expires: '1y', path: '/', domain: '', secure: '', sameSite: 'Strict' })
Vue.use(VCalendar, {})
Vue.use(VueResource)
const moment = require('moment')
require('moment/locale/zh-hk')
Vue.use(VueMoment, { moment })
Vue.component('multi-select', Multiselect)
Vue.component('date-picker', DatePicker)
Vue.component('vue-signature', vueSignature)

library.add(fas)
library.add(far)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(ElementUI)
Vue.use(ElementTiptapPlugin, {
  lang: 'zh_tw'
})
// Vue.component('tiny-editor', Editor)

Vue.use(VTextMarquee)
Vue.use(infiniteScroll)

const routes = [
  { path: '/', component: LoginPage },
  { path: '/forgot-password', component: ForgotPasswordPage },
  { path: '/reset-password', component: ResetPasswordPage },
  { path: '/home', component: HomePage },
  { path: '/profile', component: ProfilePage },
  { path: '/update-password', component: UpdatePasswordPage },
  { path: '/users/:userId', component: UserPage },
  { path: '/result', component: ResultPage },
  { path: '/announcements', component: AnnouncementsPage },
  { path: '/announcements/:announcementId', component: AnnouncementPage },
  { path: '/create-announcement', component: CreateAnnouncementPage },
  { path: '/update-announcement/:announcementId', component: UpdateAnnouncementPage },
  { path: '/social-essays', component: SocialEssaysPage },
  { path: '/social-essays/:essayId', component: SocialEssayPage },
  { path: '/create-social-essay', component: CreateSocialEssayPage },
  { path: '/update-social-essay/:essayId', component: UpdateSocialEssayPage },
  { path: '/calendar', component: CalendarPage },
  { path: '/create-calendar', component: CreateCalendarPage },
  { path: '/update-calendar/:calendarId', component: UpdateCalendarPage },
  { path: '/address-books', component: ContactsPage },
  { path: '/address-books/:contactId', component: ContactPage },
  // { path: '/update-contact/:contactId', component: UpdateContactPage },
  { path: '/library', component: LibraryPage },
  { path: '/library/:folderCode', component: LibraryPage },
  { path: '/library/file/:fileCode', component: LibraryPage },
  { path: '/library/share/:shareCode', component: LibraryItemPage },
  { path: '/library/share-folder/:shareCode', component: LibrarySharePage },
  { path: '/workflows', component: WorkflowsPage },
  { path: '/workflows/email-workflows/:applicationId', component: EmailWorkflowPage },
  { path: '/workflows/account-workflows/:applicationId', component: AccountWorkflowPage },
  { path: '/workflows/create-email-workflow', component: CreateEmailWorkflowPage },
  { path: '/workflows/update-email-workflow/:applicationId', component: UpdateEmailWorkflowPage },
  { path: '/workflows/create-account-workflow', component: CreateAccountWorkflowPage },
  { path: '/workflows/update-account-workflow/:applicationId', component: UpdateAccountWorkflowPage },
  { path: '/management', component: ManagementPage },
  { path: '/management/users', component: UsersPage },
  { path: '/management/create-user', component: CreateUserPage },
  { path: '/management/update-user/:userId', component: UpdateUserPage },
  { path: '/management/sites', component: SitesPage },
  { path: '/management/create-site', component: CreateSitePage },
  { path: '/management/update-site/:siteId', component: UpdateSitePage },
  { path: '/management/sites/:siteId/users', component: SiteUsersPage },
  { path: '/management/sites/:siteId/groups', component: SiteGroupsPage },
  { path: '/management/sites/:siteId/roles', component: SiteRolesPage },
  { path: '/management/sites/:siteId/create-role', component: CreateRolePage },
  { path: '/management/sites/:siteId/update-role/:roleId', component: UpdateRolePage },
  { path: '/management/sites/:siteId/permissions', component: SitePermissionsPage },
  { path: '/management/user-groups', component: UserGroupsPage },
  { path: '/management/create-user-group', component: CreateUserGroupPage },
  { path: '/management/update-user-group/:groupId', component: UpdateUserGroupPage },
  { path: '/management/user-groups/:groupId/users', component: UserGroupUsersPage },
  { path: '/management/contact-others', component: ContactOthersPage },
  { path: '/management/create-contact-other', component: CreateContactOtherPage },
  { path: '/management/update-contact-other/:otherId', component: UpdateContactOtherPage },
  { path: '/management/contact-others/:otherId/users', component: ContactOtherUsersPage },
  { path: '/management/config', component: SystemConfigPage },
  { path: '/management/banners', component: BannersPage },
  { path: '/management/create-banner', component: CreateBannerPage },
  { path: '/management/update-banner/:bannerId', component: UpdateBannerPage },
  { path: '/management/announcement-types', component: AnnouncementTypesPage },
  { path: '/management/create-announcement-type', component: CreateAnnouncementTypePage },
  { path: '/management/update-announcement-type/:typeId', component: UpdateAnnouncementTypePage },
  { path: '/management/center-categories', component: CenterCategoriesPage },
  { path: '/management/create-center-category', component: CreateCenterCategoryPage },
  { path: '/management/update-center-category/:centerCategoryId', component: UpdateCenterCategoryPage },
  { path: '/management/centers', component: CentersPage },
  { path: '/management/centers/:centreId', component: CenterPage },
  { path: '/management/create-center', component: CreateCenterPage },
  { path: '/management/update-center/:centreId', component: UpdateCenterPage },
  { path: '/management/positions', component: PositionsPage },
  { path: '/management/create-position', component: CreatePositionPage },
  { path: '/management/update-position/:positionId', component: UpdatePositionPage },
  { path: '/management/library-tags', component: LibraryTagsPage },
  { path: '/management/resources', component: ResourcesPage },
  { path: '/management/create-resource', component: CreateResourcePage },
  { path: '/management/update-resource/:resourceId', component: UpdateResourcePage },
  { path: '/management/faq-types', component: FaqTypesPage },
  { path: '/management/create-faq-type', component: CreateFaqTypePage },
  { path: '/management/update-faq-type/:typeId', component: UpdateFaqTypePage },
  { path: '/management/forum-categories', component: ForumCategoriesPage },
  { path: '/management/create-forum-category', component: CreateForumCategoryPage },
  { path: '/management/update-forum-category/:categoryId', component: UpdateForumCategoryPage },
  { path: '/management/performance-reports', component: PerformanceReportsPage },
  { path: '/management/create-performance-report-section', component: CreatePerformanceReportSectionPage },
  { path: '/management/update-performance-report-section/:sectionId', component: UpdatePerformanceReportSectionPage },
  { path: '/management/performance-reports/:sectionId', component: PerformanceReportTablesPage },
  { path: '/management/performance-reports/:sectionId/create-table', component: CreatePerformanceReportTablePage },
  { path: '/management/performance-reports/:sectionId/update-table/:tableId', component: UpdatePerformanceReportTablePage },
  { path: '/management/line-of-supervisions', component: LineOfSupervisionsPage },
  { path: '/management/create-line-of-supervision', component: CreateLineOfSupervisionPage },
  { path: '/management/update-line-of-supervision/:id', component: UpdateLineOfSupervisionPage },
  { path: '/management/create-performance-report-version', component: CreatePerformanceReportVersionPage },
  { path: '/management/update-performance-report-version/:versionId', component: UpdatePerformanceReportVersionPage },
  { path: '/management/performance-report-versions/:versionId', component: PerformanceReportVersionPage },
  { path: '/management/menu-shortcut', component: MenuShortcutsPage },
  { path: '/management/create-menu-shortcut', component: CreateMenuShortcutPage },
  { path: '/management/update-menu-shortcut/:shortcutId', component: UpdateMenuShortcutPage },
  { path: '/management/audit-logs', component: AuditLogsPage },
  { path: '/forms', component: FormsPage },
  { path: '/forms/:formId', component: FormPage },
  { path: '/create-form', component: CreateFormPage },
  { path: '/update-form/:formId', component: UpdateFormPage },
  { path: '/submit-form/:formId', component: SubmitFormPage },
  { path: '/forms/:formId/response', component: FormResponsePage },
  { path: '/forms/:formId/update-response/:responseId', component: UpdateSubmitFormPage },
  { path: '/resource-bookings', component: ResourceBookingsPage },
  { path: '/resource-bookings/:bookingId', component: ResourceBookingPage },
  { path: '/create-resource-booking', component: CreateResourceBookingPage },
  { path: '/update-resource-booking/:bookingId', component: UpdateResourceBookingPage },
  { path: '/resources/:resourceId', component: ResourcePage },
  { path: '/faq', component: FaqPage },
  { path: '/faq/knowledge/:knowledgeId', component: KnowledgePage },
  { path: '/faq/create-knowledge', component: CreateKnowledgePage },
  { path: '/faq/update-knowledge/:knowledgeId', component: UpdateKnowledgePage },
  { path: '/forum', component: ForumPage },
  { path: '/forum/threads/:threadId', component: ThreadPage },
  { path: '/forum/create-thread', component: CreateThreadPage },
  { path: '/performance-report', component: PerformanceReportPage },
  { path: '/forum/update-thread/:threadId', component: UpdateThreadPage },
  { path: '/file/:itemCode', component: FilePage, name: 'file' }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

Vue.config.productionTip = false

Vue.mixin(sideMenu)
Vue.mixin(mobileMenu)
Vue.mixin(tinySlider)
Vue.mixin(dropZone)

Vue.mixin(mixin)
Vue.mixin(helper)
Vue.mixin(network)

Dropzone.autoDiscover = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
