<template>
  <div id="centers-page">

    <h2 class="intro-y text-lg font-medium mt-10">
      部/組/服務單位 (共{{ total }}個)
    </h2>

    <div v-if="checkPermission(permissions, 'system.centre.view')">
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
          <router-link v-if="checkPermission(permissions, 'system.centre.create')" to="/management/create-center" class="btn btn-primary shadow-md mr-2">新增</router-link>
          <div class="hidden md:block mx-auto text-slate-500">正在顯示第 {{ from }} 至 {{ to }} 個
          </div>

          <div v-if="!loading" class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
            <div class="w-56 relative text-slate-500">
              <input @keyup.enter="loadCenters" v-model="search" type="text" class="form-control w-56 box pr-10" placeholder="搜尋">
              <font-awesome-icon @click="loadCenters" icon="fa-solid fa-magnifying-glass" class="absolute my-auto w-4 h-4 mr-3 right-0 inset-y-0"/>
            </div>
          </div>

          <div v-show="loading" class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0 flex">
            <span><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>
        </div>

        <!-- BEGIN: Data List -->
        <div class="intro-y col-span-12 overflow-auto overflow-visible">
          <table class="table table-report -mt-2">
            <thead>
            <tr>
              <th class="whitespace-nowrap"><order-button title="單位編號" column="cost_centre_code" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="whitespace-nowrap"><order-button title="簡稱" column="abbrev" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="whitespace-nowrap"><order-button title="英文名稱" column="name_en" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="whitespace-nowrap"><order-button title="修改時間" column="updated_at" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="whitespace-nowrap"><order-button title="服務" column="service_site" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="whitespace-nowrap"><order-button title="主管經理" column="supervision" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="whitespace-nowrap"><order-button title="中文名稱" column="name_zh" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="whitespace-nowrap"><order-button title="中文地址" column="address_zh" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="whitespace-nowrap"><order-button title="英文地址" column="address_en" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="whitespace-nowrap"><order-button title="電話" column="phone" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="whitespace-nowrap"><order-button title="電郵" column="email" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="text-center whitespace-nowrap">檢視</th>
              <th class="text-center whitespace-nowrap" v-if="checkPermission(permissions, ['system.centre.edit', 'system.centre.delete'])">動作</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, key) in centers" :key="key" class="intro-x">
              <td>
                {{ item.cost_centre_code ?? '-' }}
              </td>
              <td>
                {{ item.abbrev ?? '-' }}
              </td>
              <td>
                {{ item.name_en ?? '-' }}
              </td>
              <td>
                {{ $moment(item.updated_at).format('YYYY-MM-DD') ?? '-' }}
              </td>
              <td>
                {{ item.service_site != null ? item.service_site.name : '-' }}
              </td>
              <td>
                {{ item.supervision != null ? item.supervision.name : '-' }}
              </td>
              <td>
                {{ item.name_zh ?? '-' }}
              </td>
              <td>
                {{ item.address_zh ?? '-' }}
              </td>
              <td>
                {{ item.address_en ?? '-' }}
              </td>
              <td>
                {{ item.phone ?? '-' }}
              </td>
              <td>
                {{ item.email ?? '-' }}
              </td>
              <td class="table-report__action text-center">
                <router-link :to="`/management/centers/${item.centre_id}`" class="btn btn-success">
                  <font-awesome-icon icon="fa-regular fa-eye" class="text-white" />
                </router-link>
              </td>
              <td class="table-report__action w-56" v-if="checkPermission(permissions, ['system.centre.edit', 'system.centre.delete'])">
                <div class="flex justify-center items-center">
                  <router-link v-if="checkPermission(permissions, 'system.centre.edit')" :to="`/management/update-center/${item.centre_id}`" class="flex items-center mr-3">
                    <font-awesome-icon icon="fa-solid fa-pen-to-square"/>
                    &nbsp;更改
                  </router-link>
                  <button v-if="checkPermission(permissions, 'system.centre.delete')" class="flex items-center text-danger" @click="openDeleteModal(item.centre_id)">
                    <font-awesome-icon icon="fa-regular fa-trash-can"/>
                    &nbsp;刪除
                  </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- END: Data List -->
        <!-- BEGIN: Pagination -->
        <pagination-row :pages="pages" :current-page.sync="currentPage"
                        :item-per-page.sync="itemPerPage"></pagination-row>
        <!-- END: Pagination -->
      </div>
    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>

    <!-- BEGIN: Delete Confirmation Modal -->
    <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger"/>
              <div class="text-3xl mt-5">你確定嗎？</div>
              <div class="text-slate-500 mt-2">
                你是否要刪除此部/組/服務單位？
                <br>
                這動作不能還原。
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
              <button type="button" class="btn btn-danger w-24" @click="onDelete">確定</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
  </div>
</template>

<script>
export default {
  props: ['auth', 'permissions'],
  data() {
    return {
      mounted: false,
      loading: 0,

      centers: [],

      itemPerPage: 50,
      currentPage: 1,
      pages: 0,
      from: 0,
      to: 0,
      total: 0,
      search: '',
      orderBy: '',
      order: '',

      deleteId: null,

      serviceSites: [],
      buildingSites: [],
      districtSites: []
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentPage(val) {
      this.loadCenters(false)
    },
    itemPerPage(val) {
      this.loadCenters()
    },
    order() {
      this.loadCenters()
    },
    async search(val) {
      if (val === '') {
        await this.loadCenters()
      }
    }
  },
  async mounted() {
    window.tailwind.svgLoader()

    if (!this.auth) {
      return
    }
    this.init()
  },
  methods: {
    async init() {
      await this.loadCenters()
      // await this.loadSystemSites()

      setTimeout(() => {
        this.mounted = true
      }, 500)
    },
    async loadCenters(resetCurrentPage = true) {
      this.loading++

      const response = await this.getCenters(this.search, this.itemPerPage, this.currentPage, this.orderBy, this.order)
      this.centers = response.data
      this.pages = response.meta.page.last_page
      this.from = response.meta.page.from ?? 0
      this.to = response.meta.page.to ?? 0
      this.total = response.meta.page.total

      if (resetCurrentPage) {
        this.currentPage = 1
      }

      this.loading--
    },
    async loadSystemSites() {
      this.loading++

      this.serviceSites = await this.getSites('PROJECT')
      this.buildingSites = await this.getSites('BUILDING')
      this.districtSites = await this.getSites('DISTRICT')

      this.loading--
    },
    openDeleteModal(id) {
      this.deleteId = id

      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDelete() {
      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      const response = await this.deleteCenter(this.deleteId)

      let option = {
        title: '成功',
        message: '部/組/服務單位已被刪除',
        type: 'success'
      }

      if (response.code === 0) {
        await this.loadCenters()
        this.updateList()
      } else {
        option = {
          title: '未能刪除部/組/服務單位',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    }
  }
}
</script>

<style lang="scss" scoped></style>
