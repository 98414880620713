<template>
  <div id="app" :class="{'home py-5 md:py-0' : auth && !isFile}">
    <site-menu ref="site_menu" v-if="auth && !isFile" :user-sites="userSites" :current-site="currentSite"></site-menu>
    <top-bar v-if="auth && !isFile" @toggle-site-menu="toggleSiteMenu" :current-site="currentSite" :pages="pages" :unread="unread" :notifications="notifications" @load-notification="loadNotifications" :notification-end="notificationEnd" @check-has-unread="loadNotificationStatistics" :notification-loading="notificationLoading"></top-bar>
    <mobile-menu ref="mobile_menu" @toggle-mobile-menu="toggleMobileMenu" :current-site="currentSite" :permissions="permissions" @pass-pages="getPages"></mobile-menu>

    <div :class="{'flex overflow-hidden': auth && !isFile}">
      <mobile-top-bar v-if="auth && !isFile" :current-site="currentSite" :permissions="permissions" @toggle-mobile-menu="toggleMobileMenu"></mobile-top-bar>
      <side-menu ref="side_menu" v-if="auth && !isFile" :current-site="currentSite" :permissions="permissions" @pass-pages="getPages"></side-menu>
      <!-- BEGIN: Content -->
      <div :class="{'content': auth && !isFile}">
        <router-view :auth="auth" :all-sites="allSites" :user-sites="userSites" :current-site="currentSite" :permissions="permissions" :profile="profile"></router-view>
      </div>
      <!-- END: Content -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      auth: false,
      isFile: false,
      currentSite: null,
      userSites: [],
      allSites: [],
      permissions: [],
      unread: 0,
      notifications: [],
      notificationPage: 1,
      notificationEnd: false,
      profile: null,
      pages: [],
      menuItems: [],
      notificationLoading: false
    }
  },
  watch: {
    async '$route'(val) {
      // this.$nextTick(() => {
      // })
      if (this.auth) {
        if (this.currentSite) {
          await this.getCurrentPermission()
        } else {
          if (this.currentSite == null || this.currentSite === '') {
            this.toggleSiteMenu()
          }
        }

        this.loadNotificationStatistics()
        this.notifications = []
        this.notificationPage = 0
        this.notificationEnd = false
        this.loadNotifications()
      }
    },
    currentSite(val) {
      this.loadMenuItems()
    },
    menuItems(val) {
      this.$refs.side_menu.menuItems = val
      this.$refs.mobile_menu.menuItems = val
    }
  },
  mounted() {
    this.$moment().locale('zh-hk')
    this.isLoggedIn()

    if (this.$router.currentRoute.name === 'file') {
      this.isFile = true
    }
  },
  methods: {
    async loadMenuItems() {
      if (this.currentSite) {
        this.menuItems = [
          { name: '報告板', url: '/announcements', icon: 'fa-solid fa-rss', sitePermission: this.currentSite.is_announcement_enabled, permission: 'site.announcement.view' },
          { name: '行事曆', url: '/calendar', icon: 'fa-regular fa-calendar', sitePermission: this.currentSite.is_calendar_enabled, permission: 'site.calendar.view' },
          { name: '通訊錄', url: '/address-books', icon: 'fa-regular fa-address-book', sitePermission: this.currentSite.is_phone_directory_enabled, permission: 'site.phone_directory.view' },
          { name: '檔案櫃', url: '/library', icon: 'fa-regular fa-hard-drive', sitePermission: true, permission: null },
          { name: '內聯網申請表', url: '/workflows', icon: 'fa-solid fa-table-columns', sitePermission: this.currentSite.is_workflow_enabled, permission: ['system.workflow.email-workflow', 'system.workflow.account-workflow'] },
          { name: '問巻/電子表格', url: '/forms', icon: 'fa-regular fa-file-lines', sitePermission: this.currentSite.is_form_enabled, permission: 'site.form.view' },
          { name: '會議室及資源預借', url: '/resource-bookings', icon: 'fa-solid fa-clipboard-check', sitePermission: this.currentSite.is_resource_booking_enabled, permission: ['site.resource-booking.view'] },
          { name: 'FAQ', url: '/faq', icon: 'fa-regular fa-circle-question', sitePermission: this.currentSite.is_knowledge_base_enabled, permission: 'site.knowledge_base.view' },
          { name: '討論區', url: '/forum', icon: 'fa-regular fa-comment', sitePermission: this.currentSite.is_forum_enabled, permission: 'site.forum.view' },
          { name: '執行報告', url: '/performance-report', icon: 'fa-regular fa-file', sitePermission: true, permission: 'centre.performance-report.fill' },
          { name: '系統管理', url: '/management', icon: 'fa-solid fa-gear', sitePermission: true, permission: ['site.manage'] }
        ]

        await this.loadMenuShortcuts()
      }
    },
    async loadMenuShortcuts(site) {
      const response = await this.getSiteMenuShortcuts(this.currentSite.site_id)

      for (let i = 0; i < response.length; i++) {
        if (response[i].is_effective) {
          this.menuItems.push({
            name: response[i].name,
            url: response[i].url,
            target: response[i].is_open_newwindow ? '_blank' : '',
            icon: 'fa-solid fa-link',
            externalLink: true
          })
        }
      }
    },
    toggleMobileMenu(val) {
      this.$refs.mobile_menu.show = !this.$refs.mobile_menu.show
    },
    async isLoggedIn() {
      // Check accessToken exist
      const accessToken = this.$cookies.get('accessToken')
      if (accessToken == null || accessToken === '') {
        // Check user is logged in or not
        this.auth = false
        // Store before path
        if (this.$route.path != null && this.$route.path !== '' && this.$route.path !== '/' && this.$route.path !== '/forgot-password' && this.$route.path !== '/reset-password') {
          this.$cookies.set('enterUrl', this.$route.path)
          this.$router.push('/')
        }
      } else {
        await this.getProfileAndCurrentSite()
        // await this.getUserSites()
        await this.getAllSites()

        if (this.currentSite == null || this.currentSite === '') {
          this.toggleSiteMenu()
        }
      }
    },
    getPages(value) {
      this.pages = value
    },
    async getProfileAndCurrentSite() {
      this.auth = true

      const response = await this.getProfile()
      this.profile = response.profile
      this.userSites = response.sites
      await this.$cookies.set('profile', response.profile)
      await this.$cookies.set('sites', response.sites)

      // const departmentId = response.profile.current_department_id

      this.currentSite = await this.$cookies.get('currentSite')

      if (this.currentSite == null || this.currentSite === '') {
        if (response.sites.length > 0) {
          this.currentSite = response.sites[0]
          this.setCurrentSiteCookies(this.currentSite)
        }
      }
      // Get Permissions
      await this.getCurrentPermission()

      // Get Notifications
      // this.loadNotificationStatistics()
      this.loadNotificationStatistics()
      this.loadNotifications()
    },
    async setCurrentSiteCookies(currentSite) {
      delete currentSite.permissions
      delete currentSite.roles
      await this.$cookies.set('currentSite', currentSite)
    },
    async getUserSites() {
      const response = await this.getProfile()
      this.userSites = response.sites
    },
    async getAllSites() {
      this.allSites = await this.getSites()
    },
    async getCurrentPermission() {
      if (this.currentSite != null) {
        const response = await this.getUserSitePermission(this.currentSite.site_id, true)
        if (response != null) {
          this.permissions = response.permissions
        }
      }
    },
    async loadNotificationStatistics() {
      const response = await this.getNotificationStatistics()
      if (response != null) {
        this.unread = response.unread ? response.unread : 0
      }
    },
    async loadNotifications() {
      this.notificationLoading = true
      const response = await this.getNotifications(10, this.notificationPage++)

      if (response.length === 0) {
        this.notificationEnd = true
      } else {
        this.notifications = [...this.notifications, ...response]
      }

      this.notificationLoading = false
    },
    toggleSiteMenu(val) {
      if (!this.$refs.site_menu) {
        return
      }

      this.$refs.site_menu.show = !this.$refs.site_menu.show
    },
    async updateCurrentSiteData(siteId = null) {
      let currentSiteId = null
      if (siteId == null) {
        const currentSite = this.$cookies.get('currentSite')
        console.log(currentSite)
        currentSiteId = currentSite.site_id
      } else {
        currentSiteId = siteId
      }

      this.currentSite = await this.getSite(currentSiteId)
      this.setCurrentSiteCookies(this.currentSite)
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
