<template>
  <div id="top-bar">
    <!-- BEGIN: Top Bar -->
    <div
      class="top-bar-boxed h-[70px] md:h-[65px] z-[51] border-b border-white/[0.08] mt-12 md:mt-0 -mx-3 sm:-mx-8 md:-mx-0 px-3 md:border-b-0 relative md:fixed md:inset-x-0 md:top-0 sm:px-8 md:px-10 md:pt-10 md:bg-gradient-to-b md:from-slate-100 md:to-transparent dark:md:from-darkmode-700">
      <div class="h-full flex items-center">
        <!-- BEGIN: Logo -->
        <router-link to="/home" class="logo -intro-x hidden md:flex block flex flex-column align-items-center">
          <img alt="" class="logo__image" src="/images/logo_small.png">
        </router-link>
        <!-- END: Logo -->

        <!-- BEGIN: Breadcrumb -->
        <nav aria-label="breadcrumb" class="-intro-x h-[45px] mr-auto">
          <ol class="breadcrumb breadcrumb-light">
            <li class="breadcrumb-item hidden md:block">
              <button type="button" class="font-bold text-lg" @click="onGoHome">iManage</button>
            </li>
            <li class="breadcrumb-item font-bold text-lg" v-if="currentSite"><router-link to="/home">{{ currentSite.name }}</router-link></li>

            <li v-if="showSubpage()" class="breadcrumb-item active" aria-current="page">{{ subPage }}</li>
          </ol>
        </nav>
        <!-- END: Breadcrumb -->

        <a href="https://efaith.atlassian.net/servicedesk/customer/portal/24" target="_blank" class="help-desk intro-x relative mr-4 text-md text-white">
          <font-awesome-icon icon="fa-regular fa-circle-question" class="mr-2" /> eFaith Help Desk
        </a>

        <!-- BEGIN: Search -->
        <div class="intro-x relative mr-3 sm:mr-6 hidden md:block">
          <div class="search hidden sm:block">
            <input type="text" class="search__input form-control border-transparent" placeholder="搜尋..." v-model="search" @keyup.enter="onSearch">
            <button class="search__icon dark:text-slate-500" type="button" @click="onSearch"><font-awesome-icon icon="fa-solid fa-magnifying-glass" /></button>
          </div>
          <a class="notification notification--light sm:hidden" href=""> <i data-lucide="search"
                                                                            class="notification__icon dark:text-slate-500"></i>
          </a>
        </div>
        <!-- END: Search -->

        <!-- BEGIN: Notifications -->
        <div class="intro-x dropdown mr-4 sm:mr-6">
          <div class="dropdown-toggle notification cursor-pointer" role="button" aria-expanded="false" data-tw-toggle="dropdown" :class="{'notification--bullet': unread > newRead}">
            <font-awesome-icon icon="fa-regular fa-bell" class="notification__icon dark:text-slate-500" />
          </div>
          <div class="notification-content pt-2 dropdown-menu">
            <div v-infinite-scroll="loadMoreNotification" :infinite-scroll-disabled="loading && notificationEnd" class="notification-content__box dropdown-content">
              <div class="notification-content__title">通知</div>

              <div v-for="(item, key) in notifications" :key="key" class="cursor-pointer relative flex items-center" :class="{'mt-5': key !== 0}" @click="onReadNotification(item)">
                <div class="notification-site mr-1 bg-primary text-white flex justify-center align-items-center font-medium" :class="{'unread': !item.is_readed }" v-if="item.site">
                  {{ item.site.name }}
                </div>
                <div class="ml-2 overflow-hidden w-full">
                  <div class="flex items-between">
                    <div class="font-medium truncate mr-5 flex-1">
                      <v-text-marquee v-if="isTooLong(item.message, 20)" :speed="30" :content="item.message"></v-text-marquee>
                      <div v-else> {{ item.message }}</div>
                    </div>
                    <div class="text-xs text-slate-400 ml-auto whitespace-nowrap">{{ $moment(item.created_at).format('MM-DD HH:mm') }}</div>
                  </div>
                  <div class="w-full truncate text-slate-500 mt-0.5">
                    <v-text-marquee v-if="isTooLong(item.title, 17)" :speed="30" :content="item.title"></v-text-marquee>
                    <div v-else> {{ item.title }}</div>
                  </div>
                </div>
              </div>

              <button type="button" class="btn btn-outline-secondary w-full mt-4" @click="loadMoreNotification" v-if="!notificationEnd">載入更多</button>
              <div v-show="notificationLoading" class="flex justify-center mt-2"><i data-loading-icon="oval" class="w-8 h-8"></i></div>
            </div>
          </div>
        </div>
        <!-- END: Notifications -->

        <!-- BEGIN: Site Menu -->
        <div class="intro-x mr-4 sm:mr-6">
          <button class="site" type="button" @click="toggleSiteMenu">
            <font-awesome-icon icon="fa-solid fa-sitemap" class="dark:text-slate-500" />
          </button>
        </div>
        <!-- END: Site Menu -->

        <!-- BEGIN: Account Menu -->
        <div class="intro-x dropdown w-8 h-8">
          <div class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110"
               role="button" aria-expanded="false" data-tw-toggle="dropdown">
            <img alt="" class="tooltip rounded-full" :src="profile != null && profile.avatar_url != null && profile.avatar_url !== '' ? profile.avatar_url : '/images/user.png'">
          </div>
          <div class="dropdown-menu w-56">
            <ul
              class="profile-menu dropdown-content bg-primary/80 before:block before:absolute before:bg-black before:inset-0 before:rounded-md before:z-[-1] text-white">
              <li class="p-2">
                <div v-if="profile != null" class="font-medium">{{ profile.display_name }}</div>
                <div v-if="currentSite != null" class="text-xs text-white/60 mt-0.5 dark:text-slate-500">{{ currentSite.name }}</div>
              </li>
              <li>
                <hr class="dropdown-divider border-white/[0.08]">
              </li>
              <li>
                <router-link to="/profile" class="dropdown-item hover:bg-white/5"> <font-awesome-icon icon="fa-solid fa-user" class="w-4 h-4 mr-2" /> 個人檔案 </router-link>
              </li>
              <li>
                <hr class="dropdown-divider border-white/[0.08]">
              </li>
              <li>
                <router-link to="/update-password" class="dropdown-item hover:bg-white/5"> <font-awesome-icon icon="fa-solid fa-key" class="w-4 h-4 mr-2" /> 更改密碼 </router-link>
              </li>
              <li>
                <hr class="dropdown-divider border-white/[0.08]">
              </li>
              <li>
                <a @click="onLogout" class="pointer dropdown-item hover:bg-white/5"> <font-awesome-icon icon="fa-solid fa-right-from-bracket" class="w-4 h-4 mr-2" /> 登出
                </a>
              </li>
            </ul>
          </div>
        </div>
        <!-- END: Account Menu -->
      </div>
    </div>
    <!-- END: Top Bar -->
  </div>
</template>

<script>
export default {
  props: ['currentSite', 'pages', 'unread', 'notifications', 'notificationEnd', 'notificationLoading'],
  data() {
    return {
      profile: null,
      subPage: null,
      search: null,
      lastSearch: null,
      newRead: 0,
      loading: false
    }
  },
  watch: {
    '$route'(val) {
      this.showSubpage()
      this.search = null
      if (this.$route.path !== '/result') {
        this.lastSearch = null
      }
    },
    pages() {
      this.showSubpage()
    },
    notificationEnd(val) {
      this.loading = val || this.notificationLoading
    },
    notificationLoading(val) {
      this.loading = val || this.notificationEnd
    }
  },
  async mounted() {
    if (this.$route.path === '/result') {
      this.lastSearch = this.$route.query.search ?? null
    }

    this.profile = this.$cookies.get('profile')

    if (this.profile == null) {
      const response = await this.getProfile()
      await this.$cookies.set('profile', response.profile)
      this.profile = response.profile
    }

    window.tailwind.svgLoader()
  },
  methods: {
    showSubpage() {
      if (this.pages == null) {
        return false
      }

      for (let i = 0; i < this.pages.length; i++) {
        if (this.$route.path.includes(this.pages[i].url)) {
          this.subPage = this.pages[i].name
          return true
        }
      }
      return false
    },
    async onGoHome() {
      if (this.currentSite.site_id === 1) {
        if (this.$route.path !== '/home') {
          this.$router.push('/home')
        }
      } else {
        await this.$root.$children[0].updateCurrentSiteData(1)
        this.$router.go('/home')
      }
    },
    toggleSiteMenu() {
      this.$emit('toggle-site-menu')
    },
    onSearch() {
      if (this.search == null || this.search === '') {
        return
      }

      if (this.lastSearch !== this.search) {
        this.$router.push({
          path: '/result',
          query: { search: this.search }
        })
      }

      this.lastSearch = this.search
    },
    onLogout() {
      // Mark user is signed in
      this.$root.$children[0].auth = false

      // Hide menu
      const el = document.querySelector('.profile-menu')
      const dropdown = window.tailwind.Dropdown.getOrCreateInstance(el)
      dropdown.hide()

      this.$cookies.remove('accessToken')
      this.$cookies.remove('profile')
      this.$cookies.remove('sites')
      this.$cookies.remove('currentSite')
      this.$router.push('/')
    },
    loadMoreNotification() {
      if (this.notificationEnd) {
        return
      }
      this.$emit('load-notification')
    },
    async onReadNotification(item) {
      if (!item.is_readed) {
        item.is_readed = true
        this.newRead++

        await this.readNotification(item.notification_id)
      }

      // Update CurrentSite in App.vue
      await this.$root.$children[0].updateCurrentSiteData(item.site.site_id)

      const path = this.$router.currentRoute.path
      // console.log(item.object_type, path, item.object_type, item.path, item.action)

      switch (item.object_type) {
        case 'announcement':
          if (item.action === 'create' || item.action === 'update' || item.action === null) {
            if (path !== item.path) {
              window.location.href = item.path
            }
          }
          break
        case 'calendar_event':
          if (item.action === 'create' || item.action === 'update' || item.action === null) {
            const date = this.$moment(item.object.from_time).format('YYYY-MM')
            window.location.href = `/calendar?date=${date}`
          }
          break
        case 'resource_booking':
          if (item.action === 'create' || item.action === 'update' || item.action === null) {
            if (path !== item.path) {
              window.location.href = item.path
            }
          }
          break
        case 'email_workflow':
          if (item.action === 'create' || item.action === 'update' || item.action === 'pending' || item.action === 'complete' || item.action === null) {
            if (path !== item.path) {
              window.location.href = item.path
            }
          }
          break
        case 'account_workflow':
          if (item.action === 'create' || item.action === 'update' || item.action === 'pending' || item.action === 'complete' || item.action === null) {
            if (path !== item.path) {
              window.location.href = item.path
            }
          }
          break
        case 'library_item':
          if (item.action === 'create' || item.action === 'update' || item.action === null) {
            if (path !== item.path) {
              window.location.href = item.path
            }
          }
          break
        case 'library_folder':
          if (item.action === 'create' || item.action === 'update' || item.action === null) {
            window.location.href = item.object.parent_folder ? `/library/${item.object.parent_folder.item_code}` : '/library'
          }
          break
      }
    },
    isTooLong(text, length) {
      return text.length > length
    }
  }
}
</script>

<style scoped lang="scss">
#top-bar {
  .logo {
    width: 180px;
  }

  nav {
    ol {
      li {
        &:nth-child(2)::before {
          display: none;
        }
      }
    }
  }

  .site {
    position: relative;
    color: rgb(255 255 255 / 0.7);
  }

  .help-desk {
    a {
      color: #FFF;
    }
  }
}
</style>
