<template>
  <div id="center-form">
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">

          <div class="mt-3">
            <label for="cost_centre_code" class="form-label">單位編號</label>
            <input v-model="form.cost_centre_code" id="cost_centre_code" type="text" class="form-control w-full" placeholder="請輸入單位編號">
            <div v-if="'cost_centre_code' in errors" class="text-danger mt-2" v-html="errors.cost_centre_code"></div>
          </div>

          <div class="mt-3">
            <label for="abbrev" class="form-label"><span class="text-danger">*</span> 簡稱</label>
            <input v-model="form.abbrev" id="abbrev" type="text" class="form-control w-full" placeholder="請輸入單位編號">
            <div v-if="'abbrev' in errors" class="text-danger mt-2" v-html="errors.abbrev"></div>
          </div>

          <div class="mt-3">
            <label for="name_en" class="form-label">英文名稱</label>
            <input v-model="form.name_en" id="name_en" type="text" class="form-control w-full" placeholder="請輸入英文名稱">
            <div v-if="'name_en' in errors" class="text-danger mt-2" v-html="errors.name_en"></div>
          </div>

          <div class="mt-3">
            <label for="name_zh" class="form-label">中文名稱</label>
            <input v-model="form.name_zh" id="name_zh" type="text" class="form-control w-full" placeholder="請輸入中文名稱">
            <div v-if="'name_zh' in errors" class="text-danger mt-2" v-html="errors.name_zh"></div>
          </div>

<!--          <div class="mt-3">-->
<!--            <label for="service" class="form-label">服務</label>-->
<!--            <input v-model="form.service" id="service" type="text" class="form-control w-full" placeholder="請輸入服務">-->
<!--            <div v-if="'service' in errors" class="text-danger mt-2" v-html="errors.service"></div>-->
<!--          </div>-->

          <div class="mt-3">
            <label for="category_id" class="form-label">類別</label>
            <span v-show="categories == null"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
            <select v-if="categories != null" id="category_id" v-model="form.category_id" class="form-select" aria-label="請選擇類別">
              <option value="">--- 請選擇 ---</option>
              <option v-for="(item, key) in categories" :key="key" :value="item.category_id">{{ item.name }} {{ item.name_en }}</option>
            </select>
            <div v-if="'category_id' in errors" class="text-danger mt-2" v-html="errors.category_id"></div>
          </div>

          <div class="mt-3">
            <label for="service_site_id" class="form-label">服務</label>
            <span v-show="serviceSites == null"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
            <select v-if="serviceSites != null" id="service_site_id" v-model="form.service_site_id" class="form-select" aria-label="請選擇服務">
              <option value="">--- 請選擇 ---</option>
              <option v-for="(item, key) in serviceSites" :key="key" :value="item.site_id">{{
                  item.name
                }}
              </option>
            </select>
            <div v-if="'service_site_id' in errors" class="text-danger mt-2" v-html="errors.service_site_id"></div>
          </div>

          <div class="mt-3">
            <label for="building_site_id" class="form-label">社會服務大樓</label>
            <span v-show="buildingSites == null"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
            <select v-if="buildingSites != null" id="building_site_id" v-model="form.building_site_id" class="form-select" aria-label="請選擇社會服務大樓">
              <option value="">--- 請選擇 ---</option>
              <option v-for="(item, key) in buildingSites" :key="key" :value="item.site_id">{{
                  item.name
                }}
              </option>
            </select>
            <div v-if="'building_site_id' in errors" class="text-danger mt-2" v-html="errors.building_site_id"></div>
          </div>

          <div class="mt-3">
            <label for="district_site_id" class="form-label">區域</label>
            <span v-show="districtSites == null"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
            <select v-if="districtSites != null" id="district_site_id" v-model="form.district_site_id" class="form-select" aria-label="請選擇區域">
              <option value="">--- 請選擇 ---</option>
              <option v-for="(item, key) in districtSites" :key="key" :value="item.site_id">{{
                  item.name
                }}
              </option>
            </select>
            <div v-if="'district_site_id' in errors" class="text-danger mt-2" v-html="errors.district_site_id"></div>
          </div>

          <div class="mt-3">
            <label for="district_site_id" class="form-label">主管經理</label>
            <span v-show="lineOfSupervisions == null"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
            <select v-if="lineOfSupervisions != null" id="district_site_id" v-model="form.supervision_id" class="form-select" aria-label="請選擇主管經理">
              <option value="">--- 請選擇 ---</option>
              <option v-for="(item, key) in lineOfSupervisions" :key="key" :value="item.id">{{
                  item.name
                }}
              </option>
            </select>
            <div v-if="'supervision_id' in errors" class="text-danger mt-2" v-html="errors.supervision_id"></div>
          </div>

          <div class="mt-3">
            <label for="funding_mode" class="form-label">Funding Mode</label>
            <select id="funding_mode" v-model="form.funding_mode" class="form-select">
              <option value="">--- 請選擇 ---</option>
              <option value="LSG">LSG</option>
              <option value="Self-financing">Self-financing</option>
              <option value="Other Subvented">Other Subvented</option>
              <option value="Social Enterprise">Social Enterprise</option>
            </select>
            <div v-if="'funding_mode' in errors" class="text-danger mt-2" v-html="errors.funding_mode"></div>
          </div>

          <div class="mt-3">
            <label for="head_title" class="form-label">單位負責人職銜</label>
            <select id="head_title" v-model="form.head_title" class="form-select" aria-label="請選擇職銜">
              <option value="">--- 請選擇 ---</option>
              <option v-for="(item, key) in posts" :key="key" :value="item.value">{{
                  item.key + ' ' + item.value
                }}
              </option>
            </select>
            <div v-if="'head_title' in errors" class="text-danger mt-2" v-html="errors.head_title"></div>
          </div>

          <div class="mt-3">
            <label for="address_en" class="form-label">英文地址</label>
            <input v-model="form.address_en" id="address_en" type="text" class="form-control w-full" placeholder="請輸入英文地址">
            <div v-if="'address_en' in errors" class="text-danger mt-2" v-html="errors.address_en"></div>
          </div>

          <div class="mt-3">
            <label for="address_zh" class="form-label">中文地址</label>
            <input v-model="form.address_zh" id="address_zh" type="text" class="form-control w-full" placeholder="請輸入中文地址">
            <div v-if="'address_zh' in errors" class="text-danger mt-2" v-html="errors.address_zh"></div>
          </div>

          <div class="flex mt-3">
            <div class="flex-1 mr-1">
              <label for="phone" class="form-label">電話</label>
              <input v-model="form.phone" id="phone" type="text" class="form-control w-full" placeholder="請輸入電話"
               @keyup="formatPhone('phone', $event)">
              <div v-if="'phone' in errors" class="text-danger mt-2" v-html="errors.phone"></div>
            </div>

            <div class="flex-1">
              <label for="phone2" class="form-label">電話2</label>
              <input v-model="form.phone_2" id="phone2" type="text" class="form-control w-full" placeholder="請輸入電話2"
               @keyup="formatPhone('phone_2', $event)">
              <div v-if="'phone2' in errors" class="text-danger mt-2" v-html="errors.phone2"></div>
            </div>
          </div>

          <div class="flex mt-3">
            <div class="flex-1 mr-1">
              <label for="phone3" class="form-label">電話3</label>
              <input v-model="form.phone_3" id="phone3" type="text" class="form-control w-full" placeholder="請輸入電話3"
               @keyup="formatPhone('phone_3', $event)">
              <div v-if="'phone3' in errors" class="text-danger mt-2" v-html="errors.phone3"></div>
            </div>

            <div class="flex-1">
              <label for="fax" class="form-label">傳真</label>
              <input v-model="form.fax" id="fax" type="text" class="form-control w-full" placeholder="請輸入傳真"
               @keyup="formatPhone('fax', $event)">
              <div v-if="'fax' in errors" class="text-danger mt-2" v-html="errors.fax"></div>
            </div>
          </div>

          <div class="mt-3">
            <label for="email" class="form-label">電郵</label>
            <input v-model="form.email" id="email" type="text" class="form-control w-full" placeholder="請輸入電郵">
            <div v-if="'email' in errors" class="text-danger mt-2" v-html="errors.email"></div>
          </div>

          <div class="mt-3">
            <label for="cost_centre_user_id" class="form-label">Cost Centre Account</label>
            <span v-show="filteredUser == null"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
            <multi-select v-show="filteredUser != null" v-model="user" :options="filteredUser ?? []" :multiple="false" :close-on-select="true" :clear-on-select="true" :preserve-search="true" placeholder="以名搜尋" selectLabel="按下Enter以選擇" label="searchLabel" track-by="user_id" :preselect-first="false">
              <template slot="noResult"><span class="multiselect__option">沒有結果，請輸入其他字</span></template>
            </multi-select>
            <div v-if="'cost_centre_user_id' in errors" class="text-danger mt-2" v-html="errors.cost_centre_user_id"></div>
          </div>

          <div class="mt-3">
            <label for="remark" class="form-label">備註</label>
            <input v-model="form.remark" id="remark" type="text" class="form-control w-full" placeholder="請輸入備註">
            <div v-if="'remark' in errors" class="text-danger mt-2" v-html="errors.remark"></div>
          </div>

          <div class="flex justify-end align-items-center mt-5">
            <router-link v-if="!loading && !finish" to="/management/centers" type="button" class="btn btn-outline-secondary w-24 mr-1">取消</router-link>
            <button v-if="!loading && !finish" type="button" class="btn btn-primary w-24" @click="onSave">儲存</button>
            <span v-show="loading && !finish"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data'],
  data() {
    return {
      mode: 'create',

      categories: null,
      lineOfSupervisions: null,
      users: null,

      serviceSites: null,
      buildingSites: null,
      districtSites: null,

      form: {
        cost_centre_code: null,
        abbrev: null,
        name_en: null,
        name_zh: null,
        service: null,
        category_id: '',
        sub_category_id: '',
        service_site_id: '',
        building_site_id: '',
        district_site_id: '',
        supervision_id: '',
        is_mailing_list: '',
        funding_mode: '',
        head_title: '',
        line_of_supervision: null,
        address_en: null,
        address_zh: null,
        phone: null,
        phone_2: null,
        phone_3: null,
        fax: null,
        email: null,
        cost_centre_user_id: null,
        remark: ''
      },
      user: null,
      filteredUser: null,

      errors: {},
      loading: false,
      finish: false
    }
  },
  watch: {
    data(val) {
      if (val != null) {
        this.form = this.data
        Array.from(['category_id', 'sub_category_id', 'service_site_id', 'building_site_id', 'district_site_id', 'supervision_id', 'is_mailing_list', 'funding_mode', 'head_title']).forEach((value) => {
          this.form[value] = this.form[value] === null ? '' : this.form[value]
        })
        this.mode = 'update'
      }
    },
    users() {
      if (this.data && this.data.cost_centre_user_id != null) {
        for (let i = 0; i < this.users.length; i++) {
          if (this.users[i].user_id === this.data.cost_centre_user_id) {
            this.user = this.users[i]
          }
        }
      }
    },
    user(val) {
      this.form.cost_centre_user_id = val ? val.user_id : null
    }
  },
  mounted() {
    window.tailwind.svgLoader()

    this.loadCenterCategories()
    this.loadSystemSites()
    this.loadLineOfSupervisions()
    this.loadUsers()
  },
  methods: {
    async loadCenterCategories() {
      this.categories = await this.getCenterCategories()
    },
    async loadLineOfSupervisions() {
      this.lineOfSupervisions = await this.getLineOfSupervisions()
    },
    async loadSystemSites() {
      this.serviceSites = await this.getSites('PROJECT')
      this.buildingSites = await this.getSites('BUILDING')
      this.districtSites = await this.getSites('DISTRICT')
    },
    async loadUsers() {
      const users = await this.getAllUsers()

      for (let i = 0; i < users.length; i++) {
        users[i].searchLabel = this.getUserSearchLabel(users[i])
      }

      this.users = users
      this.filteredUser = this.users.filter(user => user.is_cost_centre)
    },
    formatPhone(field, event) {
      if (this.form[field] != null && this.form[field].length >= 4 && !this.form[field].includes(' ') && event.key !== 'Backspace') {
        const values = this.split(this.form[field], 4)
        this.$set(this.form, field, `${values[0]} ${values[1]}`)
      }
    },
    onSave: async function() {
      this.loading = true
      this.errors = {}

      let response

      if (this.mode === 'create') {
        response = await this.createCenter(this.form)
      } else {
        response = await this.updateCenter(this.data.centre_id, this.form)
      }

      if ('errors' in response.data) {
        this.errors = response.data.errors
        this.loading = false
      } else {
        let option = {
          title: (this.mode === 'create') ? '已成功新增部/組/服務單位' : '已成功更新部/組/服務單位',
          message: '請稍後，將會轉至部/組/服務單位頁面',
          type: 'success'
        }

        if (response.code === 0) {
          this.finish = true

          setTimeout(() => {
            this.$router.push('/management/centers')
          }, 1000)
        } else {
          this.loading = false
          option = {
            title: (this.mode === 'create') ? '未能新增部/組/服務單位' : '未能更新部/組/服務單位',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
