<template>
  <div id="audit-logs-page">
    <h2 class="intro-y text-lg font-medium mt-10">
      系統紀錄 (共{{ total }}條)
    </h2>

    <div v-if="checkPermission(permissions, 'site.user-group.view')">
      <div class="box grid grid-cols-12 gap-6 mt-5 p-5">

        <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center ">
          <div class="hidden md:block mx-auto text-slate-500">正在顯示第 {{ from }} 至 {{ to }} 條紀錄</div>
          <div v-if="!loading" class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
            <div class="w-56 relative text-slate-500">
              <input v-model="search" type="text" class="form-control sm:w-56 pr-10" placeholder="搜尋" @keyup.enter="loadAuditLogs">
              <font-awesome-icon @click="loadAuditLogs" icon="fa-solid fa-magnifying-glass" class="absolute my-auto w-4 h-4 mr-3 right-0 inset-y-0" />
            </div>
          </div>
          <div v-show="loading" class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0 flex">
            <span><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>
        </div>

        <div v-if="!loading" class="col-span-12 flex flex-wrap sm:flex-nowrap items-center justify-end">
          <div class="flex flex-wrap sm:flex-nowrap justify-end">
            <div class="mr-2 flex-basis-50 sm:flex-basis-0 sm:w-32">
              <label class="form-label text-xs">操作類別</label>
              <select id="event" v-model="event" class="form-select mr-2" aria-label="請選擇">
                <option value="">全部</option>
                <option v-for="(item, key) in allEvent" :key="key" :value="item.value">{{ item.name }}</option>
              </select>
            </div>

            <div class="mr-2 flex-basis-50 sm:flex-basis-0 sm:w-32">
              <label class="form-label text-xs">操作詳情</label>
              <select id="auditable_type" v-model="auditableType" class="form-select mr-2" aria-label="請選擇">
                <option value="">全部</option>
                <option v-for="(item, key) in allAuditableType" :key="key" :value="item.value">{{ item.name }}</option>
              </select>
            </div>

            <div class="mr-2 flex-basis-50 sm:flex-basis-0 sm:w-32">
              <label class="form-label text-xs">開始日期</label>
              <date-picker v-model="startAt" type="date" placeholder="請選擇日期" input-class="form-control w-full" style="width: auto"></date-picker>
            </div>

            <div class="mr-2 flex-basis-50 sm:flex-basis-0 sm:w-32">
              <label class="form-label text-xs">結束日期</label>
              <date-picker v-model="endAt" type="date" placeholder="請選擇日期" input-class="form-control w-full" style="width: auto"></date-picker>
            </div>

          </div>
        </div>

        <!-- BEGIN: Data List -->
        <div class="intro-y col-span-12 overflow-auto overflow-visible">
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="whitespace-nowrap"><order-button title="操作類別" column="event" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
                <th class="whitespace-nowrap"><order-button title="操作詳情" column="auditable_type" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
                <th class="whitespace-nowrap">影響內容</th>
                <th class="whitespace-nowrap"><order-button title="IP地址" column="centre" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
                <th class="whitespace-nowrap"><order-button title="操作用戶" column="user" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
                <th class="whitespace-nowrap"><order-button title="時間" column="created_at" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
                <th class="text-center whitespace-nowrap">檢視</th>
              </tr>
            </thead>
            <tbody>
              <tr class="" v-for="(item, key) in auditLogs" :key="key">
                <td>{{ getObjectValue(allEvent, 'value', item.event, 'name') }}</td>
                <td>{{ getObjectValueBySearchKeyEnding(allAuditableType, 'value', item.auditable_type, 'name') }}</td>
                <td>{{ item.new_values ? getAllKeys(item.new_values).join(', ') : '-' }}</td>
                <td>{{ item.ip_address ?? '-' }}</td>
                <td>{{ item.user ? item.user.display_name : '-' }}</td>
                <td>{{ $moment(item.created_at).format('YYYY-MM-DD HH:mm') }}</td>
                <td class="table-report__action text-center">
                  <button class="btn btn-success" @click="openViewModal(item)">
                    <font-awesome-icon icon="fa-regular fa-eye" class="text-white"/>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- END: Data List -->
        <!-- BEGIN: Pagination -->
        <pagination-row :pages="pages" :current-page.sync="currentPage" :item-per-page.sync="itemPerPage"></pagination-row>
        <!-- END: Pagination -->
      </div>
    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>

    <!-- BEGIN: Event Modal -->
    <div id="audit-log-view-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">
              系統紀錄
            </h2>
          </div>
          <div class="modal-body p-0">
            <div class="p-5">
              <div class="text-slate-500">
                <div>
                  <label for="staff-number" class="form-label">操作類別</label>
                  <div class="w-full">{{ getObjectValue(allEvent, 'value', selectedItem.event, 'name') }}</div>
                </div>

                <div class="mt-4">
                  <label for="staff-number" class="form-label">操作詳情</label>
                  <div class="w-full">{{ getObjectValueBySearchKeyEnding(allAuditableType, 'value', selectedItem.auditable_type, 'name') }}</div>
                </div>

                <div class="mt-4">
                  <label for="staff-number" class="form-label">操作編號</label>
                  <div class="w-full">
                    {{ selectedItem.auditable_id }}
                  </div>
                </div>

                <div v-if="selectedItem.event != 'created' && selectedItem.event != 'deleted'" class="mt-4">
                  <label for="staff-number" class="form-label">原有內容</label>
                  <div class="w-full">
                    <ul>
                      <li class="" v-for="(value, key) in selectedItem.old_values" :key="key">
                        {{ key }}: {{ value }}
                      </li>
                    </ul>
                  </div>
                </div>

                <div v-if="selectedItem.event != 'deleted'" class="mt-4">
                  <label for="staff-number" class="form-label">儲存內容</label>
                  <div class="w-full">
                    <ul>
                      <li class="" v-for="(value, key) in selectedItem.new_values" :key="key">
                        {{ key }}: {{ value }}
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="mt-4">
                  <label for="staff-number" class="form-label">IP地址</label>
                  <div class="w-full">{{ selectedItem.ip_address ?? '-' }}</div>
                </div>

                <div class="mt-4">
                  <label for="staff-number" class="form-label">客戶端</label>
                  <div class="w-full">{{ selectedItem.user_agent ?? '-' }}</div>
                </div>

                <div class="mt-4">
                  <label for="staff-number" class="form-label">操作用戶</label>
                  <div class="w-full">{{ selectedItem.user ? selectedItem.user.display_name : '-' }}</div>
                </div>

                <div class="mt-4">
                  <label for="staff-number" class="form-label">時間</label>
                  <div class="w-full">{{ $moment(selectedItem.created_at).format('YYYY-MM-DD HH:mm:ss') }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Event Modal -->

  </div>
</template>

<script>
export default {
  props: ['auth', 'permissions', 'currentSite'],
  data() {
    return {
      mounted: false,
      loading: 0,

      auditLogs: [],

      event: '',
      auditableType: '',
      startAt: null,
      endAt: null,
      selectedItem: '',

      itemPerPage: 50,
      currentPage: 1,
      total: 0,
      pages: 0,
      from: 0,
      to: 0,
      search: '',
      orderBy: 'created_at',
      order: 'DESC',

      allEvent: [
        { name: '新增', value: 'created' },
        { name: '更新', value: 'updated' },
        { name: '刪除', value: 'deleted' }
      ],
      allAuditableType: [
        { name: '報告板', value: 'Announcement' },
        // { name: '報告板歴史', value: 'AnnouncementHistory' },
        { name: '報告板類別', value: 'AnnouncementType' },
        { name: '行事曆', value: 'Calendar' },
        { name: '行事曆活動', value: 'CalendarEvent' },
        { name: '通訊錄', value: 'Contact' },
        { name: '通訊錄電郵地址', value: 'ContactEmail' },
        { name: '通訊錄電話', value: 'ContactNumber' },
        { name: '通訊錄PDF服務', value: 'ContactOther' },
        { name: '通訊錄PDF服務用戶', value: 'ContactOtherEnrollment' },
        { name: '檔案櫃項目', value: 'LibraryItem' },
        { name: '檔案櫃項目分享用戶', value: 'LibraryItemShare' },
        { name: '檔案櫃項目分享組別', value: 'LibraryItemShareGroup' },
        { name: '檔案櫃項目標籤', value: 'LibraryItemTag' },
        { name: '檔案櫃項目連結', value: 'LibraryItemTempAccessCode' },
        { name: '檔案櫃下載', value: 'LibraryDownload' },
        { name: '檔案櫃檔案', value: 'File' },
        { name: '檔案櫃檔案格式', value: 'FileFormat' },
        { name: '檔案櫃檔案版本', value: 'FileVersion' },
        { name: '檔案櫃標籤', value: 'LibraryTag' },
        { name: '內聯網申請表', value: 'AccountWorkflow' },
        // { name: '', value: 'EmailWorkflow' },
        { name: '內聯網申請表審批', value: 'WorkflowApproval' },
        { name: '內聯網申請表審批流程', value: 'WorkflowHistory' },
        { name: '問巻/電子表格', value: 'Form' },
        { name: '問巻/電子表格特定回覆用戶', value: 'FormPermission' },
        { name: '問巻/電子表格特定回覆組別', value: 'FormPermissionGroup' },
        { name: '問巻/電子表格分段', value: 'FormSection' },
        { name: '問巻/電子表格題目', value: 'FormItem' },
        { name: '問巻/電子表格題目選擇', value: 'FormItemOption' },
        { name: '問巻/電子表格回覆', value: 'FormResponse' },
        { name: '問巻/電子表格回覆題目', value: 'FormItemResponse' },
        { name: '會議室及資源', value: 'Resource' },
        { name: '會議室及資源可用分站', value: 'ResourceAllowance' },
        { name: '會議室及資源預借', value: 'ResourceBooking' },
        { name: 'FAQ', value: 'KnowledgeBase' },
        { name: 'FAQ類別', value: 'KnowledgeType' },
        { name: '討論區主題', value: 'ForumThread' },
        { name: '討論區回覆', value: 'ForumPost' },
        { name: '討論區類別', value: 'ForumCategory' },
        { name: '選單捷徑', value: 'MenuShortcut' },
        { name: '執行報告', value: 'PerformanceReportData' },
        { name: '執行報告部分', value: 'PerformanceReportSection' },
        { name: '執行報告表格', value: 'PerformanceReportTable' },
        { name: '執行報告表格格', value: 'PerformanceReportTableDataField' },
        { name: '執行報告表格行', value: 'PerformanceReportTableRow' },
        { name: '執行報告表格行所屬部/組/服務單位', value: 'PerformanceReportTableRowCentre' },
        { name: '執行報告回答版本', value: 'PerformanceReportVersion' },
        { name: '社服隨筆 及 新中心/新計劃服務巡禮', value: 'SocialEssay' },
        { name: '社服隨筆 及 新中心/新計劃服務巡禮留言', value: 'SocialEssayComment' },
        // { name: '社服隨筆 及 新中心/新計劃服務巡禮歴史', value: 'SocialEssayHistory' },
        { name: '社服隨筆 及 新中心/新計劃服務巡禮讚好', value: 'SocialEssayLike' },
        { name: '用戶', value: 'User' },
        { name: '組別', value: 'UserGroup' },
        { name: '組別用戶', value: 'UserGroupEnrollment' },
        { name: '分站', value: 'Site' },
        { name: '分站職能', value: 'Role' },
        { name: '分站職能權限', value: 'RolePermission' },
        { name: '分站權限', value: 'Permission' },
        { name: '分站用戶', value: 'SiteEnrollment' },
        { name: '分站組別', value: 'SiteUserGroupEnrollment' },
        { name: '系統參數設定', value: 'SystemConfig' },
        { name: '橫額', value: 'Banner' },
        { name: '部/組/服務單位', value: 'Centre' },
        { name: '部/組/服務單位類別', value: 'CentreCategory' },
        { name: '職位', value: 'Position' },
        { name: '主管經理', value: 'LineOfSupervision' }
        // { name: '', value: 'CalendarShare' },
        // { name: '', value: 'CommonLink' },
        // { name: '', value: 'Department' },
        // { name: '', value: 'FormDownload' },
        // { name: '', value: 'Information' },
        // { name: '', value: 'InformationType' },
        // { name: '', value: 'Manual' },
        // { name: '', value: 'ManualType' },
      ]
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    async currentSite(val) {
      this.init()
    },
    event(val) {
      this.loadAuditLogs()
    },
    auditableType(val) {
      this.loadAuditLogs()
    },
    startAt(val) {
      this.loadAuditLogs()
    },
    endAt(val) {
      this.loadAuditLogs()
    },
    currentPage(val) {
      this.loadAuditLogs(false)
    },
    itemPerPage(val) {
      this.loadAuditLogs()
    },
    search(val) {
      if (val === '') {
        this.loadAuditLogs()
      }
    }
  },
  async mounted() {
    window.tailwind.svgLoader()

    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.loadAuditLogs()

        setTimeout(() => {
          this.mounted = true
        }, 500)
      }
    },
    async loadAuditLogs(resetCurrentPage = true) {
      this.loading++
      if (resetCurrentPage) {
        this.currentPage = 1
      }

      const startAt = (this.startAt != null ? this.$moment(this.startAt).format('YYYY-MM-DD') : '')
      const endAt = (this.endAt != null ? this.$moment(this.endAt).format('YYYY-MM-DD') : '')
      const response = await this.getAuditLog(this.search, this.event, this.auditableType, startAt, endAt, this.itemPerPage, this.currentPage, this.orderBy, this.order)
      this.auditLogs = response.data
      this.total = response.meta.page.total
      this.pages = response.meta.page.last_page
      this.from = response.meta.page.from ?? 0
      this.to = response.meta.page.to ?? 0

      this.loading--
    },
    openViewModal(item) {
      this.selectedItem = item

      const el = document.querySelector('#audit-log-view-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    }
  }
}
</script>

<style lang="scss" scoped>
#user-group-page {
  .table-report__action {
    width: 18rem;
  }
}
</style>
