<template>
  <div id="forum-page">
    <div class="intro-y flex flex-col sm:flex-row mt-8">
      <h2 class="text-lg font-medium mr-auto">
        討論區
      </h2>
      <div v-if="checkPermission(permissions, 'site.forum.view')" class="flex justify-end mt-4 sm:mt-0">
        <select v-if="!loading && checkPermission(permissions, 'site.announcement.edit')" id="status" v-model="currentStatus" class="form-select mr-2 w-auto" aria-label="請選擇狀態">
          <option v-for="(item, key) in allStatus" :key="key" :value="item.value">{{ item.name }}</option>
        </select>

        <div v-if="!loading" class="relative text-slate-500 mr-2">
          <input @keyup.enter="loadThreads" v-model="search" type="text" class="form-control sm:w-56 pr-10" placeholder="搜尋">
          <font-awesome-icon @click="loadThreads" icon="fa-solid fa-magnifying-glass" class="absolute my-auto w-4 h-4 mr-3 right-0 inset-y-0" />
        </div>

        <div v-show="loading" class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0 mr-3 flex">
          <span><i data-loading-icon="oval" class="w-8 h-8"></i></span>
        </div>

        <div class="text-slate-500">
          <router-link to="/forum/create-thread" v-if="checkPermission(permissions, 'site.forum.create')"
                         class="btn btn-primary shadow-md">新增主題
          </router-link>
        </div>
      </div>
    </div>

    <div v-if="checkPermission(permissions, 'site.forum.view')">
      <div class="intro-y chat grid grid-cols-12 gap-5 mt-5">
        <!-- BEGIN: Chat Side Menu -->
        <div class="col-span-12">
          <div class="intro-y pr-1">
            <div class="box p-2">
              <ul class="nav nav-pills forum-nav" role="tablist">
                <li class="nav-item flex-1" v-for="(item, key) in forumCategories" :key="key">
                  <button class="nav-link w-full py-2" :class="{'active': item.category_id === currentCategory}"
                          type="button" @click="currentCategory = item.category_id"> {{ item.name }}
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <div class="tab-content">
            <div id="chats" class="tab-pane active" role="tabpanel" aria-labelledby="chats-tab">

              <div class="hidden md:block mx-auto text-slate-500 text-center mt-5">正在顯示第
                {{ threads.length !== 0 ? from : 0 }} 至 {{ to ?? 0 }} 個主題
              </div>

              <div class="chat__chat-list pr-1 pt-1 mt-4">
                <div class="intro-x box relative flex items-center p-5" v-for="(item, key) in threads" :key="key"
                     :class="{'mt-2': key !== 0}">
                  <div class="ml-2 overflow-hidden w-full">
                    <div class="flex justify-between">
                      <router-link :to="`/forum/threads/${item.thread_id}`" class="font-medium">{{
                          item.title
                        }}
                      </router-link>

                      <div class="flex">
                        <div
                          v-if="checkPermission(permissions, 'site.forum.edit') || checkPermission(permissions, 'site.forum.delete') "
                          class="table-report__action">
                          <div class="flex justify-center items-center">
                            <router-link v-if="checkPermission(permissions, 'site.forum.edit')"
                                         :to="`/forum/update-thread/${item.thread_id}`" class="flex items-center mr-3">
                              <font-awesome-icon icon="fa-solid fa-pen-to-square"/>
                              &nbsp;更改
                            </router-link>
                            <button v-if="checkPermission(permissions, 'site.forum.delete')"
                                    class="flex items-center text-danger mr-3" @click="openDeleteModal(item.thread_id)">
                              <font-awesome-icon icon="fa-regular fa-trash-can"/>
                              &nbsp;刪除
                            </button>
                          </div>
                        </div>

                        <div>
                          <font-awesome-icon icon="fa-regular fa-message" class="text-success mr-1"/> {{ item.post_count }}
                        </div>
                      </div>
                    </div>

                    <div class="flex justify-between align-items-center">
                      <div class="truncate text-slate-500 mt-2 flex align-items-center">
                        <div class="w-8 h-8 flex-none image-fit mr-2">
                          <img alt="" class="rounded-full" :src="item.author?.avatar_url != null && item.author.avatar_url !== '' ? item.author.avatar_url : '/images/user.png'">
                        </div>

                        {{ item.author?.display_name }}
                      </div>

                      <div class="text-slate-400">{{ $moment(item.updated_at).format('YYYY-MM-DD HH:mm') }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Chat Side Menu -->

        <!-- BEGIN: Pagination -->
        <pagination-row :pages="pages" :current-page.sync="currentPage"
                        :item-per-page.sync="itemPerPage"></pagination-row>
        <!-- END: Pagination -->

        <!-- BEGIN: Delete Confirmation Modal -->
        <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body p-0">
                <div class="p-5 text-center">
                  <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger"/>
                  <div class="text-3xl mt-5">你確定嗎？</div>
                  <div class="text-slate-500 mt-2">
                    你是否要刪除此主題？
                    <br>
                    這動作不能還原。
                  </div>
                </div>
                <div class="px-5 pb-8 text-center">
                  <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
                  <button type="button" class="btn btn-danger w-24" @click="onDelete">確定</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Delete Confirmation Modal -->
      </div>
    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'currentSite', 'permissions'],
  data() {
    return {
      mounted: false,
      loading: 0,

      forumCategories: [],
      currentCategory: null,
      currentStatus: 'PUBLISHED',

      threads: [],

      itemPerPage: 50,
      currentPage: 1,
      pages: 0,
      from: 0,
      to: 0,
      search: '',

      allStatus: [
        { name: '全部', value: 'ALL' },
        { name: '公開', value: 'PUBLISHED' },
        { name: '草稿', value: 'DRAFT' }
      ]
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentSite() {
      this.init()
    },
    currentCategory(val) {
      this.loadThreads()
    },
    currentPage(val) {
      this.loadThreads(false)
    },
    currentStatus(val) {
      this.loadThreads()
    },
    itemPerPage(val) {
      this.loadThreads()
    },
    search(val) {
      if (val === '') {
        this.loadThreads()
      }
    }
  },
  async mounted() {
    window.tailwind.svgLoader()

    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.loadForumCategories()
        this.mounted = true
      }
    },
    async loadForumCategories() {
      this.loading++

      this.forumCategories = await this.getSiteForumCategories(this.currentSite.site_id)
      if (this.forumCategories.length > 0) {
        this.currentCategory = this.forumCategories[0].category_id
        await this.loadThreads()
      }

      this.loading--
    },
    async loadThreads(resetCurrentPage = true) {
      this.loading++

      const response = await this.getSiteForumThreads(this.currentSite.site_id, this.search, this.currentCategory, this.itemPerPage, this.currentPage, this.currentStatus)

      if (response.code === 0) {
        this.threads = response.data
        this.pages = response.meta.page.last_page
        this.from = response.meta.page.from ?? 0
        this.to = response.meta.page.to ?? 0
      }

      if (resetCurrentPage) {
        this.currentPage = 1
      }

      this.loading--
    },
    openDeleteModal(id) {
      this.deleteId = id

      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDelete() {
      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      const response = await this.deleteSiteForumThread(this.currentSite.site_id, this.deleteId)

      let option = {
        title: '成功',
        message: '主題已被刪除',
        type: 'success'
      }

      if (response.code === 0) {
        this.loadThreads()
      } else {
        option = {
          title: '未能刪除主題',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    }
  }
}
</script>

<style lang="scss" scoped>
#forum-page {
  .thread-title {
    font-size: 20px;
  }

  .chat .chat__chat-list {
   height: unset;
  }
}
@media screen and (max-width: 992px) {
  .forum-nav {
    display: flex;
    flex-wrap: wrap;

    .nav-item {
      flex: 1 0 33%;
    }
  }
}
@media screen and (max-width: 767px) {
  .forum-nav {
    .nav-item {
      flex: 1 0 50%;
    }
  }
}
</style>
