<template>
  <div id="library-page" class="page">
    <div class="mt-10">
      <div class="flex align-items-center overflow-x-auto directory-list">
        <div class="intro-y text-lg font-medium" v-if="item != null && childFolders.length == 0">{{ item.item_name }}</div>
        <button type="button" v-else-if="item != null" @click="goRoot" class="intro-y text-lg font-medium">{{ item.item_name }}</button>

        <span v-for="(child, key) in childFolders" :key="key" class="flex align-items-center">
          <font-awesome-icon icon="fa-solid fa-chevron-right" class="ml-4 mr-4"/>

          <span v-if="key === childFolders.length - 1" class="intro-y text-lg font-medium">
            {{ child.item_name }}
          </span>

          <button v-else @click="goFolder(child, key)"
                       class="intro-y text-lg font-medium">
            {{ child.item_name }}
          </button>
        </span>
      </div>
    </div>

    <div>
      <div class="box grid grid-cols-12 gap-6 mt-5 p-5">

        <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center">
          <div class="flex flex-wrap sm:flex-nowrap items-center justify-end">
            <button v-if="!downloading && selectedItemIds.length < items.length" class="btn btn-secondary shadow-md ml-2" @click="selectAllItems">
              全選
            </button>

            <button v-if="!downloading && selectedItemIds.length > 0" class="btn btn-secondary shadow-md ml-2" @click="clearSelectedItems">
              清除選擇
            </button>

            <div v-if="!downloading && selectedItemIds.length > 0" class="flex justify-center items-center action ml-2">
              <button type="button" class="btn btn-primary shadow-md dropdown-toggle" @click="action = !action" @focusout="action = false">
                動作
              </button>

              <div class="relative">
                <div class="dropdown-menu w-40" :class="{'show': action}">
                  <ul class="dropdown-content">
                    <li v-if="checkAllSelectedItemsType('FILE')">
                      <button type="button" class="dropdown-item" @click.stop="onMultipleDownload()">
                        <font-awesome-icon icon="fa-solid fa-cloud-arrow-down" class="fa-1x w-5 mr-2"/>
                        下載
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <span v-show="downloading"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>

          <div class="hidden md:block mx-auto text-slate-500">正在顯示第 {{ items.length }} 個檔案</div>

          <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0 flex">
          </div>
        </div>

        <!-- BEGIN: Data List -->
        <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="whitespace-nowrap">選取</th>
              <th class="whitespace-nowrap">類型</th>
              <th class="whitespace-nowrap">名稱</th>
              <th class="text-center whitespace-nowrap"></th>
              <th class="whitespace-nowrap">擁有者</th>
              <th class="whitespace-nowrap">上次修改日期</th>
              <th class="whitespace-nowrap">版本</th>
              <th class="whitespace-nowrap">檔案大小</th>
            </tr>
            </thead>

            <tbody>
            <tr class="" v-for="(item, key) in items" :key="key" @click.stop="onOpen(item)">
              <td><input v-if="item.item_type === 'FILE'" class="form-check-input border border-slate-500" type="checkbox" @click.stop="onSelect(item)" :value="item.item_id" v-model="selectedItemIds"></td>
              <td class="">
                <div class="item-icon bg-contain"
                     :class="{'folder': item.item_type === 'DIRECTORY', 'file': item.item_type === 'FILE'}"><span
                  v-if="item.item_type === 'FILE'">{{ getExtension(item, 'item_name') }}</span></div>
              </td>
              <td class="">
                {{ item.item_name ?? '-' }}
              </td>
              <td
                class="table-report__action w-56">
                <div class="flex justify-center items-center action">
                  <button type="button" class="dropdown-toggle w-5 h-5 block" @click.stop="onClick(item)"
                          @focusout="onUnselect" :tabindex="key">
                    <font-awesome-icon icon="fa-solid fa-ellipsis-vertical"/>
                  </button>

                  <div class="relative">
                    <div class="dropdown-menu w-40" :class="{'show': item.item_id === currentItemId}">
                      <ul class="dropdown-content">
                        <li v-if="item.item_type === 'FILE' && isViewable(item)">
                          <button type="button" class="dropdown-item" @click.stop="onOpen(item)">
                            <font-awesome-icon icon="fa-solid fa-eye" class="fa-1x w-5 mr-2"/>
                            檢視
                          </button>
                        </li>
                        <li v-if="item.item_type === 'FILE'">
                          <button type="button" class="dropdown-item" @click.stop="onDownload(item)">
                            <font-awesome-icon icon="fa-solid fa-cloud-arrow-down" class="fa-1x w-5 mr-2"/>
                            下載
                          </button>
                        </li>
                        <li v-if="item.item_type === 'DIRECTORY'">
                          <button type="button" class="dropdown-item" @click.stop="onOpen(item)">
                            <font-awesome-icon icon="fa-solid fa-folder-open" class="fa-1x w-5 mr-2"/>
                            開啟
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </td>
              <td>{{ item.owner != null ? item.owner.full_name : '-' }}</td>
              <td>{{ item.updated_at ?? '-' }}</td>
              <td>{{ item.file_versions_count != null ? (item.file_versions_count + 1) : '-' }}</td>
              <td>{{ item.item_type === 'FILE' ? getFileSize(item.file.file_size) : '' }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- END: Data List -->

        <!-- BEGIN: View Modal -->
        <div id="view-modal" class="modal" tabindex="-1" aria-hidden="true">
          <div class="flex justify-between align-items-center view-toolbar">
            <button type="button" @click="onDownload(viewItem)" class="btn-download text-white">
              <font-awesome-icon icon="fa-solid fa-cloud-arrow-down" class="fa-2x"/>
            </button>

            <div class="text-white">{{ viewItem != null ? viewItem.file.file_name : ''}}</div>

            <button type="button" data-tw-dismiss="modal" class="btn-close text-white">
              <font-awesome-icon icon="fa-solid fa-xmark" class="fa-2x"/>
            </button>
          </div>

          <div class="modal-dialog">
            <img v-if="viewItem != null && viewItem.file && viewItem.file.format && viewItem.file.format.mime_type.startsWith('image/')" :src="viewItem.view_url">
          </div>

          <div v-if="viewItem != null && viewItem.file && viewItem.file.format && !viewItem.file.format.mime_type.startsWith('image/')">
            <embed v-if="viewItem.file.format.mime_type === 'application/pdf'" :src="viewItem.view_url" class="iframe-viewer"/>
            <iframe
              v-else-if="isViewable(viewItem)"
              :src="`https://view.officeapps.live.com/op/embed.aspx?src=${viewItem.view_url}`" frameborder="0"
              class="iframe-viewer"></iframe>
          </div>

        </div>
        <!-- END: View Modal -->
      </div>
    </div>
  </div>
</template>

<script>
import { partial } from 'filesize'

export default {
  props: ['auth', 'currentSite'],
  data() {
    return {
      mounted: false,

      host: '',

      shareCode: '',
      childFolders: [],
      item: null,
      items: [],
      currentItemId: null,

      downloading: false,

      search: '',
      orderBy: '',
      order: '',

      viewItem: null,

      selectedItemIds: [],
      action: false
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentSite(val) {
      this.init()
    },
    '$route'(val) {
      this.init()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      window.tailwind.svgLoader()

      this.host = window.location.protocol + '//' + window.location.host
      this.item = null
      this.shareCode = this.$route.params.shareCode ?? null

      if (this.currentSite) {
        await this.getItem()
      }

      this.mounted = true
    },
    async onChangeFolder(shareCode = '') {
      this.item = null
      this.shareCode = shareCode

      if (this.currentSite) {
        await this.getItem()
      }
    },
    async getItem() {
      const response = await this.getItemByShareCode(this.$route.params.shareCode)

      if (response.code === 0) {
        this.item = response.data
        this.items = response.data.children
      } else {
        const option = {
          title: '未能開啟資料夾',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }

        this.$notify(option)

        setTimeout(() => {
          this.$router.push('/library')
        }, 500)
      }
    },
    getFileSize(fileSize) {
      const size = partial({ base: 10, standard: 'jedec' })
      return size(fileSize)
    },
    goRoot() {
      this.items = this.item.children
      this.childFolders = []
    },
    goFolder(item, key) {
      this.items = item.children

      const childFoldersLength = this.childFolders.length - 1

      for (let i = 0; i < childFoldersLength - key; i++) {
        this.childFolders.pop()
      }
    },
    onOpen(item) {
      this.selectedItemIds = []
      this.action = false

      if (item.item_type === 'DIRECTORY') {
        this.items = item.children
        this.childFolders.push(item)
      } else {
        this.openViewModal(item)
      }
    },
    onClick(item) {
      this.currentItemId = item.item_id
    },
    onUnselect() {
      this.currentItemId = null
    },
    async openViewModal(item) {
      const response = await this.getLibraryItem(this.currentSite.site_id, item.item_code)

      if (response.code === 0) {
        this.viewItem = response.data

        if (this.isViewable(item)) {
          const el = document.querySelector('#view-modal')
          const modal = window.tailwind.Modal.getOrCreateInstance(el)
          modal.show()
        } else {
          this.onDownload(item)
        }
      }
    },
    async onDownload(item, filename = null, versionId = '') {
      this.downloading = true
      const response = await this.downloadLibraryItem(this.currentSite.site_id, item.item_code, versionId)
      const blob = new Blob([response], { type: item.file.format != null ? item.file.format.mime_type : null })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = filename ?? item.file.file_name
      link.click()
      URL.revokeObjectURL(link.href)
      this.downloading = false
    },
    onSelect(item) {
    },
    selectAllItems() {
      this.clearSelectedItems()
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].item_type === 'FILE') {
          this.selectedItemIds.push(this.items[i].item_id)
        }
      }
    },
    clearSelectedItems() {
      this.selectedItemIds = []
    },
    getSelectedItem(itemId) {
      const index = this.items.findIndex(e => e.item_id === itemId)
      if (index > -1) {
        return this.items[index]
      }
      return null
    },
    checkAllSelectedItemsType(type) {
      for (let i = 0; i < this.selectedItemIds.length; i++) {
        const item = this.getSelectedItem(this.selectedItemIds[i])
        if (item && item.item_type !== type) {
          return false
        }
      }

      return true
    },
    async onMultipleDownload() {
      for (let i = 0; i < this.selectedItemIds.length; i++) {
        const item = this.getSelectedItem(this.selectedItemIds[i])
        if (item) {
          await this.onDownload(item)
        }
      }

      this.selectedItemIds = []
    }
  }
}
</script>

<style lang="scss" scoped>
#library-page {

  &.page {
    margin-bottom: 200px;
  }

  .directory-list {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

    .root {
      flex: 0 0 56px;
    }
  }

  .directory-list::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }

  table {
    tr {
      &:hover {
        td {
          background-color: rgb(var(--color-primary) / 0.3);
        }
      }
    }
  }

  .item-icon {
    width: 40px;
    height: 40px;
  }

  .bg-contain {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .folder {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='786' height='786' viewBox='0 0 786 786'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%238a97ac'/%3E%3Cstop offset='1' stop-color='%235d6c83'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg id='Group_3' data-name='Group 3' transform='translate(-567 -93)'%3E%3Crect id='Rectangle_4' data-name='Rectangle 4' width='418' height='681' rx='40' transform='translate(896 109)' fill='%2395a5b9'/%3E%3Crect id='Rectangle_3' data-name='Rectangle 3' width='433' height='681' rx='40' transform='translate(606 93)' fill='%23a0aec0'/%3E%3Crect id='Rectangle_2' data-name='Rectangle 2' width='742' height='734' rx='40' transform='translate(590 145)' fill='%23bec8d9'/%3E%3Crect id='Rectangle_5' data-name='Rectangle 5' width='786' height='692' rx='40' transform='translate(567 187)' fill='url(%23linear-gradient)'/%3E%3C/g%3E%3C/svg%3E%0A");
  }

  .file {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='628.027' height='786.012' viewBox='0 0 628.027 786.012'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%238a97ac'/%3E%3Cstop offset='1' stop-color='%235d6c83'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg id='Group_5' data-name='Group 5' transform='translate(-646 -92.988)'%3E%3Cpath id='Union_2' data-name='Union 2' d='M40,786A40,40,0,0,1,0,746V40A40,40,0,0,1,40,0H501V103h29v24h98V746a40,40,0,0,1-40,40Z' transform='translate(646 93)' fill='url(%23linear-gradient)'/%3E%3Cpath id='Intersection_2' data-name='Intersection 2' d='M.409,162.042l.058-109.9c31.605,29.739,125.37,125.377,125.37,125.377l-109.976.049A20.025,20.025,0,0,1,.409,162.042Z' transform='translate(1147 42)' fill='%23bec8d9' stroke='%23bec8d9' stroke-width='1'/%3E%3C/g%3E%3C/svg%3E%0A");

    span {
      color: #fff;
      font-size: 11px;
    }
  }

  .action {
    .show {
      visibility: visible;
      opacity: 1;
    }

    .dropdown-menu {
      margin-top: 10px;
      left: -100px;

      button {
        width: 100%;
      }
    }
  }
}
</style>
