<template>
  <div id="thread-form">
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">

          <div class="mt-3">
            <label for="category_id" class="form-label"><span class="text-danger">*</span> 類別</label>
            <span v-show="forumCategories == null"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
            <select v-if="forumCategories != null" id="category_id" v-model="form.category_id" class="form-select" aria-label="請選擇類別">
              <option value="">--- 請選擇 ---</option>
              <option v-for="(item, key) in forumCategories" :key="key" :value="item.category_id">{{ item.name }}</option>
            </select>
            <div v-if="'category_id' in errors" class="text-danger mt-2" v-html="errors.category_id"></div>
          </div>

          <div class="mt-3">
            <label for="title" class="form-label"><span class="text-danger">*</span> 主題</label>
            <input v-model="form.title" id="title" type="text" class="form-control w-full" placeholder="請輸入主題">
            <div v-if="'title' in errors" class="text-danger mt-2" v-html="errors.title"></div>
          </div>

          <div class="mt-3">
            <label for="content" class="form-label"><span class="text-danger">*</span> 內容</label>
            <el-tiptap v-model="form.content" :extensions="extensions" />
            <div v-if="'content' in errors" class="text-danger mt-2" v-html="errors.content"></div>
          </div>

          <div class="mt-3">
            <label for="display_order" class="form-label">顯示次序</label>
            <input v-model="form.display_order" id="display_order" type="number" class="form-control w-full" placeholder="請輸入顯示次序">
            <div v-if="'display_order' in errors" class="text-danger mt-2" v-html="errors.display_order"></div>
          </div>

          <div class="mt-3" v-if="false">
            <div class="sm:grid grid-cols-2 gap-2">
              <div>
                <label class="form-label">置頂</label>
                <div class="form-switch mt-2">
                  <input id="is_pinned" v-model="form.is_pinned" type="checkbox" class="form-check-input">
                </div>

                <div v-if="'is_pinned' in errors" class="text-danger mt-2" v-html="errors.is_pinned"></div>
              </div>

              <div>
                <label class="form-label">可回覆</label>
                <div class="form-switch mt-2">
                  <input id="replyable" v-model="form.replyable" type="checkbox" class="form-check-input">
                </div>

                <div v-if="'replyable' in errors" class="text-danger mt-2" v-html="errors.replyable"></div>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <label for="status" class="form-label">狀態</label>
            <select id="status" v-model="form.status" class="form-select" aria-label="請選擇狀態">
              <option value="">--- 請選擇 ---</option>
              <option v-for="(item, key) in allStatus" :key="key" :value="item.value">{{ item.name }}</option>
            </select>
            <div v-if="'status' in errors" class="text-danger mt-2" v-html="errors.status"></div>
          </div>

          <div class="flex justify-end align-items-center mt-5">
            <router-link v-if="!loading && !finish" to="/forum" type="button" class="btn btn-outline-secondary w-24 mr-1">取消</router-link>
            <button v-if="!loading && !finish" type="button" class="btn btn-primary w-24" @click="onSave">儲存</button>
            <span v-show="loading && !finish"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  // all extensions
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Link,
  Image,
  Blockquote,
  ListItem,
  BulletList, // use with ListItem
  OrderedList, // use with ListItem
  TodoItem,
  TodoList, // use with TodoItem
  TextAlign,
  Indent,
  HorizontalRule,
  HardBreak,
  History,
  // LineHeight,
  // Iframe,
  // CodeBlock,
  // TrailingNode,
  Table, // use with TableHeader, TableCell, TableRow
  TableHeader,
  TableCell,
  TableRow,
  // FormatClear,
  TextColor,
  TextHighlight,
  FontType,
  FontSize,
  // Preview,
  // Print,
  Fullscreen,
  CodeView
  // SelectAll,
} from 'element-tiptap'

import InsertYoutube from '../extension/insertYoutube/index'
import InsertFile from '../extension/insertFile/index'

import codemirror from 'codemirror'
import 'codemirror/lib/codemirror.css' // import base style
import 'codemirror/mode/xml/xml.js' // language
import 'codemirror/addon/selection/active-line.js' // require active-line.js
import 'codemirror/addon/edit/closetag.js' // autoCloseTags

export default {
  props: ['site', 'data', 'auth', 'currentSite', 'permissions', 'profile'],
  data() {
    return {
      mode: 'create',
      forumCategories: null,
      form: {
        category_id: '',
        sub_category_id: 0,
        title: null,
        content: '',
        display_order: 0,
        is_pinned: false,
        replyable: true,
        status: 'DRAFT'
      },
      errors: {},
      loading: false,
      finish: false,

      allStatus: [
        { name: '草稿', value: 'DRAFT' },
        { name: '公開', value: 'PUBLISHED' }
      ],

      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({ level: 6 }),
        new Bold({ bubble: true }),
        new Underline({ bubble: true }),
        new Italic({ bubble: true }),
        new Strike({ bubble: true }),
        new Link({ bubble: true }),
        new Image(),
        new InsertFile(this.auth, this.currentSite, this.permissions, this.profile, process.env.VUE_APP_THREAD_FORM_FOLDER_CODE),
        new InsertYoutube(),
        new Blockquote(),
        new TextAlign(),
        new ListItem(),
        new BulletList({ bubble: true }),
        new OrderedList({ bubble: true }),
        new TodoItem(),
        new TodoList(),
        new Indent(),
        new HardBreak(),
        new HorizontalRule({ bubble: true }),
        new Fullscreen(),
        new CodeView({
          codemirror,
          codemirrorOptions: {
            styleActiveLine: true,
            autoCloseTags: true
          }
        }),
        new History(),
        new Table(),
        new TableHeader(),
        new TableCell(),
        new TableRow(),
        new TextColor(),
        new TextHighlight(),
        new FontType({
          fontTypes: {
            Arial: 'Arial',
            'Arial Black': 'Arial Black',
            Impact: 'Impact',
            Tahoma: 'Tahoma',
            'Times New Roman': 'Times New Roman',
            monospace: 'monospace',
            新細明體: '新細明體',
            細明體: '細明體',
            標楷體: '標楷體',
            微軟正黑體: '微軟正黑體',
            微軟雅黑體: '微軟雅黑體'
          }
        }),
        new FontSize()
      ],
      fontList: ['新細明體', '細明體', '標楷體', '微軟正黑體', '微軟雅黑體', 'serif', 'sans-serif', 'monospace', 'Times New Roman', 'Arial', 'Helvetica', 'Calibri']
    }
  },
  watch: {
    site() {
      this.init()
    },
    data(val) {
      this.init()
    }
  },
  mounted() {
    window.tailwind.svgLoader()
    this.init()
  },
  methods: {
    async init() {
      if (this.site) {
        await this.loadForumCategories()
      }

      if (this.data != null) {
        this.form = this.data
        this.mode = 'update'
      }
    },
    async loadForumCategories() {
      if (this.forumCategories == null) {
        this.forumCategories = await this.getSiteForumCategories(this.site.site_id)
      }
    },
    onSave: async function() {
      this.loading = true
      this.errors = {}

      this.form.content = this.trim(this.form.content, '<p><br></p>')

      let response

      if (this.mode === 'create') {
        response = await this.createSiteForumThread(this.site.site_id, this.form)
      } else {
        response = await this.updateSiteForumThread(this.site.site_id, this.data.thread_id, this.form)
      }

      if ('errors' in response.data) {
        this.errors = response.data.errors
        this.loading = false
      } else {
        let option = {
          title: (this.mode === 'create') ? '已成功新增主題' : '已成功更新主題',
          message: '請稍後，將會轉至主題頁面',
          type: 'success'
        }

        if (response.code === 0) {
          this.finish = true

          setTimeout(() => {
            this.$router.push('/forum')
          }, 1000)
        } else {
          this.loading = false
          option = {
            title: (this.mode === 'create') ? '未能新增主題' : '未能更新主題',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
