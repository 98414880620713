<template>
  <div id="site-groups-page">
    <div class="flex justify-between mt-10">
      <h2 class="intro-y text-lg font-medium">
        <span v-if="site.name != null">{{ site.name }}的</span>組別 (共{{ total }}個)
      </h2>

      <router-link to="/management/sites" type="button" class="btn btn-outline-secondary w-24">返回</router-link>
    </div>

    <div v-if="checkPermission(permissions, 'system.site_enrollment.view')">
      <div class="tab-content border-l border-r border-b mb-5 mt-4">
        <div class="tab-pane leading-relaxed p-5 active">

          <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center">
            <button class="btn btn-primary shadow-md mr-2" @click="onAddGroupModal" v-if="checkPermission(permissions, 'system.site_enrollment.create')">添加組別</button>

            <button v-if="checkPermission(permissions, 'system.site_enrollment.delete') && selectedGroupIds.length < groups.length" class="btn btn-secondary shadow-md ml-2" @click="selectAllItems">
              全選
            </button>

            <button v-if="checkPermission(permissions, 'system.site_enrollment.delete') && selectedGroupIds.length > 0" class="btn btn-secondary shadow-md ml-2" @click="clearSelectedItems">
              清除選擇
            </button>

            <div v-if="checkPermission(permissions, 'system.site_enrollment.delete') && selectedGroupIds.length > 0" class="flex justify-center items-center action ml-2">
              <button type="button" class="btn btn-primary shadow-md dropdown-toggle" @click="action = !action" @focusout="action = false">
                動作
              </button>

              <div class="relative">
                <div class="dropdown-menu w-40" :class="{'show': action}">
                  <ul class="dropdown-content">
                    <li>
                      <button type="button" class="dropdown-item" @click.stop="openDeleteModal()">
                        <font-awesome-icon icon="fa-solid fa-trash" class="fa-1x w-5 mr-2"/>
                        移除
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="hidden md:block mx-auto text-slate-500">正在顯示第 {{ groups.length !== 0 ? from : 0 }} 至 {{ to ?? 0 }} 個組別</div>
            <div v-if="!loading" class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
              <div class="w-56 relative text-slate-500">
                <input v-model="search" type="text" class="form-control w-56 pr-10" placeholder="搜尋" @keyup.enter="loadSiteGroups">
                <font-awesome-icon @click="loadSiteGroups" icon="fa-solid fa-magnifying-glass" class="absolute my-auto w-4 h-4 mr-3 right-0 inset-y-0" />
              </div>
            </div>
            <div v-show="loading" class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0 flex">
              <span><i data-loading-icon="oval" class="w-8 h-8"></i></span>
            </div>
          </div>

          <div class="overflow-x-auto mt-2">
            <table class="table table-striped">
              <thead>
              <tr>
                <th v-if="checkPermission(permissions, 'system.site_enrollment.delete')" class="whitespace-nowrap">選取</th>
                <th class="whitespace-nowrap"><span v-if="search !== ''">名稱</span><order-button v-else title="名稱" column="name" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
                <th class="whitespace-nowrap"><span v-if="search !== ''">角色</span><order-button v-else title="角色" column="role" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
                <th class="text-center whitespace-nowrap">啟用</th>
                <th class="text-center whitespace-nowrap">動作</th>
              </tr>
              </thead>

              <tbody>
              <tr class="" v-for="(item, key) in groups" :key="key">
                <td v-if="checkPermission(permissions, 'system.site_enrollment.delete')"><input class="form-check-input border border-slate-500" type="checkbox" @click.stop="onSelect(item)" :value="item.group_id" v-model="selectedGroupIds"></td>
                <td class="">
                  <router-link :to="`/management/user-groups/${item.group_id}/users`">{{ item.site.name }} - {{ item.name }}</router-link>
                </td>
                <td class="">
                  {{ item.role ? item.role.name : '-' }}
                </td>
                <td class="">
                  <div class="flex justify-center align-items-center mx-4">
                    <div class="mr-2">關</div>
                    <div class="form-switch">
                      <input v-model="item.is_enrolled" :disabled="!checkPermission(permissions, 'system.site_enrollment.edit')" type="checkbox" class="form-check-input" @change="toggleGroup(item)">
                    </div>
                    <div class="ml-2">開</div>
                  </div>
                </td>
                <td class="w-56">
                  <div class="flex justify-center">
                    <button v-if="checkPermission(permissions, 'system.site_enrollment.delete')" class="flex items-center text-danger" @click="openDeleteModal(item)">
                      <font-awesome-icon icon="fa-regular fa-trash-can" />
                      &nbsp;移除 </button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- BEGIN: Pagination -->
      <pagination-row :pages="pages" :current-page.sync="currentPage" :item-per-page.sync="itemPerPage"></pagination-row>
      <!-- END: Pagination -->

      <!-- BEGIN: Add Group Modal -->
      <div id="add-group-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <!-- BEGIN: Modal Header -->
            <div class="modal-header">
              <h2 class="font-medium text-base mr-auto">
                添加組別
              </h2>
            </div>
            <!-- END: Modal Header -->
            <!-- BEGIN: Modal Body -->
            <div class="modal-body">
              <div>
                <label for="role-id" class="form-label"><span class="text-danger">*</span> 角色</label>
                <select v-model="roleId" id="role-id" class="form-select mt-2 sm:mr-2" aria-label="Default select example">
                  <option value="">--- 請選擇 ---</option>
                  <option v-for="(item, key) in roles" :key="key" :value="item.role_id">{{ item.name }}</option>
                </select>
              </div>

              <div class="mt-3">
                <label class="form-label"><span class="text-danger">*</span> 選擇組別</label>
                <span v-show="options == null"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
                <multi-select v-show="options != null" v-model="newGroups" :options="options ?? []" :multiple="true" :close-on-select="true" :clear-on-select="true" :preserve-search="true" placeholder="以名搜尋" selectLabel="按下Enter以選擇" label="display_name" track-by="group_id" :preselect-first="true" :hide-selected="true" >
                  <template slot="noResult"><span class="multiselect__option">沒有結果，請輸入其他字</span></template>
                </multi-select>
              </div>
            </div>
            <!-- END: Modal Body -->
            <!-- BEGIN: Modal Footer -->
            <div class="modal-footer">
              <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-20 mr-1">取消</button>
              <button type="button" class="btn btn-primary w-20" @click="onAdd" :disabled="roleId === '' || newGroups.length === 0">儲存</button>
            </div>
            <!-- END: Modal Footer -->
          </div>
        </div>
      </div>
      <!-- END: Add Group Modal -->

      <!-- BEGIN: Delete Confirmation Modal -->
      <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="p-5 text-center">
                <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger" />
                <div class="text-3xl mt-5">你確定嗎？</div>
                <div class="text-slate-500 mt-2">
                  你是否要移除此組別？
                  <br>
                  此組別會喪失在此分站的權限
                </div>
              </div>
              <div class="px-5 pb-8 text-center">
                <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
                <button type="button" class="btn btn-danger w-24" @click="onDelete">確定</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Delete Confirmation Modal -->
    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>

  </div>
</template>

<script>
export default {
  props: ['auth', 'permissions'],
  data() {
    return {
      mounted: false,
      loading: 0,

      site: {
        name: null
      },

      groups: [],
      userGroups: [],

      itemPerPage: 50,
      currentPage: 1,
      pages: 0,
      from: 0,
      to: 0,
      total: 0,
      search: '',
      orderBy: '',
      order: '',

      removeGroup: null,

      roles: [],
      roleId: '',
      options: null,
      newGroups: [],

      selectedGroupIds: [],
      action: false
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentPage(val) {
      this.loadSiteGroups(false)
    },
    itemPerPage(val) {
      this.loadSiteGroups()
    },
    search(val) {
      if (val === '') {
        this.loadSiteGroups()
      }
    },
    order() {
      this.loadSiteGroups()
    }
  },
  async mounted() {
    window.tailwind.svgLoader()

    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      await this.loadSite()
      await this.loadRoles()
      await this.loadSiteGroups()

      setTimeout(() => {
        this.mounted = true
      }, 500)
    },
    async loadSite() {
      this.loading++

      this.site = await this.getSite(this.$route.params.siteId)

      this.loading--
    },
    async loadSiteGroups(resetCurrentPage = true) {
      this.loading++

      const response = await this.getSiteGroups(this.$route.params.siteId, this.search, this.itemPerPage, this.currentPage, this.orderBy, this.order)
      this.groups = response.data
      this.pages = response.meta.page.last_page
      this.from = response.meta.page.from ?? 0
      this.to = response.meta.page.to ?? 0
      this.total = response.meta.page.total

      if (resetCurrentPage) {
        this.currentPage = 1
      }

      this.loading--
    },
    async loadUserGroups() {
      this.loading++

      this.userGroups = await this.getUserGroups(1)

      this.options = this.userGroups.filter((x) => {
        return !x.is_site
      })

      this.loading--
    },
    async loadRoles() {
      this.loading++

      this.roles = await this.getRoles(this.$route.params.siteId)

      this.loading--
    },
    onAddGroupModal() {
      if (this.options == null) {
        this.loadUserGroups()
      }
      this.roleId = ''
      this.newGroups = []
      const el = document.querySelector('#add-group-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()

      window.tailwind.svgLoader()
    },
    async onAdd() {
      const el = document.querySelector('#add-group-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      const data = {
        role_id: this.roleId
      }

      let response = null

      data.groups = this.newGroups
      response = await this.addSiteGroups(this.$route.params.siteId, data)

      let option = {
        title: '成功',
        message: '已添加組別到分站',
        type: 'success'
      }

      if (response.code === 0) {
        this.roleId = ''
        this.newGroups = []
        await this.loadSiteGroups()
      } else {
        option = {
          title: '未能添加組別到分站',
          message: '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    },
    openDeleteModal(item) {
      this.removeGroup = item

      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDelete() {
      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      if (this.removeGroup != null) {
        const response = await this.deleteSiteGroup(this.$route.params.siteId, this.removeGroup.group_id)

        let option = {
          title: '成功',
          message: '組別已從分站中移除',
          type: 'success'
        }

        if (response.code === 0) {
          await this.loadSiteGroups()
        } else {
          option = {
            title: '未能從分站移除組別',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)
      } else if (this.selectedGroupIds.length > 0) {
        let option = {
          title: '成功',
          message: '組別已從分站中移除',
          type: 'success'
        }

        for (let i = 0; i < this.selectedGroupIds.length; i++) {
          const item = this.getSelectedItem(this.selectedGroupIds[i])
          if (item) {
            const response = await this.deleteSiteGroup(this.$route.params.siteId, item.group_id)

            if (response.code === 0) {
              console.log('Success')
            } else {
              option = {
                title: '未能從分站移除組別',
                message: response.data.message ?? '系統錯誤，請稍後再試',
                type: 'error'
              }
              break
            }
          }
        }

        this.$notify(option)
        this.loadSiteGroups()
      }
    },
    async toggleGroup(item) {
      const response = await this.toggleSiteGroup(this.$route.params.siteId, item.group_id)

      let option = {
        title: '成功',
        message: '已設定組別分站狀態',
        type: 'success'
      }

      if (response.code === 0) {
        console.log()
      } else {
        option = {
          title: '未能設定組別分站狀態',
          message: '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    },
    onSelect(item) {
    },
    selectAllItems() {
      this.clearSelectedItems()
      for (let i = 0; i < this.groups.length; i++) {
        this.selectedGroupIds.push(this.groups[i].group_id)
      }
    },
    clearSelectedItems() {
      this.selectedGroupIds = []
    },
    getSelectedItem(groupId) {
      const index = this.groups.findIndex(e => e.group_id === groupId)
      if (index > -1) {
        return this.groups[index]
      }
      return null
    },
    toggleSelectAll() {
      if (this.newGroups.length > 0) {
        this.newGroups = []
      } else {
        this.newGroups = this.userGroups ?? []
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
#site-groups-page {
  .tab-content {
    background-color: #FFF;

    table {
      thead {
        th:first-child, th:nth-child(3) {
          width: 120px;
        }
      }

      td {
        position: relative;

        span {
          display: inline-block;
          width: 14px;

          &.unread::before {
            top: 23px;
            left: 20px;
          }
        }
      }
    }
  }

  .action {
    .show {
      visibility: visible;
      opacity: 1;
    }

    .dropdown-menu {
      margin-top: 10px;
      left: -100px;

      button {
        width: 100%;
      }
    }
  }
}
</style>
