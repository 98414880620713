<template>
  <div id="contacts-page">
    <h2 class="intro-y text-lg font-medium mt-10">
      通訊錄
    </h2>

    <div v-if="checkPermission(permissions, 'site.phone_directory.view')">
      <div class="box grid grid-cols-12 gap-6 mt-5 p-5">

        <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center ">
          <div class="flex flex-wrap sm:flex-nowrap items-center justify-end mb-2 sm:mb-0">
            <button v-if="!downloading" class="btn btn-primary shadow-md mr-2" @click="onDownload('pdf')">下載PDF
            </button>
            <button v-if="!downloading" class="btn btn-primary shadow-md" @click="openSelectColumnsModal('csv')">
              下載Excel
            </button>
            <span v-show="downloading"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>

          <div class="hidden md:block mx-auto text-slate-500">正在顯示第 {{ contacts.length !== 0 ? from : 0 }} 至
            {{ to ?? 0 }} 個聯絡人
          </div>

          <div v-if="!loading" class="w-56 relative text-slate-500">
            <input @keyup.enter="loadContacts" v-model="search" type="text" class="form-control w-56 pr-10" placeholder="搜尋">
            <font-awesome-icon @click="loadContacts" icon="fa-solid fa-magnifying-glass"
                               class="absolute my-auto w-4 h-4 mr-3 right-0 inset-y-0"/>
          </div>
          <div v-show="loading" class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0 flex">
            <span><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>
        </div>

        <div v-if="!loading" class="col-span-12 flex flex-wrap sm:flex-nowrap items-center justify-end">
          <div class="flex flex-wrap sm:flex-nowrap justify-end">
            <div v-if="false" class="mr-2 flex-basis-50 sm:flex-basis-0 sm:w-32">
              <label class="form-label text-xs">分站</label>
              <select id="site" v-model="siteId" class="form-select mr-2" aria-label="請選擇分站">
                <option v-for="(item, key) in sites" :key="key" :value="item.site_id">{{ item.name }}</option>
              </select>
            </div>

            <div v-if="checkPermission(permissions, 'system.user.edit')" class="mr-2 flex-basis-50 sm:flex-basis-0 sm:w-32">
              <label class="form-label text-xs">生效</label>
              <select id="is_effective" v-model="isEffective" class="form-select mr-2" aria-label="請選擇">
                <option :value="true">是</option>
                <option :value="false">否</option>
              </select>
            </div>

            <div class="mr-2 flex-basis-50 sm:flex-basis-0 sm:w-32">
              <label class="form-label text-xs">主任級或以上</label>
              <select id="is_high_level" v-model="isHighLevel" class="form-select mr-2" aria-label="請選擇">
                <option value="">全部</option>
                <option :value="true">是</option>
                <option :value="false">否</option>
              </select>
            </div>

            <div class="mr-2 flex-basis-50 sm:flex-basis-0 sm:w-32">
              <label class="form-label text-xs">社工</label>
              <select id="is_register_social_worker" v-model="isRegisterSocialWorker" class="form-select mr-2" aria-label="請選擇">
                <option value="">全部</option>
                <option :value="true">是</option>
                <option :value="false">否</option>
              </select>
            </div>

            <div class="mr-2 flex-basis-50 sm:flex-basis-0 sm:w-32">
              <label class="form-label text-xs">服務單位</label>
              <select id="centre_id" v-model="centreId" class="form-select mr-2" aria-label="請選擇">
                <option value="">全部</option>
                <option v-for="(item, key) in centers" :key="key" :value="item.centre_id">{{ item.abbrev }}</option>
              </select>
            </div>
          </div>
        </div>

        <!-- BEGIN: Data List -->
        <div class="intro-y col-span-12 overflow-auto overflow-visible">
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="whitespace-nowrap">頭像</th>
              <th v-if="checkPermission(permissions, 'system.user.edit')" class="whitespace-nowrap"><order-button title="職員編號" column="staff_number" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="whitespace-nowrap"><order-button title="英文全名" column="full_name" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="whitespace-nowrap"><order-button title="中文全名" column="full_name_zh" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="whitespace-nowrap"><order-button title="電郵地址" column="email" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="whitespace-nowrap"><order-button title="服務單位" column="centre" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="whitespace-nowrap"><order-button title="職位名稱" column="position" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="whitespace-nowrap"><order-button title="辦公室電話" column="office_number" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="whitespace-nowrap"><order-button title="手提電話" column="contact_number" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="text-center whitespace-nowrap">檢視</th>
            </tr>
            </thead>
            <tbody>
            <tr class="" v-for="(item, key) in contacts" :key="key">
              <td class="w-0">
                <router-link :to="`/address-books/${item.contact_id}`">
                  <div class="flex">
                    <div class="w-10 h-10 image-fit">
                      <img alt="" class="tooltip rounded-full" :src="item.user.avatar_url != null && item.user.avatar_url !== '' ? item.user.avatar_url : '/images/user.png'">
                    </div>
                  </div>
                </router-link>
              </td>
              <td v-if="checkPermission(permissions, 'system.user.edit')">{{ item.staff_number ?? '-' }}</td>
              <td>{{ item.user.full_name ?? '-' }}</td>
              <td>{{ item.user.full_name_zh ?? '-' }}</td>
              <td>{{ item.user.email ?? '-' }}</td>
              <td>{{ item.user.centre ? item.user.centre.abbrev : '-' }}</td>
              <td>{{ item.user.position ? item.user.position.name : '-' }}</td>
              <td>{{ item.user.office_number ?? '-' }}</td>
              <td>{{ item.user.contact_number ?? '-' }}</td>
              <td class="table-report__action text-center">
                <router-link :to="`/address-books/${item.contact_id}`" class="btn btn-success">
                  <font-awesome-icon icon="fa-regular fa-eye" class="text-white"/>
                </router-link>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- END: Data List -->
        <!-- BEGIN: Pagination -->
        <pagination-row :pages="pages" :current-page.sync="currentPage"
                        :item-per-page.sync="itemPerPage"></pagination-row>
        <!-- END: Pagination -->
      </div>
    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>

    <!-- BEGIN: Select Columns Modal -->
    <div id="select-columns-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">
              選擇下載Excel欄
            </h2>
          </div>

          <div class="modal-body p-0">
            <div class="p-5">
              <div>
                <span class="text-danger">* </span>如全不選擇則視為全都下載
              </div>

              <div class="text-slate-500">
                <div v-for="(item, key) in columns" :key="key"
                     class="flex justify-between items-center px-3 py-2 mt-2 rounded-md">
                  <div class="form-switch flex items-center">
                    <label class="pointer flex">
                      <input type="checkbox" class="form-check-input mr-2" v-model="selectedColumns"
                             :value="item.value">
                      <div>{{ item.key }}</div>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="px-5 pb-8 text-center">
              <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消
              </button>
              <button type="button" class="btn btn-primary w-24" @click="onDownload('csv')">下載</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Select Columns Modal -->
  </div>
</template>

<script>
export default {
  props: ['auth', 'allSites', 'currentSite', 'permissions', 'userSites'],
  data() {
    return {
      mounted: false,
      loading: 0,

      sites: [],
      centers: [],

      contacts: [],

      downloading: false,

      siteId: null,
      isEffective: true,
      isHighLevel: '',
      isRegisterSocialWorker: '',
      centreId: '',
      itemPerPage: 50,
      currentPage: 1,
      pages: 0,
      from: 0,
      to: 0,
      search: '',
      orderBy: '',
      order: '',

      columns: [],

      selectedColumns: []
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    permissions(val) {
      if (this.checkPermission(val, 'system.user.edit')) {
        this.columns = [
          {
            value: 'staff_number',
            key: '職員編號'
          },
          {
            value: 'centre',
            key: '所屬部/組/服務單位'
          },
          {
            value: 'position',
            key: '職位'
          },
          {
            value: 'is_high_level',
            key: '主任級或以上'
          },
          {
            value: 'is_register_social_worker',
            key: '註冊社工'
          },
          {
            value: 'honorific_title',
            key: '稱謂'
          },
          {
            value: 'full_name_zh',
            key: '中文名稱'
          },
          {
            value: 'full_name',
            key: '英文名稱'
          },
          {
            value: 'display_name',
            key: '顯示名稱'
          },
          {
            value: 'email',
            key: '電郵地址'
          },
          {
            value: 'office_number',
            key: '辦公室電話'
          },
          {
            value: 'fax',
            key: '傳真'
          },
          {
            value: 'contact_number',
            key: '手提電話'
          },
          {
            value: 'other_number',
            key: '其他電話'
          },
          {
            value: 'birthdate',
            key: '出生日期'
          },
          {
            value: 'address',
            key: '地址'
          }
        ]
      } else {
        this.columns = [
          {
            value: 'centre',
            key: '所屬部/組/服務單位'
          },
          {
            value: 'position',
            key: '職位'
          },
          {
            value: 'is_high_level',
            key: '主任級或以上'
          },
          {
            value: 'is_register_social_worker',
            key: '註冊社工'
          },
          {
            value: 'honorific_title',
            key: '稱謂'
          },
          {
            value: 'full_name_zh',
            key: '中文名稱'
          },
          {
            value: 'full_name',
            key: '英文名稱'
          },
          {
            value: 'display_name',
            key: '顯示名稱'
          },
          {
            value: 'email',
            key: '電郵地址'
          },
          {
            value: 'office_number',
            key: '辦公室電話'
          },
          {
            value: 'fax',
            key: '傳真'
          },
          {
            value: 'contact_number',
            key: '手提電話'
          },
          {
            value: 'other_number',
            key: '其他電話'
          },
          {
            value: 'birthdate',
            key: '出生日期'
          },
          {
            value: 'address',
            key: '地址'
          }
        ]
      }
    },
    currentSite() {
      this.init()
    },
    siteId() {
      if (this.mounted) {
        this.loadContacts()
      }
    },
    isEffective() {
      this.loadContacts()
    },
    isHighLevel() {
      this.loadContacts()
    },
    isRegisterSocialWorker() {
      this.loadContacts()
    },
    centreId() {
      this.loadContacts()
    },
    currentPage(val) {
      this.loadContacts(false)
    },
    itemPerPage(val) {
      this.loadContacts()
    },
    order() {
      this.loadContacts()
    },
    search(val) {
      if (val === '') {
        this.loadContacts()
      }
    }
  },
  async mounted() {
    window.tailwind.svgLoader()

    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        const siteId = this.$route.query.siteId
        if (siteId != null && siteId !== '') {
          this.siteId = siteId
        } else if (this.currentSite) {
          this.siteId = this.currentSite.site_id
        }
        const centreId = this.$route.query.centreId
        if (centreId != null && centreId !== '') {
          this.centreId = centreId
        }

        this.search = this.$route.query.search ?? ''

        await this.loadSites()
        await this.loadCenters()
        await this.loadContacts()

        this.mounted = true
      }
    },
    async loadSites() {
      this.loading++

      this.sites = await this.getAllSites()

      this.loading--
    },
    async loadCenters() {
      this.loading++

      this.centers = await this.getCenters()

      this.loading--
    },
    async loadContacts(resetCurrentPage = true) {
      this.loading++

      const response = await this.getSiteContacts(this.siteId, this.search, this.itemPerPage, this.currentPage, this.isEffective, this.isHighLevel, this.isRegisterSocialWorker, this.centreId, this.orderBy, this.order)

      if (response.code === 0) {
        this.contacts = response.data
        this.pages = response.meta.page.last_page
        this.from = response.meta.page.from ?? 0
        this.to = response.meta.page.to ?? 0
      }

      if (resetCurrentPage) {
        this.currentPage = 1
      }

      this.loading--
    },
    openSelectColumnsModal() {
      const el = document.querySelector('#select-columns-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDownload(type) {
      this.downloading = true

      if (type === 'csv') {
        const el = document.querySelector('#select-columns-modal')
        const modal = window.tailwind.Modal.getOrCreateInstance(el)
        modal.hide()
      }

      const response = await this.downloadSiteMailingList(this.siteId, type, this.selectedColumns, this.search, this.isEffective, this.isHighLevel, this.isRegisterSocialWorker, this.centreId, this.orderBy, this.order)
      const blob = new Blob([response], { type: `application/${type}` })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      if (type === 'csv') {
        link.download = this.$moment().format('YYYY_MM_DD') + '_CSD_Mailing_List.xlsx'
      } else {
        link.download = this.$moment().format('YYYY_MM_DD') + `_CSD_Mailing_List.${type}`
      }
      link.click()
      URL.revokeObjectURL(link.href)
      this.downloading = false
    }
  }
}
</script>

<style lang="scss" scoped>
#contacts-page {
  .tab-content {
    background-color: #FFF;

    table {
      thead {
        th:first-child, th:nth-child(3) {
          width: 120px;
        }
      }
    }
  }
}
</style>
