<template>
  <div id="resource-form">
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">

          <div class="">
            <label for="resource_type" class="form-label"><span class="text-danger">*</span> 類別</label>
            <select id="resource_type" v-model="form.resource_type" class="form-select" aria-label="請選擇類別">
              <option value="">--- 請選擇 ---</option>
              <option v-for="(item, key) in resourceTypes" :key="key" :value="item.value">{{ item.key }}</option>
            </select>
          </div>

          <div class="mt-6">
            <label for="name" class="form-label"><span class="text-danger">*</span> 項目名稱</label>
            <input v-model="form.name" id="name" type="text" class="form-control w-full" placeholder="請輸入項目名稱">
            <div v-if="'name' in errors" class="text-danger mt-2" v-html="errors.name"></div>
          </div>

          <div class="mt-6">
            <label for="description" class="form-label">簡介</label>
            <input v-model="form.description" id="description" type="text" class="form-control w-full" placeholder="請輸入簡介">
            <div v-if="'description' in errors" class="text-danger mt-2" v-html="errors.description"></div>
          </div>

          <div class="mt-6">
            <label for="address" class="form-label">地點</label>
            <input v-model="form.address" id="address" type="text" class="form-control w-full" placeholder="請輸入地址">
            <div v-if="'address' in errors" class="text-danger mt-2" v-html="errors.address"></div>
          </div>

          <div class="mt-6">
            <label for="remark" class="form-label">備註</label>
            <input v-model="form.remark" id="remark" type="text" class="form-control w-full" placeholder="請輸入備註">
            <div v-if="'remark' in errors" class="text-danger mt-2" v-html="errors.remark"></div>
          </div>

          <div class="mt-6">
            <label for="is_available">可借用</label>
            <div class="form-switch mt-2">
              <input id="is_available" v-model="form.is_available" type="checkbox" class="form-check-input">
            </div>

            <div v-if="'is_available' in errors" class="text-danger mt-2" v-html="errors.is_available"></div>
          </div>

          <div v-if="false && site && site.site_id == 1" class="mt-6">
            <label for="is_available">可用分站</label>

            <div class="flex flex-wrap">
              <div v-for="(item, key) in allSites" :key="key" class="site mt-4">
                <label for="allowedSites">{{ item.name }}</label>

                <div class="form-switch">
                  <input id="allowedSites" v-model="allowedSites" type="checkbox" class="form-check-input" :value="item.site_id">
                </div>
              </div>
            </div>

            <div v-if="'allowedSites' in errors" class="text-danger mt-2" v-html="errors.allowedSites"></div>
          </div>

          <div class="flex justify-end align-items-center mt-5">
            <router-link v-if="!loading && !finish" to="/management/resources" type="button" class="btn btn-outline-secondary w-24 mr-1">取消</router-link>
            <button v-if="!loading && !finish" type="button" class="btn btn-primary w-24" @click="onSave">儲存</button>
            <span v-show="loading && !finish"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['site', 'data', 'allSites'],
  data() {
    return {
      mode: 'create',
      sites: [],
      form: {
        resource_type: '',
        name: null,
        description: null,
        address: null,
        remark: null,
        is_available: true,
        allowedSites: []
      },
      allowedSites: [],

      errors: {},
      loading: false,
      finish: false,

      mounted: false
    }
  },
  watch: {
    data(val) {
      if (val != null) {
        for (let i = 0; i < this.data.allowedSites.length; i++) {
          this.allowedSites.push(this.data.allowedSites[i].site_id)
        }

        this.form = this.data
        this.mode = 'update'
      }
    },
    allSites() {
      this.init()
    }
  },
  mounted() {
    window.tailwind.svgLoader()
    this.init()
  },
  methods: {
    init() {
      if (this.allSites) {
        this.mounted = true
      }
    },
    onSave: async function() {
      this.loading = true
      this.errors = {}

      this.form.allowedSites = []

      for (let i = 0; i < this.allowedSites.length; i++) {
        this.form.allowedSites.push(this.getObjectValue(this.allSites, 'site_id', this.allowedSites[i]))
      }

      let response

      if (this.mode === 'create') {
        response = await this.createSiteResource(this.site.site_id, this.form)
      } else {
        response = await this.updateSiteResource(this.site.site_id, this.data.resource_id, this.form)
      }

      if ('errors' in response.data) {
        this.errors = response.data.errors
        this.loading = false
      } else {
        let option = {
          title: (this.mode === 'create') ? '已成功新增項目' : '已成功更新項目',
          message: '請稍後，將會轉至會議室及資源項目頁面',
          type: 'success'
        }

        if (response.code === 0) {
          this.finish = true

          setTimeout(() => {
            this.$router.push('/management/resources')
          }, 1000)
        } else {
          this.loading = false
          option = {
            title: (this.mode === 'create') ? '未能新增項目' : '未能更新項目',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)
      }
    }
  }
}
</script>

<style scoped lang="scss">
#resource-form {
  .site {
    flex: 0 0 50%;
  }
}
</style>
