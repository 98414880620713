<template>
  <div id="announcement-page">

    <div class="flex justify-end mt-8">
      <router-link to="/faq" type="button" class="btn btn-outline-secondary w-24">返回</router-link>
    </div>

    <div v-if="checkPermission(permissions, 'site.knowledge_base.view')">
      <div v-if="data != null" class="intro-y news xl:w-3/5 p-5 box">
        <!-- BEGIN: Blog Layout -->
        <h2 class="intro-y font-medium text-xl sm:text-2xl">
          {{ data.title }}
        </h2>
        <div class="intro-y text-slate-600 dark:text-slate-500 mt-3 text-xs sm:text-sm"><span class="text-primary"> {{ data.type?.name }}</span>
        </div>
        <div class="intro-y text-justify html-content mt-6" v-html="data.content">
        </div>
        <div class="flex mt-5 pt-5 border-t border-slate-200/60 dark:border-darkmode-400">
          <div class="intro-y flex flex-1 text-xs sm:text-sm flex-col sm:flex-row items-center">
            <div class="flex items-center">
              <div class="w-12 h-12 flex-none image-fit" v-if="data.user != null">
                <img alt="" class="rounded-full" :src="data.user.avatar_url != null && data.user.avatar_url !== '' ? data.user.avatar_url : '/images/user.png'">
              </div>
              <div class="ml-3 mr-auto">
                <div v-if="data.user != null" class="font-medium">{{ data.user.display_name }}</div>
                <div class="text-slate-500 text-xs">建立日期：{{ $moment(data.created_at).format('YYYY-MM-DD HH:mm') }}</div>
                <div class="text-slate-500 text-xs">上次修改日期：{{ $moment(data.updated_at).format('YYYY-MM-DD HH:mm') }}<span v-if="data.updatedUser != null">，修改者：{{ data.updatedUser.display_name }}</span></div>
              </div>
            </div>
          </div>
          <div class="intro-y flex justify-end items-end">
            <button v-if="!printing" type="button" class="flex items-center mr-3 no-print text-pending" @click="onCopy(data)">
              <font-awesome-icon icon="fa-solid fa-link" class="mr-1" />
               分享連結
            </button>
            <router-link v-if="checkPermission(permissions, 'site.knowledge_base.edit')" :to="`/faq/update-knowledge/${data.knowledge_id}`" class="flex items-center mr-3">
              <font-awesome-icon icon="fa-solid fa-pen-to-square" />
              &nbsp;更改 </router-link>
            <button v-if="checkPermission(permissions, 'site.knowledge_base.delete')" class="flex items-center text-danger" @click="openDeleteModal(data.knowledge_id)">
              <font-awesome-icon icon="fa-regular fa-trash-can" />
              &nbsp;刪除 </button>
          </div>
        </div>
        <!-- END: Blog Layout -->
      </div>
    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>

    <!-- BEGIN: Delete Confirmation Modal -->
    <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger" />
              <div class="text-3xl mt-5">你確定嗎？</div>
              <div class="text-slate-500 mt-2">
                你是否要刪除此FAQ？
                <br>
                這動作不能還原。
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
              <button type="button" class="btn btn-danger w-24" @click="onDelete">確定</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
  </div>
</template>

<script>
export default {
  props: ['auth', 'currentSite', 'permissions'],
  data() {
    return {
      mounted: false,
      data: null
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    async currentSite(val) {
      await this.init()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.loadData()

        if (this.data.code) {
          this.$router.push('/faq')
        }

        this.mounted = true
      }
    },
    async loadData() {
      this.data = await this.getSiteFaqBase(this.currentSite.site_id, this.$route.params.knowledgeId)
    },
    onCopy(item) {
      const tempInput = document.createElement('input')
      tempInput.value = window.location.host + `/faq/knowledge/${item.knowledge_id}`
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand('copy')
      document.body.removeChild(tempInput)

      const option = {
        title: '成功',
        message: '已複製分享連結',
        type: 'success'
      }

      this.$notify(option)
    },
    openDeleteModal(id) {
      this.deleteId = id

      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDelete() {
      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      const response = await this.deleteSiteFaqBase(this.currentSite.site_id, this.deleteId)

      let option = {
        title: '成功',
        message: 'FAQ已被刪除',
        type: 'success'
      }

      if (response.code === 0) {
        setTimeout(() => {
          this.$router.push('/faq')
        }, 1000)
      } else {
        option = {
          title: '未能刪除FAQ',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    }
  }
}
</script>

<style lang="scss" scoped>
#announcement-page {
}
</style>
