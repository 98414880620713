<template>
  <div id="submit-form-page">

    <div v-if="checkPermission(permissions, 'site.form.view')">
      <div v-if="data != null" class="flex justify-between mt-10">
        <h2 class="intro-y text-lg font-medium">
          {{ data.name }}
        </h2>

        <router-link to="/forms" type="button" class="btn btn-outline-secondary w-24">返回</router-link>
      </div>

      <div v-if="data != null">
        <submit-form-form :current-site="currentSite" :data="data" :response="response"></submit-form-form>
      </div>
    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>

  </div>
</template>

<script>
export default {
  props: ['auth', 'currentSite', 'permissions'],
  data() {
    return {
      mounted: false,
      data: null,
      response: false
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentSite(val) {
      if (val) {
        this.init()
      }
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.loadData()

        this.mounted = true
      }
    },
    async loadData() {
      this.data = await this.getSiteForm(this.currentSite.site_id, this.$route.params.formId)

      if (this.data.code) {
        this.$router.push('/forms')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#form-page {
  .leading-relaxed {
    min-height: 400px;
  }

  .type {
    font-size: 16px;
  }
}
</style>
