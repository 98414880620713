<template>
  <div id="line-of-supervisions-page">

    <h2 class="intro-y text-lg font-medium mt-10">
      主管經理 (共{{ allLineOfSupervisions.length }}個)
    </h2>

    <div v-if="checkPermission(permissions, 'system.line-of-supervision.view')">
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
          <router-link to="/management/create-line-of-supervision" class="btn btn-primary shadow-md mr-2" v-if="checkPermission(permissions, ['system.line-of-supervision.create'])">新增經理</router-link>
          <div class="hidden md:block mx-auto text-slate-500">正在顯示第
            {{ filterLineOfSupervisions.length !== 0 ? parseInt((currentPage - 1) * itemPerPage + 1) : 0 }} 至 {{
              (((currentPage - 1) * itemPerPage + itemPerPage) < filterLineOfSupervisions.length) ? parseInt((currentPage - 1) * itemPerPage + itemPerPage) : filterLineOfSupervisions.length
            }} 個經理
          </div>
          <div v-if="!loading" class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
            <div class="w-56 relative text-slate-500">
              <input v-model="search" type="text" class="form-control w-56 box pr-10" placeholder="搜尋" @keyup.enter="onSearch()">
              <font-awesome-icon @click="onSearch()" icon="fa-solid fa-magnifying-glass" class="absolute my-auto w-4 h-4 mr-3 right-0 inset-y-0"/>
            </div>
          </div>
          <div v-show="loading" class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0 flex">
            <span><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>
        </div>
        <!-- BEGIN: Data List -->
        <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
          <table class="table table-report -mt-2">
            <thead>
            <tr>
              <th class="whitespace-nowrap">名稱</th>
              <th class="text-center whitespace-nowrap" v-if="checkPermission(permissions, ['system.line-of-supervision.edit', 'system.line-of-supervision.delete'])">動作</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, key) in LineOfSupervisions" :key="key" class="intro-x">
              <td>
                <div class="font-medium whitespace-nowrap">{{ item.name }}</div>
              </td>
              <td class="table-report__action w-56" v-if="checkPermission(permissions, ['system.line-of-supervision.edit', 'system.line-of-supervision.delete'])">
                <div class="flex justify-center items-center">
                  <router-link v-if="checkPermission(permissions, 'system.line-of-supervision.edit')" :to="`/management/update-line-of-supervision/${item.id}`"
                              class="flex items-center mr-3">
                    <font-awesome-icon icon="fa-solid fa-pen-to-square"/>
                    &nbsp;更改
                  </router-link>
                  <button v-if="checkPermission(permissions, 'system.line-of-supervision.delete')" class="flex items-center text-danger" @click="openDeleteModal(item.id)">
                    <font-awesome-icon icon="fa-regular fa-trash-can"/>
                    &nbsp;刪除
                  </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- END: Data List -->
        <!-- BEGIN: Pagination -->
        <pagination-row :pages="pages" :current-page.sync="currentPage"
                        :item-per-page.sync="itemPerPage"></pagination-row>
        <!-- END: Pagination -->
      </div>
    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>

    <!-- BEGIN: Delete Confirmation Modal -->
    <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger"/>
              <div class="text-3xl mt-5">你確定嗎？</div>
              <div class="text-slate-500 mt-2">
                你是否要刪除此類別？
                <br>
                這動作不能還原。
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
              <button type="button" class="btn btn-danger w-24" @click="onDelete">確定</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
  </div>
</template>

<script>
export default {
  props: ['auth', 'permissions'],
  data() {
    return {
      mounted: false,
      loading: 0,

      allLineOfSupervisions: [],
      filterLineOfSupervisions: [],
      LineOfSupervisions: [],
      itemPerPage: 50,
      currentPage: 0,
      pages: 0,
      search: null,
      deleteId: null
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentPage(val) {
      this.updateList()
    },
    itemPerPage(val) {
      this.pages = Math.ceil(this.filterLineOfSupervisions.length / val)
      if (this.currentPage === 1) {
        this.updateList()
      }

      this.currentPage = 1
    },
    search(val) {
      if (val === '') {
        this.onSearch()
      }
    }
  },
  async mounted() {
    window.tailwind.svgLoader()

    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      await this.loadLineOfSupervisions()

      setTimeout(() => {
        this.mounted = true
      }, 500)
    },
    async loadLineOfSupervisions() {
      this.loading++

      this.filterLineOfSupervisions = this.allLineOfSupervisions = await this.getLineOfSupervisions('ALL')
      this.pages = Math.ceil(this.filterLineOfSupervisions.length / this.itemPerPage)
      this.currentPage = 1

      this.loading--
    },
    onSearch() {
      const val = this.search.toLowerCase()

      if (val == null || val === '') {
        this.filterLineOfSupervisions = this.allLineOfSupervisions
      } else {
        this.filterLineOfSupervisions = this.allLineOfSupervisions.filter(o => ((o.name != null && o.name.toLowerCase().includes(val)) || (o.description != null && o.description.toLowerCase().includes(val))))
      }

      this.pages = Math.ceil(this.filterLineOfSupervisions.length / this.itemPerPage)
      this.currentPage = 1
      this.updateList()
    },
    updateList() {
      this.LineOfSupervisions = this.filterLineOfSupervisions.slice((this.currentPage - 1) * this.itemPerPage, (this.currentPage - 1) * this.itemPerPage + this.itemPerPage)
    },
    openDeleteModal(id) {
      this.deleteId = id

      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDelete() {
      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      const response = await this.deleteLineOfSupervision(this.deleteId)

      let option = {
        title: '成功',
        message: '類別已被刪除',
        type: 'success'
      }

      if (response.code === 0) {
        await this.loadLineOfSupervisions()
        this.updateList()
      } else {
        option = {
          title: '未能刪除類別',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    }
  }
}
</script>

<style lang="scss" scoped></style>
