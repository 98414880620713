<template>
  <div id="user-page">
    <div class="mt-10">
      <div v-if="data != null" class="intro-y xl:w-3/5 p-5 box">
        <div class="flex justify-end">
          <a @click="$router.back()" class="btn btn-outline-secondary w-24">返回</a>
        </div>

        <div class="flex justify-center">
          <div class="profile-image image-fit">
            <img alt="" :src="data.avatar_url ?? '/images/user.png'" class="tooltip rounded-full">
          </div>
        </div>

        <div class="mt-14">
          <div class="sm:grid grid-cols-2 gap-2">
            <div>
              <label for="staff-number" class="form-label">職員編號</label>
              <div class="w-full">{{ data.staff_number }}</div>
            </div>

            <div>
              <label class="form-label">用戶登入名稱</label>
              <div class="w-full">{{ data.username }}</div>
            </div>
          </div>
        </div>

        <div class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6 mt-6" role="alert">
          <span class="font-medium">基本資料</span>
        </div>

        <div class="mt-3">
          <div class="sm:grid grid-cols-2 gap-2">
            <div>
              <label for="centre_id" class="form-label">所屬部/組/服務單位</label>
              <div class="w-full">{{ data.centre ? data.centre.abbrev : '-' }}</div>
            </div>

            <div>
              <label for="position_id" class="form-label">職位</label>
              <div class="w-full">{{ data.position ? data.position.name : '-' }}</div>
            </div>
          </div>
        </div>

        <div class="mt-3">
          <div class="flex">
            <div class="flex-1 mr-1">
              <label class="form-label">主任級或以上</label>
              <div class="w-full">{{ data.is_high_level ? '是' : '否' }}</div>
            </div>
            <div class="flex-1 ml-1">
              <label class="form-label">註冊社工</label>
              <div class="w-full">{{ data.is_register_social_worker ? '是' : '否' }}</div>
            </div>
          </div>
        </div>

        <div class="mt-3">
          <label class="form-label">稱謂</label>
          <div class="w-full">{{ data.honorific_title ? getObjectValue(titles, 'value', data.honorific_title, 'key') : '-' }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">中文名稱</label>
          <div class="w-full">{{ isEmpty(data.full_name_zh) }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">英文名稱</label>
          <div class="w-full">{{ isEmpty(data.full_name) + ' ' + checkTextEmpty(data.other_name_en) }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">顯示名稱</label>
          <div class="w-full">{{ data.display_name ?? '-' }}</div>
        </div>

        <div class="flex justify-between mt-3">
          <div class="flex-1">
            <label class="form-label">電郵地址</label>
            <div class="w-full">{{ isEmpty(data.email)}}</div>
          </div>

          <div class="flex-1">
            <label class="form-label">帳戶狀態</label>
            <div v-if="!data.is_effective || data.deleted_at != null" class="flex items-center justify-start text-warning">
              <font-awesome-icon icon="fa-regular fa-circle-xmark" />
              <div class="ml-2">無效</div>
            </div>
            <div v-else class="flex items-center justify-start text-success">
              <font-awesome-icon icon="fa-regular fa-square-check" />
              <div class="ml-2">生效</div>
            </div>
          </div>
        </div>

        <div class="mt-3 flex">
          <div class="flex-1">
            <label for="staff_number" class="form-label">辦公室電話</label>
            <div class="w-full">{{ data.office_number ?? '-' }}</div>
          </div>
          <div class="flex-1">
            <label for="cost_centre_code" class="form-label">傳真</label>
            <div class="w-full">{{ data.fax ?? '-' }}</div>
          </div>
        </div>

        <div class="mt-3 flex">
          <div class="flex-1">
            <label for="staff_number" class="form-label">手提電話</label>
            <div class="w-full">{{ data.contact_number ?? '-' }}</div>
          </div>
          <div class="flex-1">
            <label for="cost_centre_code" class="form-label">其他電話</label>
            <div class="w-full">{{ data.other_number ?? '-' }}</div>
          </div>
        </div>

        <div class="mt-3">
          <label class="form-label">出生日期</label>
          <div class="w-full">{{ data.birthdate ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">專長</label>
          <div class="w-full">{{ data.special_skill ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">關於我</label>
          <div class="w-full">{{ data.about_me ?? '-' }}</div>
        </div>

        <div class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6 mt-6" role="alert">
          <span class="font-medium">其他資料</span>
        </div>

        <div class="mt-5">
          <label class="form-label">個人網站</label>
          <a v-if="data.personal_website" :href="getValidUrl(data.personal_website)" target="_blank" class="w-full block text-primary">{{ data.personal_website }}</a>
          <div v-else class="w-full">-</div>
        </div>

        <div class="mt-5">
          <label class="form-label">博客網站</label>
          <a v-if="data.blog_website" :href="getValidUrl(data.blog_website)" target="_blank" class="w-full block text-primary">{{ data.blog_website }}</a>
          <div v-else class="w-full">-</div>
        </div>

        <div class="mt-5">
          <label class="form-label">其他網站</label>
          <a v-if="data.other_website" :href="getValidUrl(data.other_website)" target="_blank" class="w-full block text-primary">{{ data.other_website }}</a>
          <div v-else class="w-full">-</div>
        </div>

        <div class="mt-5">
          <label class="form-label">團體及組織</label>
          <div class="w-full">{{ data.group ?? '-' }}</div>
        </div>

        <div class="mt-5">
          <label class="form-label">榮譽及獎項</label>
          <div class="w-full">{{ data.honors ?? '-' }}</div>
        </div>

        <div class="mt-5">
          <label class="form-label">榮譽及獎項</label>
          <div class="w-full">{{ data.honors ?? '-' }}</div>
        </div>

        <div class="mt-5">
          <label class="form-label">興趣</label>
          <div class="w-full">{{ data.interest ?? '-' }}</div>
        </div>

        <div class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6 mt-6" role="alert">
          <span class="font-medium">教育</span>
        </div>

        <div class="mt-5">
          <label class="form-label">就讀學校</label>
          <div class="w-full">{{ data.school ?? '-' }}</div>
        </div>

        <div class="mt-5 flex">
          <div class="flex-1">
            <label for="academic" class="form-label">獲取的學歷</label>
            <div class="w-full">{{ data.academic ?? '-' }}</div>
          </div>

          <div class="flex-1">
            <label for="academic_department" class="form-label">學系</label>
            <div class="w-full">{{ data.academic_department ?? '-' }}</div>
          </div>

          <div class="flex-1">
            <label for="academic_other" class="form-label">其他資料</label>
            <div class="w-full">{{ data.academic_other ?? '-' }}</div>
          </div>
        </div>

        <div class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6 mt-6" role="alert">
          <span class="font-medium">專業資格</span>
        </div>

        <div class="mt-5 flex">
          <div class="flex-1">
            <label for="professional_qualifications" class="form-label">持有的專業資格</label>
            <div class="w-full">{{ data.professional_qualifications ?? '-' }}</div>
          </div>

          <div class="flex-1">
            <label for="qualification_authority" class="form-label">頒發機構全名</label>
            <div class="w-full">{{ data.qualification_authority ?? '-' }}</div>
          </div>

          <div class="flex-1">
            <label for="qualification_level" class="form-label">已達到的程度</label>
            <div class="w-full">{{ data.qualification_level ?? '-' }}</div>
          </div>
        </div>

        <div class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6 mt-6" role="alert">
          <span class="font-medium">工作經驗</span>
        </div>

        <div class="mt-5 flex">
          <div class="flex-1">
            <label for="past_company" class="form-label">公司名稱</label>
            <div class="w-full">{{ data.past_company ?? '-' }}</div>
          </div>

          <div class="flex-1">
            <label for="past_position" class="form-label">職位</label>
            <div class="w-full">{{ data.past_position ?? '-' }}</div>
          </div>

          <div class="flex-1">
            <label for="past_intro" class="form-label">描述</label>
            <div class="w-full">{{ data.past_intro ?? '-' }}</div>
          </div>
        </div>
      </div>

      <div v-if="data != null && (checkPermission(permissions, 'site.user-group.view') || checkPermission(permissions, 'system.site.view'))" class="intro-y xl:w-3/5 p-5 box mt-5">
        <div v-if="checkPermission(permissions, 'site.user-group.view')">
          <div  class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-5" role="alert">
            <span class="font-medium">組別</span>
          </div>
          <ul class="mb-6">
            <li v-for="(item, key) in userGroups" :key="key">{{ item.display_name }}</li>
          </ul>
        </div>

        <div v-if="checkPermission(permissions, 'system.site.view')">
          <div  class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6" role="alert">
            <span class="font-medium">內聯網分站</span>
          </div>

          <div class="mb-5" v-for="(item, key) in sitePermissions" :key="key">
            <hr class="mb-5" v-if="key > 0">
            <div class="font-bold">{{ '分站：' + item.name }}</div>
            <div class="mt-2 font-bold" v-if="checkPermission(permissions, 'system.role.view')">
              {{ '角色：' + item.roles.map(entry => entry.name).join('、') }}
            </div>
            <div class="mt-2 flex flex-wrap" v-if="checkPermission(permissions, 'system.role_permission.view')">
              <div class="w-1/2" v-for="(permission, j) in item.permissions" :key="j">{{ permission.name ?? '-' }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="mounted">
        <div class="alert alert-pending show mt-2" role="alert">沒有此用戶</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'permissions'],
  data() {
    return {
      mounted: false,

      userGroups: [],
      sitePermissions: [],

      data: null,

      isSystemAdmin: false
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    async permissions(val) {
      this.init()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.auth && this.permissions) {
        this.data = await this.getUser(this.$route.params.userId)

        if (this.checkPermission(this.permissions, 'site.user-group.view')) {
          await this.loadUserGroups()
        }
        if (this.checkPermission(this.permissions, 'system.site.view')) {
          await this.loadSitePermissions()
        }

        // await this.checkIsSystemAdmin()

        this.mounted = true
      }
    },
    async loadUserGroups() {
      this.userGroups = await this.getUserUserGroups(this.data.user_id)
    },
    async loadSitePermissions() {
      this.sitePermissions = await this.getUserSitePermissions(this.data.user_id)
    },
    isEmpty(val) {
      return (val != null && val !== '' ? val : '-')
    },
    async checkIsSystemAdmin() {
      let sites = await this.$cookies.get('sites')

      if (sites == null) {
        const response = await this.getProfile()
        await this.$cookies.set('profile', response.profile)
        await this.$cookies.set('sites', response.sites)
        sites = response.sites
      }

      for (let i = 0; i < sites.length; i++) {
        if (sites[i].site_id === 1 && sites[i].role != null && sites[i].role.role_id === 1) {
          this.isSystemAdmin = true
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#user-page {
  .leading-relaxed {
    min-height: 400px;
  }

  .profile-image {
    width: 100px;
    height: 100px;
  }

  h3 {
    font-size: 18px;
  }
}
</style>
