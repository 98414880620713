<template>
  <div id="mobile-menu" class="p-relative">
    <div class="backdrop" v-if="show" @click="onClose"></div>
    <!-- BEGIN: Mobile Menu -->
    <div class="mobile-menu" :class="{'mobile-menu--active': show }">
      <div class="scrollable">
        <button class="mobile-menu-toggler" @click="onClose"><font-awesome-icon icon="fa-regular fa-circle-xmark" class="w-8 h-8 text-white transform"/></button>

        <ul class="scrollable__content py-2 mt-10">
          <li v-for="(item, key) in menuItems" :key="key">
            <a v-if="item.externalLink" :href="item.url" :target="item.target" class="menu">
              <div class="menu__icon"><font-awesome-icon :icon="item.icon" class="fa-2x fa-fw" /></div>
              <div class="menu__title"> {{ item.name }}</div>
            </a>
            <template v-else-if="item.sitePermission && (item.permission == null || checkPermission(permissions, item.permission))">
              <router-link v-if="item.children == null" :to="item.url" :target="item.target" class="menu" :class="{'menu--active': isActive(item)}" @click.native="show = false">
                <div class="menu__icon"><font-awesome-icon :icon="item.icon" class="fa-2x fa-fw" /></div>
                <div class="menu__title"> {{ item.name }}</div>
              </router-link>

              <button v-else class="w-full menu" @click="item.show = !item.show">
                <div class="menu__icon"> <font-awesome-icon :icon="item.icon" class="fa-2x fa-fw" /> </div>
                <div class="menu__title"> {{ item.name }} <font-awesome-icon icon="fa-solid fa-chevron-up" class="menu__sub-icon" /> </div>
              </button>

              <ul v-if="item.children != null && item.children.length > 0 && item.show">
                <li v-for="(child, index) in item.children" :key="`${key}-${index}`">
                  <router-link :to="child.url" class="menu" @click.native="show = false">
                    <div class="menu__icon"> <font-awesome-icon :icon="child.icon ?? 'fa-regular fa-circle'" class="fa-2x fa-fw" /> </div>
                    <div class="menu__title"> {{ child.name }} </div>
                  </router-link>
                </li>
              </ul>
            </template>
          </li>
        </ul>
      </div>
    </div>
    <!-- END: Mobile Menu -->
  </div>
</template>

<script>
export default {
  props: ['currentSite', 'permissions'],
  data() {
    return {
      show: false,
      menuItems: []
    }
  },
  watch: {
    currentSite() {
      this.init()
    }
  },
  async mounted() {
    await this.init()
  },
  methods: {
    init() {
      this.mobileMenu()
    },
    isActive(item) {
      return this.$route.path.startsWith(item.url)
    },
    onClose() {
      this.show = false
    }
  }
}
</script>

<style scoped lang="scss">
#mobile-menu{
  .mobile-menu::before {
    display: none;
  }

  .mobile-menu .scrollable {
    z-index: 65;
  }

  .banner {
    margin-left: auto;
    margin-right: auto;
    width: 220px;

    a {
      width: 100%;
      margin: 16px 0;
    }
  }

  .mobile-menu .scrollable .mobile-menu-toggler {
    right: unset;
    left: 200px;
    margin-right: unset;
    margin-left: 1rem;
  }

  ul {
    display: block !important;
    transition: none !important;
    height: initial !important;
  }
}
</style>
