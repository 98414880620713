<template>
  <div id="create-data-page">
    <h2 class="intro-y text-lg font-medium mt-10">
      預約
    </h2>

    <resource-booking-form v-if="checkPermission(permissions, 'site.resource-booking.create')" :site="currentSite"></resource-booking-form>
  </div>
</template>

<script>
export default {
  props: ['auth', 'currentSite', 'permissions'],
  data() {
    return {
      mounted: false
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentSite() {
      this.init()
    },
    permissions() {
      this.init()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite && this.permissions) {
        this.mounted = true
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
