<template>
  <div id="users-page">
    <h2 class="intro-y text-lg font-medium mt-10">
      用戶 (共{{ total }}名)
    </h2>

    <div v-if="checkPermission(permissions, 'system.user.view')">
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap sm:items-end items-center mt-2">
          <router-link to="/management/create-user" v-if="checkPermission(permissions, 'system.user.create')" class="btn btn-primary shadow-md mr-2">新增用戶</router-link>
          <div class="hidden md:block mx-auto text-slate-500">正在顯示第 {{ from }} 至 {{ to }} 名用戶</div>

          <div v-if="!loading" class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
            <div class="w-56 relative text-slate-500">
              <input v-model="search" type="text" class="form-control w-56 box pr-10" placeholder="搜尋" @keyup.enter="loadUsers">
              <font-awesome-icon @click="loadUsers" icon="fa-solid fa-magnifying-glass" class="absolute my-auto w-4 h-4 mr-3 right-0 inset-y-0" />
            </div>
          </div>
          <div v-show="loading" class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0 flex">
            <span><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>
        </div>

        <div v-if="!loading" class="col-span-12 flex flex-wrap sm:flex-nowrap items-center justify-end">
          <div class="flex flex-wrap sm:flex-nowrap justify-end">
            <div class="mr-2 flex-basis-50 sm:flex-basis-0 sm:w-32">
              <label class="form-label text-xs">服務單位</label>
              <select id="centre_id" v-model="centreId" class="form-select mr-2" aria-label="請選擇">
                <option value="">全部</option>
                <option v-for="(item, key) in centers" :key="key" :value="item.centre_id">{{ item.abbrev }}</option>
              </select>
            </div>

            <div class="mr-2 flex-basis-50 sm:flex-basis-0 sm:w-32">
              <label class="form-label text-xs">職位名稱</label>
              <select id="position_id" v-model="positionId" class="form-select mr-2" aria-label="請選擇">
                <option value="">全部</option>
                <option v-for="(item, key) in positions" :key="key" :value="item.position_id">{{ item.name }}</option>
              </select>
            </div>

            <div class="mr-2 flex-basis-50 sm:flex-basis-0 sm:w-32">
              <label class="form-label text-xs">主任級或以上</label>
              <select id="is_high_level" v-model="isHighLevel" class="form-select mr-2" aria-label="請選擇">
                <option value="">全部</option>
                <option :value="true">是</option>
                <option :value="false">否</option>
              </select>
            </div>

            <div v-if="!loading && checkPermission(permissions, 'system.user.delete')" class="mr-2 flex-basis-50 sm:flex-basis-0 sm:w-32">
              <label class="form-label text-xs">狀態</label>
              <select id="is_effective" v-model="isEffective" class="form-select mr-2" aria-label="請選擇">
                <option value="">全部</option>
                <option :value="true">生效</option>
                <option :value="false">無效</option>
              </select>
            </div>
          </div>
        </div>

        <!-- BEGIN: Data List -->
        <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
          <table class="table table-report -mt-2">
            <thead>
            <tr>
              <th class="whitespace-nowrap">頭像</th>
              <th class="whitespace-nowrap"><order-button title="名稱" column="full_name" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="whitespace-nowrap"><order-button title="顯示名稱" column="display_name" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="whitespace-nowrap"><order-button title="職員編號" column="staff_number" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="whitespace-nowrap"><order-button title="服務單位" column="centre" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="whitespace-nowrap"><order-button title="職位名稱" column="position" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="whitespace-nowrap"><order-button title="主任級或以上" column="is_high_level" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="whitespace-nowrap"><order-button title="電郵" column="email" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="text-center whitespace-nowrap">帳戶狀態</th>
              <th class="text-center whitespace-nowrap">檢視</th>
              <th v-if="checkPermission(permissions, 'system.user.edit') || checkPermission(permissions, 'system.user.delete')" class="text-center whitespace-nowrap">動作</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, key) in users" :key="key" class="intro-x">
              <td class="w-0">
                <div class="flex">
                  <div class="w-10 h-10 image-fit">
                    <img alt="" class="tooltip rounded-full" :src="item.avatar_url != null && item.avatar_url !== '' ? item.avatar_url : '/images/user.png'">
                  </div>
                </div>
              </td>
              <td>
                {{ item.full_name ?? '-' }}
              </td>
              <td>
                {{ item.display_name ?? '-' }}
              </td>
              <td>
                {{ item.staff_number }}
              </td>
              <td class="">
                  {{ item.centre !== null ? item.centre.abbrev : '-' }}
                </td>
                <td class="">
                  {{ item.position !== null ? item.position.name : '-' }}
              </td>
              <td class="">
                  {{ item.is_high_level ? '是' : '否' }}
              </td>
              <td class="">{{ item.email }}</td>
              <td class="whitespace-nowrap">
                <div class="flex justify-center align-items-center mx-1">
                  <div class="mr-2 text-warning whitespace-nowrap">
                    <font-awesome-icon icon="fa-regular fa-circle-xmark" />
                    <span class="ml-2">無效</span>
                  </div>
                  <div class="form-switch">
                    <input v-model="item.is_effective" type="checkbox" class="form-check-input" @change="toggleUser(item)" :disabled="!((checkPermission(permissions, 'system.user.delete') && item.is_effective && item.deleted_at == null) || (checkPermission(permissions, 'system.user.edit') && (!item.is_effective || item.deleted_at !== null))) || (profile?.user_id == item.user_id)">
                  </div>
                  <div class="ml-2 text-success whitespace-nowrap">
                    <font-awesome-icon icon="fa-regular fa-square-check" />
                    <span class="ml-2">生效</span>
                  </div>
                </div>
              </td>
              <td class="table-report__action text-center">
                <router-link :to="`/users/${item.user_id}`" class="btn btn-success">
                  <font-awesome-icon icon="fa-regular fa-eye" class="text-white" />
                </router-link>
              </td>
              <td class="table-report__action w-40" v-if="checkPermission(permissions, 'system.user.edit')">
                <div class="flex justify-center items-center">
                  <router-link :to="`/management/update-user/${item.user_id}`" v-if="checkPermission(permissions, 'system.user.edit')" class="flex items-center mr-3">
                    <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                    &nbsp;更改 </router-link>
                  <button class="flex items-center text-danger" v-if="false && checkPermission(permissions, 'system.user.delete') && item.is_effective && item.deleted_at == null" @click="openDeleteModal(item.user_id)">
                    <font-awesome-icon icon="fa-regular fa-trash-can" />
                    &nbsp;刪除 </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- END: Data List -->
        <!-- BEGIN: Pagination -->
        <pagination-row :pages="pages" :current-page.sync="currentPage" :item-per-page.sync="itemPerPage"></pagination-row>
        <!-- END: Pagination -->
      </div>

      <!-- BEGIN: Delete Confirmation Modal -->
      <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="p-5 text-center">
                <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger" />
                <div class="text-3xl mt-5">你確定嗎？</div>
                <div class="text-slate-500 mt-2">
                  你是否要刪除此用戶？
                  <br>
                  這動作不能還原。
                </div>
              </div>
              <div class="px-5 pb-8 text-center">
                <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
                <button type="button" class="btn btn-danger w-24" @click="onDelete">確定</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Delete Confirmation Modal -->
    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'permissions', 'profile'],
  data() {
    return {
      mounted: false,
      loading: 0,

      centers: [],
      positions: [],

      users: [],

      centreId: '',
      positionId: '',
      isHighLevel: '',
      isEffective: true,
      itemPerPage: 50,
      currentPage: 1,
      total: 0,
      pages: 0,
      from: 0,
      to: 0,
      search: '',
      orderBy: '',
      order: '',

      deleteId: null
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    centreId() {
      this.loadUsers()
    },
    positionId() {
      this.loadUsers()
    },
    isHighLevel() {
      this.loadUsers()
    },
    isEffective() {
      this.loadUsers()
    },
    currentPage(val) {
      this.loadUsers(false)
    },
    itemPerPage(val) {
      this.loadUsers()
    },
    search(val) {
      if (val === '') {
        this.loadUsers()
      }
    },
    order() {
      this.loadUsers()
    }
  },
  async mounted() {
    window.tailwind.svgLoader()

    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      this.search = this.$route.query.search ?? ''

      await this.loadUsers()
      await this.loadCenters()
      await this.loadPositions()

      setTimeout(() => {
        this.mounted = true
      }, 500)
    },
    async loadCenters() {
      this.loading++

      this.centers = await this.getCenters()

      this.loading--
    },
    async loadPositions() {
      this.loading++

      this.positions = await this.getPositions()

      this.loading--
    },
    async loadUsers(resetCurrentPage = true) {
      this.loading++

      if (resetCurrentPage) {
        this.currentPage = 1
      }

      const response = await this.getUsers(this.search, this.centreId, this.positionId, this.isHighLevel, this.isEffective, this.itemPerPage, this.currentPage, this.orderBy, this.order)
      this.users = response.data.map((item) => {
        if (item.deleted_at != null) {
          item.is_effective = false
        }
        return item
      })
      this.total = response.meta.page.total
      this.pages = response.meta.page.last_page
      this.from = response.meta.page.from ?? 0
      this.to = response.meta.page.to ?? 0

      this.loading--
    },
    openDeleteModal(id) {
      this.deleteId = id

      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDelete() {
      this.loading++

      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      const response = await this.deleteUser(this.deleteId)

      let option = {
        title: '成功',
        message: '用戶已被刪除',
        type: 'success'
      }

      if (response.code === 0) {
        await this.loadUsers(true)
      } else {
        option = {
          title: '未能刪除用戶',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)

      this.loading--
    },
    async toggleUser(item) {
      this.loading++

      let response

      if (!item.is_effective) {
        response = await this.disableUser(this.$route.params.siteId, item.user_id)
      } else {
        response = await this.enableUser(this.$route.params.siteId, item.user_id)
      }

      let option = {
        title: '成功',
        message: '已設定帳戶狀態',
        type: 'success'
      }

      if (response.code === 0) {
        // await this.loadUsers()
      } else {
        option = {
          title: '未能設定帳戶狀態',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)

      this.loading--
    }
  }
}
</script>

<style lang="scss" scoped></style>
