<template>
  <div id="announcement-types-page">
    <h2 class="intro-y text-lg font-medium mt-10">
      檔案櫃標籤 (共{{ libraryTags.length }}個)
    </h2>

    <div v-if="checkPermission(permissions, 'system.library-tag.view')">
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
          <button v-if="checkPermission(permissions, 'system.library-tag.create')" type="button" class="btn btn-primary shadow-md mr-2" @click="openModal('create')">新增標籤</button>
        </div>

        <!-- BEGIN: Data List -->
        <div class="col-span-12">
          <div class="grid grid-cols-12 gap-5 mt-5">
            <div class="col-span-12 box p-5" v-for="(item, key) in libraryTags" :key="key">
              <div class="flex justify-between align-items-center">
                <div class="font-medium text-base">{{ item.tag_name }}</div>
                <div>
                  <button v-if="checkPermission(permissions, 'system.library-tag.create')" type="button" class="btn btn-secondary py-1 px-2 mr-2" @click="openModal('create', null, item.tag_id )">
                    新增子層標籤
                  </button>
                  <button v-if="checkPermission(permissions, 'system.library-tag.edit')" class="btn btn-outline-pending py-1 px-2 mr-2" @click="openModal('update', item)">修改</button>
                  <button v-if="checkPermission(permissions, 'system.library-tag.delete')" class="btn btn-outline-danger py-1 px-2" @click="openDeleteModal(item.tag_id)">刪除</button>
                </div>
              </div>

              <div class="mt-3 col-span-12 box box-child p-5" v-for="(child, i) in item.children" :key="i">
                <div class="flex justify-between align-items-center">
                  <div class="font-medium text-base">{{ child.tag_name }}</div>
                  <div>
                    <button v-if="checkPermission(permissions, 'system.library-tag.create')" type="button" class="btn btn-secondary py-1 px-2 mr-2" @click="openModal('create', null, child.tag_id)">
                      新增子層標籤
                    </button>
                    <button v-if="checkPermission(permissions, 'system.library-tag.edit')" class="btn btn-outline-pending py-1 px-2 mr-2" @click="openModal('update', child)">修改
                    </button>
                    <button v-if="checkPermission(permissions, 'system.library-tag.delete')" class="btn btn-outline-danger py-1 px-2" @click="openDeleteModal(child.tag_id)">刪除
                    </button>
                  </div>
                </div>

                <div class="mt-3 col-span-12 box box-child p-5" v-for="(grandChild, j) in child.children" :key="j">
                  <div class="flex justify-between align-items-center">
                    <div class="font-medium text-base">{{ grandChild.tag_name }}</div>
                    <div>
                      <button v-if="checkPermission(permissions, 'system.library-tag.edit')" class="btn btn-outline-pending py-1 px-2 mr-2" @click="openModal('update', grandChild)">修改
                      </button>
                      <button v-if="checkPermission(permissions, 'system.library-tag.delete')" class="btn btn-outline-danger py-1 px-2" @click="openDeleteModal(grandChild.tag_id)">刪除
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <!-- END: Data List -->
      </div>
    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>

    <!-- BEGIN: Create Update Modal -->
    <div id="create-update-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">
              {{ mode === 'create' ? '新增標籤' : '更改標籤' }}
            </h2>
          </div>
          <div class="modal-body p-0">
            <div class="p-5">
              <div class="text-slate-500 mt-2">
                <div class="">
                  <label class="form-label"><span class="text-danger">*</span> 標籤名稱</label>
                  <input type="text" v-model="form.tag_name" placeholder="請輸入標籤名稱" class="form-control w-full"/>
                </div>
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消
              </button>
              <button type="button" data-tw-dismiss="modal" class="btn btn-primary w-24 text-white"
                      :disabled="form.tag_name == null || form.tag_name === ''"
                      @click="onSubmit">確定
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Create Folder Modal -->

    <!-- BEGIN: Delete Confirmation Modal -->
    <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger"/>
              <div class="text-3xl mt-5">你確定嗎？</div>
              <div class="text-slate-500 mt-2">
                你是否要刪除此標籤？
                <br>
                這動作不能還原。
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
              <button type="button" class="btn btn-danger w-24" @click="onDelete">確定</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
  </div>
</template>

<script>
export default {
  props: ['auth', 'currentSite', 'permissions'],
  data() {
    return {
      mounted: false,
      libraryTags: [],

      form: {
        parent_id: null,
        tag_name: null
      },

      mode: null,
      deleteId: null
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      await this.loadLibraryTags()

      setTimeout(() => {
        this.mounted = true
      }, 500)
    },
    async loadLibraryTags() {
      this.libraryTags = await this.getLibraryTags()
    },
    openModal(mode, item = null, parentId = null) {
      if (mode === 'create') {
        this.form = {
          parent_id: parentId,
          tag_name: null
        }
      } else {
        this.form = item
      }

      this.mode = mode

      const el = document.querySelector('#create-update-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onSubmit() {
      const el = document.querySelector('#create-update-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      let response = null

      if (this.mode === 'create') {
        response = await this.createLibraryTag(this.form)
      } else {
        response = await this.updateLibraryTag(this.form.tag_id, this.form)
      }

      let option = {
        title: '成功',
        message: (this.mode === 'create') ? '已新增標籤' : '已更改標籤',
        type: 'success'
      }

      if (response.code === 0) {
        this.form.tag_name = null
        this.loadLibraryTags()
      } else {
        option = {
          title: (this.mode === 'create') ? '未能新增標籤' : '未能更改標籤',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    },
    openDeleteModal(id) {
      this.deleteId = id

      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDelete() {
      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      const response = await this.deleteLibraryTag(this.deleteId)

      let option = {
        title: '成功',
        message: '標籤已被刪除',
        type: 'success'
      }

      if (response.code === 0) {
        await this.loadLibraryTags()
      } else {
        option = {
          title: '未能刪除標籤',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    }
  }
}
</script>

<style lang="scss" scoped>
.box-child {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
</style>
