<template>
  <div id="center-page">
    <div class="mt-8">
      <div class="flex justify-end">
        <a @click="$router.back()" class="btn btn-outline-secondary w-24">返回</a>
      </div>

      <div v-if="checkPermission(permissions, 'system.centre.view') && data != null" class="intro-y xl:w-3/5 p-5 box">
        <div class="mt-3">
          <label class="form-label">單位編號</label>
          <div class="w-full">{{ data.cost_centre_code ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">簡稱</label>
          <div class="w-full">{{ data.abbrev ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">英文名稱</label>
          <div class="w-full">{{ data.name_en ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">中文名稱</label>
          <div class="w-full">{{ data.name_zh ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">類別</label>
          <div class="w-full">{{ data.category ? ((data.category.name ?? '') + ' ' + (data.category.name_en ?? '')) : '-' }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">服務</label>
          <div class="w-full">{{ data.service_site ? data.service_site.name : '-' }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">社會服務大樓</label>
          <div class="w-full">{{ data.building_site ? data.building_site.name : '-' }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">區域</label>
          <div class="w-full">{{ data.district_site ? data.district_site.name : '-' }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">主管經理</label>
          <div class="w-full">{{ data.supervision ? data.supervision.name : '-' }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">Funding Mode</label>
          <div class="w-full">{{ data.funding_mode ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">單位負責人職銜</label>
          <div class="w-full">{{ data.head_title ? (getObjectValue(posts, 'key', data.head_title, 'key') + ' ' + getObjectValue(posts, 'value', data.head_title, 'key')) : '-' }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">英文地址</label>
          <div class="w-full">{{ data.address_en ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">中文地址</label>
          <div class="w-full">{{ data.address_zh ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <div class="sm:grid grid-cols-2 gap-2">
            <div>
              <label for="centre_id" class="form-label">電話</label>
              <div class="w-full">{{ data.phone ?? '-' }}</div>
            </div>

            <div>
              <label for="position_id" class="form-label">電話2</label>
              <div class="w-full">{{ data.phone_2 ?? '-' }}</div>
            </div>
          </div>
        </div>

        <div class="mt-3">
          <div class="sm:grid grid-cols-2 gap-2">
            <div>
              <label for="centre_id" class="form-label">電話3</label>
              <div class="w-full">{{ data.phone_3 ?? '-' }}</div>
            </div>

            <div>
              <label for="position_id" class="form-label">傳真</label>
              <div class="w-full">{{ data.fax ?? '-' }}</div>
            </div>
          </div>
        </div>

        <div class="mt-3">
          <label class="form-label">Cost Centre Account</label>
          <div class="w-full">{{ data.cost_centre_user ? data.cost_centre_user.display_name : '-' }}</div>
        </div>

        <div class="mt-3">
          <label class="form-label">備註</label>
          <div class="w-full">{{ data.remark ?? '-' }}</div>
        </div>
      </div>

      <div v-else-if="mounted">
        <div class="alert alert-pending show mt-2" role="alert">沒有此用戶</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'permissions'],
  data() {
    return {
      mounted: false,

      data: null,

      isSystemAdmin: false
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    async permissions(val) {
      this.init()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.permissions) {
        await this.loadData()

        setTimeout(() => {
          this.mounted = true
        }, 500)
      }
    },
    async loadData() {
      this.data = await this.getCenter(this.$route.params.centreId)
    }
  }
}
</script>

<style lang="scss" scoped>
#user-page {
  .leading-relaxed {
    min-height: 400px;
  }

  .profile-image {
    width: 100px;
    height: 100px;
  }

  h3 {
    font-size: 18px;
  }
}
</style>
