<template>
  <div id="performance-report-version-form">
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">

          <div class="mt-3">
            <label for="name" class="form-label">名稱</label>
            <input v-model="form.name" id="name" type="text" class="form-control w-full" placeholder="請輸入名稱">
            <div v-if="'name' in errors" class="text-danger mt-2" v-html="errors.name"></div>
          </div>

          <div class="mt-3">
            <label for="start_at" class="form-label"><span class="text-danger">*</span> 開始日期時間</label>
            <div id="start_at">
              <date-picker v-model="start_at" type="datetime" placeholder="請選擇開始日期時間" input-class="form-control w-full" :disabled-date="disabledStartDate" :disabled-time="disabledStartTime"></date-picker>
            </div>
            <div v-if="'start_at' in errors" class="text-danger mt-2" v-html="errors.start_at"></div>
          </div>

          <div class="mt-3">
            <label for="end_at" class="form-label"><span class="text-danger">*</span> 結束日期時間</label>
            <div id="end_at">
              <date-picker v-model="end_at" type="datetime" placeholder="請選擇結束日期時間" input-class="form-control w-full" :disabled-date="disabledEndDate" :disabled-time="disabledEndTime"></date-picker>
            </div>
            <div v-if="'end_at' in errors" class="text-danger mt-2" v-html="errors.end_at"></div>
          </div>

          <div class="flex justify-end align-items-center mt-5">
            <router-link v-if="!loading && !finish" to="/management/performance-reports" type="button" class="btn btn-outline-secondary w-24 mr-1">取消</router-link>
            <button v-if="!loading && !finish" type="button" class="btn btn-primary w-24" @click="onSave">儲存</button>
            <span v-show="loading && !finish"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data'],
  data() {
    return {
      mode: 'create',
      sites: [],

      start_at: null,
      end_at: null,

      form: {
        name: null,
        start_at: null,
        end_at: null
      },
      errors: {},
      loading: false,
      finish: false
    }
  },
  watch: {
    data(val) {
      if (val != null) {
        this.form = this.data
        this.start_at = this.form.start_at != null ? this.$moment(this.form.start_at).toDate() : null
        this.end_at = this.form.end_at != null ? this.$moment(this.form.end_at).toDate() : null
        this.mode = 'update'
      }
    }
  },
  mounted() {
    window.tailwind.svgLoader()

    if (this.data != null) {
      this.form = this.data
      this.start_at = this.form.start_at != null ? this.$moment(this.form.start_at).toDate() : null
      this.end_at = this.form.end_at != null ? this.$moment(this.form.end_at).toDate() : null
      this.mode = 'update'
    }
  },
  methods: {
    disabledStartDate(date) {
      return this.datepickerDisabledStartDate(date, this.end_at)
    },
    disabledStartTime(date) {
      return this.datepickerDisabledStartTime(date, this.end_at)
    },
    disabledEndDate(date) {
      return this.datepickerDisabledEndtDate(date, this.start_at)
    },
    disabledEndTime(date) {
      return this.datepickerDisabledEndTime(date, this.start_at)
    },
    onSave: async function() {
      this.loading = true
      this.errors = {}

      this.form.start_at = this.start_at != null ? this.$moment(this.start_at).format() : null
      this.form.end_at = this.end_at != null ? this.$moment(this.end_at).format() : null

      let response

      if (this.mode === 'create') {
        response = await this.createPerformanceReportVersion(this.form)
      } else {
        response = await this.updatePerformanceReportVersion(this.data.version_id, this.form)
      }

      if ('errors' in response.data) {
        this.errors = response.data.errors
        this.loading = false
      } else {
        let option = {
          title: (this.mode === 'create') ? '已成功新增版本' : '已成功更新版本',
          message: '請稍後，將會轉至執行報告頁面',
          type: 'success'
        }

        if (response.code === 0) {
          this.finish = true

          setTimeout(() => {
            this.$router.push('/management/performance-reports')
          }, 1000)
        } else {
          this.loading = false
          option = {
            title: (this.mode === 'create') ? '未能新增版本' : '未能更新版本',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
