<template>
  <div id="forgot-password-page" class="login">
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div class="my-auto mx-auto xl:ml-20 bg-white dark:bg-darkmode-600 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-full">
            <div class="tw-logo flex w-full mb-5 px-7 py-3 items-center">
              <img class="flex-1 w-0" src="/images/logo.jpg">
              <div class="tw-logo__title font-bold pl-6">iManage</div>
            </div>
            <h2 class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
              忘記密碼
            </h2>
            <div class="intro-x mt-8">
              <span v-if="sent">重設密碼電郵已發送到以下使用者的電郵地址，如沒有收到，請檢查垃圾郵箱<br><span class="">請勿短時間內重覆發送重置電郵<strong>（請隔5分鐘再發送）</strong></span></span>
              <span v-else-if="user != null">重設密碼電郵將發送到以下使用者的電郵地址<br>如發現電郵有錯誤，請其他同事幫忙進入系統，用<a href="/workflows" target="_blank">「內聯網申請表」</a>更新電郵後再重設密碼</span>
              <span v-else>請輸入使用者名稱，以便發送重設密碼電郵到你的郵箱進行重設密碼</span>
            </div>
            <div class="intro-x mt-2">
              <input v-if="user == null" v-model="username" type="username" class="intro-x login__input form-control py-3 px-4 block" :class="{'border-danger': error != null}" placeholder="使用者名稱">
              <div v-if="user != null && error == null" class="intro-x py-3">{{ user.display_name }} ({{ user.email }})</div>
              <div v-if="error != null" class="text-danger mt-2" v-html="error"></div>
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button v-if="user == null" @click="onSubmit" class="btn btn-pending py-3 px-4 w-full xl:w-32 xl:mr-3 align-top" :disabled="loading > 0">確定</button>
              <button v-else-if="!sent" @click="onSend" class="btn btn-pending py-3 px-4 w-full xl:w-32 xl:mr-3 align-top" :disabled="loading > 0">發送</button>
              <button v-if="user != null && !sent" @click="user = null" class="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top">返回</button>
              <router-link v-else to="/" class="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top">返回</router-link>
            </div>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      username: null,
      user: null,

      sent: false,
      loading: 0,
      display_name: '',
      email: '',

      error: null
    }
  },
  async mounted() {
    // Check if user is logged in
    const accessToken = this.$cookies.get('accessToken')
    if (accessToken != null && accessToken !== '' && accessToken !== '/') {
      this.$router.push('/home')
    }
  },
  methods: {
    async onSubmit() {
      this.loading++

      this.sent = false
      this.error = null

      const response = await this.getForgetPasswordUser(this.username)

      if ((response.body != null && response.body.code !== 0) || response.code !== 0) {
        this.error = response.body.message
      } else {
        this.user = response.data
      }

      this.loading--
    },
    async onSend() {
      this.loading++

      this.sent = false
      this.error = null

      const response = await this.forgetPassword(this.user.username)

      if ((response.body != null && response.body.code !== 0) || response.code !== 0) {
        this.error = response.body.message

        if (response.body != null && response.body.code === 201) {
          this.sent = true
        } else {
          this.username = null
        }
      } else {
        this.sent = true

        this.display_name = response.data.display_name
        this.email = response.data.email

        const option = {
          title: '成功',
          message: '已發送重設密碼電郵',
          type: 'success'
        }

        this.$notify(option)
      }
      this.loading--
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
