import { render, staticRenderFns } from "./LibraryItemPage.vue?vue&type=template&id=27be970e&scoped=true&"
import script from "./LibraryItemPage.vue?vue&type=script&lang=js&"
export * from "./LibraryItemPage.vue?vue&type=script&lang=js&"
import style0 from "./LibraryItemPage.vue?vue&type=style&index=0&id=27be970e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27be970e",
  null
  
)

export default component.exports