<template>
  <div id="forum-post-form">
    <div class="flex items-center border-t border-slate-200/60 dark:border-darkmode-400">
      <el-tiptap v-model="form.message" :extensions="extensions" />
      <button v-show="!loading" @click="onSave" class="w-8 h-8 sm:w-10 sm:h-10 block bg-primary text-white rounded-full flex-none flex items-center justify-center mt-4 mb-10 ml-5 mr-5"><font-awesome-icon icon="fa-regular fa-paper-plane" class="w-4 h-4"/></button>
      <span v-show="loading" class="ml-5 mr-5"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
    </div>
  </div>
</template>

<script>
import {
  // all extensions
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Link,
  Image,
  Blockquote,
  ListItem,
  BulletList, // use with ListItem
  OrderedList, // use with ListItem
  TodoItem,
  TodoList, // use with TodoItem
  TextAlign,
  Indent,
  HorizontalRule,
  HardBreak,
  History,
  // LineHeight,
  // Iframe,
  // CodeBlock,
  // TrailingNode,
  Table, // use with TableHeader, TableCell, TableRow
  TableHeader,
  TableCell,
  TableRow,
  // FormatClear,
  TextColor,
  TextHighlight,
  FontType,
  FontSize,
  // Preview,
  // Print,
  Fullscreen,
  CodeView
  // SelectAll,
} from 'element-tiptap'

import InsertYoutube from '../extension/insertYoutube/index'
import InsertFile from '../extension/insertFile/index'

import codemirror from 'codemirror'
import 'codemirror/lib/codemirror.css' // import base style
import 'codemirror/mode/xml/xml.js' // language
import 'codemirror/addon/selection/active-line.js' // require active-line.js
import 'codemirror/addon/edit/closetag.js' // autoCloseTags

export default {
  props: ['site', 'auth', 'currentSite', 'permissions', 'profile', 'thread', 'replyPost', 'updatePost'],
  data() {
    return {
      mode: 'create',
      types: [],
      form: {
        thread_id: null,
        quote_post_id: 0,
        message: ''
      },
      errors: {},
      loading: false,
      finish: false,

      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({ level: 6 }),
        new Bold({ bubble: true }),
        new Underline({ bubble: true }),
        new Italic({ bubble: true }),
        new Strike({ bubble: true }),
        new Link({ bubble: true }),
        new Image(),
        new InsertFile(this.auth, this.currentSite, this.permissions, this.profile, process.env.VUE_APP_FORUM_POST_FORM_FOLDER_CODE),
        new InsertYoutube(),
        new Blockquote(),
        new TextAlign(),
        new ListItem(),
        new BulletList({ bubble: true }),
        new OrderedList({ bubble: true }),
        new TodoItem(),
        new TodoList(),
        new Indent(),
        new HardBreak(),
        new HorizontalRule({ bubble: true }),
        new Fullscreen(),
        new CodeView({
          codemirror,
          codemirrorOptions: {
            styleActiveLine: true,
            autoCloseTags: true
          }
        }),
        new History(),
        new Table(),
        new TableHeader(),
        new TableCell(),
        new TableRow(),
        new TextColor(),
        new TextHighlight(),
        new FontType({
          fontTypes: {
            Arial: 'Arial',
            'Arial Black': 'Arial Black',
            Impact: 'Impact',
            Tahoma: 'Tahoma',
            'Times New Roman': 'Times New Roman',
            monospace: 'monospace',
            新細明體: '新細明體',
            細明體: '細明體',
            標楷體: '標楷體',
            微軟正黑體: '微軟正黑體',
            微軟雅黑體: '微軟雅黑體'
          }
        }),
        new FontSize()
      ],
      fontList: ['新細明體', '細明體', '標楷體', '微軟正黑體', '微軟雅黑體', 'serif', 'sans-serif', 'monospace', 'Times New Roman', 'Arial', 'Helvetica', 'Calibri']
    }
  },
  watch: {
    thread(val) {
      if (val != null) {
        this.form.thread_id = val.thread_id
      }
    },
    replyPost(val) {
      if (val != null) {
        this.form.quote_post_id = val.post_id
      } else {
        this.form.quote_post_id = 0
      }
    },
    updatePost(val) {
      if (val != null) {
        this.form.message = val.message
      } else {
        this.form.message = ''
      }
    }
  },
  created() {
  },
  mounted() {
    window.tailwind.svgLoader()
  },
  methods: {
    async onSave() {
      if (this.form.message == null || this.form.message === '') {
        return
      }

      this.loading = true

      const mode = (this.updatePost == null) ? 'create' : 'update'

      let response

      if (mode === 'create') {
        response = await this.createSiteForumPost(this.currentSite.site_id, this.form)
      } else {
        response = await this.updateSiteForumPost(this.currentSite.site_id, this.updatePost.post_id, this.form)
      }

      if ('errors' in response.data) {
        this.errors = response.data.errors
      } else {
        if (response.code === 0) {
          this.form.quote_post_id = 0
          this.form.message = ''

          this.$parent.clearReply()
          this.$parent.clearUpdate()

          this.$parent.loadPosts(mode === 'create')
        } else {
          const option = {
            title: (mode === 'create') ? '未能新增回覆' : '未能更新回覆',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }

          this.$notify(option)
        }
      }
      this.loading = false
    }
  }
}
</script>

<style scoped lang="scss">
#announcement-form {
  ::v-deep {
    .ql-editor {
      height: 100%;
      flex: 1;
      overflow-y: auto;
      width: 100%;
    }
  }
}
</style>
