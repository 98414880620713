<template>
  <div id="create-data-page">
    <h2 class="intro-y text-lg font-medium mt-10">
      建立分站
    </h2>

    <site-form v-if="checkPermission(permissions, 'system.site.create')"></site-form>
    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'permissions'],
  data() {
    return {
      mounted: false
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    permissions() {
      this.init()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.permissions) {
        setTimeout(() => {
          this.mounted = true
        }, 500)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
