<template>
  <div id="essays-page">
    <h2 class="intro-y text-lg font-medium mt-10">
      {{ isManager === '1' ? '社服隨筆' : '新中心/新計劃服務巡禮'}}
    </h2>

    <div v-if="checkPermission(permissions, 'site.announcement.view')">
      <div class="box grid grid-cols-12 gap-6 mt-5 p-5">

        <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap justify-between items-center mt-2">
          <router-link v-if="checkPermission(permissions, 'site.announcement.create')" :to="`/create-social-essay?isManager=${isManager}`" class="btn btn-primary whitespace-nowrap shadow-md mr-2 mb-1 sm:mb-0">新增項目</router-link>
          <div v-if="!loading" class="w-full lg:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0 flex flex-wrap sm:flex-nowrap">

            <select v-if="checkPermission(permissions, 'site.announcement.edit')" id="status" v-model="currentStatus" class="form-select mr-2 flex-basis-50 sm:flex-basis-auto sm:w-32" aria-label="請選擇狀態">
              <option v-for="(item, key) in allStatus" :key="key" :value="item.value">{{ item.name }}</option>
            </select>

            <select id="year" v-model="year" class="form-select mr-2 flex-basis-50 sm:flex-basis-auto sm:w-32" aria-label="請選擇年份">
              <option value="">--- 請選擇 ---</option>
              <option v-for="key in 10" :key="key" :value="$moment().year() - key + 1">{{ $moment().year() - key + 1 }}</option>
            </select>

            <select id="is_manager" v-model=isManager class="form-select mr-2 flex-basis-50 sm:flex-basis-auto sm:w-32" aria-label="請選擇">
              <option value="1">社服隨筆</option>
              <option value="0">新中心/新計劃服務巡禮</option>
            </select>

            <div class="flex-basis-50 sm:flex-basis-auto flex-1 mr-2 sm:w-64">
              <div class="relative text-slate-500">
                <input v-model="search" type="text" class="form-control w-full pr-10" placeholder="搜尋">
                <font-awesome-icon icon="fa-solid fa-magnifying-glass" class="absolute my-auto w-4 h-4 mr-3 right-0 inset-y-0" />
              </div>
            </div>
          </div>
          <div v-show="loading" class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0 flex">
            <span><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>
        </div>

        <!-- BEGIN: Data List -->
        <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="whitespace-nowrap"><order-button title="新增時間" column="created_at" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="whitespace-nowrap"><order-button title="標題" column="title" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="whitespace-nowrap"><order-button title="公開時間" column="display_at" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="whitespace-nowrap"><order-button title="結束時間" column="end_at" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="text-center whitespace-nowrap">檢視</th>
              <th v-if="checkPermission(permissions, 'site.announcement.edit') || checkPermission(permissions, 'site.announcement.delete')" class="text-center whitespace-nowrap">動作</th>
            </tr>
            </thead>
            <tbody>
            <tr class="" v-for="(item, key) in essays" :key="key">
              <td>{{ $moment(item.post_date).format('YYYY-MM-DD') }}</td>
              <td><font-awesome-icon icon="fa-solid fa-thumbtack" class="mr-1" v-if="item.is_pinned"/> {{ item.title }}</td>
              <td>{{ item.display_at != null ? $moment(item.display_at).format('YYYY-MM-DD HH:mm:ss') : '-' }}</td>
              <td>{{ item.end_at != null ? $moment(item.end_at).format('YYYY-MM-DD HH:mm:ss') : '-' }}</td>
              <td class="table-report__action text-center">
                <router-link :to="`/social-essays/${item.essay_id}`" class="btn btn-success">
                  <font-awesome-icon icon="fa-regular fa-eye" class="text-white" />
                </router-link>
              </td>
              <td v-if="checkPermission(permissions, 'site.announcement.edit') || checkPermission(permissions, 'site.announcement.delete')" class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <router-link v-if="checkPermission(permissions, 'site.announcement.edit')" :to="`/update-social-essay/${item.essay_id}`" class="flex items-center mr-3">
                    <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                    &nbsp;更改 </router-link>
                  <button v-if="checkPermission(permissions, 'site.announcement.delete')" class="flex items-center text-danger" @click="openDeleteModal(item.essay_id)">
                    <font-awesome-icon icon="fa-regular fa-trash-can" />
                    &nbsp;刪除 </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>

          <div class="hidden md:block mx-auto text-slate-500 text-center mt-5">正在顯示第 {{ essays.length !== 0 ? from : 0 }} 至 {{ to ?? 0 }} 個社服隨筆</div>
        </div>
        <!-- END: Data List -->

        <!-- BEGIN: Pagination -->
        <pagination-row :pages="pages" :current-page.sync="currentPage" :item-per-page.sync="itemPerPage"></pagination-row>
        <!-- END: Pagination -->

        <!-- BEGIN: Delete Confirmation Modal -->
        <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body p-0">
                <div class="p-5 text-center">
                  <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger" />
                  <div class="text-3xl mt-5">你確定嗎？</div>
                  <div class="text-slate-500 mt-2">
                    你是否要刪除此項目？
                    <br>
                    這動作不能還原。
                  </div>
                </div>
                <div class="px-5 pb-8 text-center">
                  <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
                  <button type="button" class="btn btn-danger w-24" @click="onDelete">確定</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Delete Confirmation Modal -->
      </div>
    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'currentSite', 'permissions'],
  data() {
    return {
      mounted: false,
      loading: 0,
      essayTypes: [],
      currentStatus: 'PUBLISHED',

      essays: [],

      itemPerPage: 50,
      currentPage: 1,
      pages: 0,
      from: 0,
      to: 0,
      year: '',
      search: '',
      orderBy: '',
      order: '',
      isManager: 0,

      allStatus: [
        { name: '全部', value: 'ALL' },
        { name: '公開', value: 'PUBLISHED' },
        { name: '草稿', value: 'DRAFT' }
      ],

      deleteId: null
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentSite() {
      this.init()
    },
    year(val) {
      this.loadEssays()
    },
    currentPage(val) {
      this.loadEssays(false)
    },
    currentStatus(val) {
      this.loadEssays()
    },
    itemPerPage(val) {
      this.loadEssays()
    },
    order() {
      this.loadEssays()
    },
    search(val) {
      this.loadEssays()
    },
    isManager(val) {
      if (this.$router.currentRoute.fullPath !== `/social-essays?isManager=${this.isManager}`) {
        this.$router.replace({ path: '/social-essays', query: { isManager: this.isManager } })
      }

      this.loadEssays()
    }
  },
  async mounted() {
    window.tailwind.svgLoader()

    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        this.isManager = this.$route.query.isManager ?? '0'
        await this.loadEssays()
        this.mounted = true
      }
    },
    async loadEssays(resetCurrentPage = true) {
      this.loading++

      const response = await this.getSiteEssays(this.currentSite.site_id, this.search, this.year, this.itemPerPage, this.currentPage, this.currentStatus, this.orderBy, this.order, this.isManager)

      if (response.code === 0) {
        this.essays = response.data
        this.pages = response.meta.page.last_page
        this.from = response.meta.page.from ?? 0
        this.to = response.meta.page.to ?? 0
      }

      if (resetCurrentPage) {
        this.currentPage = 1
      }

      this.loading--
    },
    openDeleteModal(id) {
      this.deleteId = id

      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDelete() {
      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      const response = await this.deleteSiteEssay(this.currentSite.site_id, this.deleteId)

      let option = {
        title: '成功',
        message: '項目已被刪除',
        type: 'success'
      }

      if (response.code === 0) {
        this.loadEssays()
      } else {
        option = {
          title: '未能刪除項目',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    }
  }
}
</script>

<style lang="scss" scoped>
#essays-page {
  .tab-content {
    background-color: #FFF;

    table {
      thead {
        th:first-child, th:nth-child(3) {
          width: 120px;
        }
      }
    }
  }
}
</style>
