<template>
  <div id="banner-form">
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">

          <div class="mt-3">
            <label for="title" class="form-label"><span class="text-danger">*</span> 標題</label>
            <input v-model="form.title" id="title" type="text" class="form-control w-full" placeholder="請輸入標題">
            <div v-if="'title' in errors" class="text-danger mt-2" v-html="errors.title"></div>
          </div>

          <div class="mt-3">
            <label for="url" class="form-label">網址</label>
            <input v-model="form.url" id="url" type="text" class="form-control w-full" placeholder="請輸入網址">
            <div v-if="'url' in errors" class="text-danger mt-2" v-html="errors.description"></div>
          </div>

          <div class="mt-3">
            <label for="image" class="form-label"><span class="text-danger">*</span> 圖片</label><br>
            <img v-if="form.image != null && form.image !== ''" :src="form.image" class="mb-2" width="50%"/>
            <div class="mb-2">圖片尺寸: {{ width }}(闊) x {{ height }}(高) 像素</div>
            <button type="button" class="btn btn-outline-secondary" @click="openFileSelect">{{ form.image ? '變更檔案' : '選擇檔案' }}</button>
            <button v-if="form.image != null" type="button" class="btn btn-outline-danger ml-2" @click="onRemoveFile">取消選擇</button>
            <div v-if="'image' in errors" class="text-danger mt-2" v-html="errors.image"></div>
          </div>

          <div class="mt-3">
            <label for="display_order" class="form-label">顯示次序</label>
            <input v-model="form.display_order" id="display_order" type="number" class="form-control w-full" placeholder="請輸入顯示次序">
            <div v-if="'display_order' in errors" class="text-danger mt-2" v-html="errors.display_order"></div>
          </div>

          <div class="flex justify-end align-items-center mt-5">
            <router-link v-if="!loading && !finish" :to="`/management/banners`" type="button" class="btn btn-outline-secondary w-24 mr-1">取消</router-link>
            <button v-if="!loading && !finish" type="button" class="btn btn-primary w-24" @click="onSave">儲存</button>
            <span v-show="loading && !finish"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>

    <file-insertor :auth="auth" :current-site="currentSite" :permissions="permissions" :modal-filter="['image/']" ref="fileInsertor" @on-select="onSelectFile"></file-insertor>
  </div>
</template>

<script>
export default {
  props: ['auth', 'currentSite', 'permissions', 'data'],
  data() {
    return {
      mode: 'create',
      form: {
        title: null,
        url: null,
        image: null,
        display_order: 0
      },
      width: 1455,
      height: 450,
      errors: {},
      loading: false,
      finish: false
    }
  },
  watch: {
    data(val) {
      if (val != null) {
        this.form = this.data
        this.mode = 'update'
      }
    },
    'form.image'(val) {
      if (val != null && val !== '') {
        const img = new Image()
        img.src = val
        img.onload = () => {
          // this.width = img.width
          // this.height = img.height
        }
      }
    }
  },
  mounted() {
    window.tailwind.svgLoader()
  },
  methods: {
    openFileSelect() {
      this.$refs.fileInsertor.onShow()
    },
    async onSelectFile(file) {
      if (file.file.format.mime_type.startsWith('image/')) {
        const response = await this.getLibraryItem(this.currentSite.site_id, file.item_code)
        this.form.image = response.data.public_url
      } else {
        const option = {
          title: '錯誤',
          message: '請選擇圖片檔案',
          type: 'error'
        }

        this.$notify(option)
      }
    },
    onRemoveFile() {
      this.form.image = null
      this.$forceUpdate()
    },
    async onSave() {
      this.loading = true
      this.errors = {}

      let response

      if (this.mode === 'create') {
        response = await this.createSiteBanner(this.currentSite.site_id, this.form)
      } else {
        response = await this.updateSiteBanner(this.currentSite.site_id, this.data.banner_id, this.form)
      }

      if ('errors' in response.data) {
        this.errors = response.data.errors
        this.loading = false
      } else {
        let option = {
          title: (this.mode === 'create') ? '已成功新增橫額' : '已成功更新橫額',
          message: '請稍後，將會轉至橫額頁面',
          type: 'success'
        }

        if (response.code === 0) {
          this.finish = true

          setTimeout(() => {
            this.$router.push('/management/banners')
          }, 1000)
        } else {
          this.loading = false
          option = {
            title: (this.mode === 'create') ? '未能新增橫額' : '未能更新橫額',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
