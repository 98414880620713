<template>
  <div id="calendar-form">
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">

          <div class="mt-3">
            <label for="name" class="form-label"><span class="text-danger">*</span> 名稱</label>
            <input v-model="form.name" id="name" type="text" class="form-control w-full" placeholder="請輸入名稱" :disabled="this.mode == 'update' && !checkPermission(permissions, 'site.calendar.edit')">
            <div v-if="'name' in errors" class="text-danger mt-2" v-html="errors.name"></div>
          </div>

          <div class="mt-3">
            <label for="color" class="form-label"><span class="text-danger">*</span> 顏色</label><br>
            <input v-model="form.color" id="color" type="color" class="form-control" placeholder="請選擇顏色" :disabled="this.mode == 'update' && !checkPermission(permissions, 'site.calendar.edit')">
            <div v-if="'color' in errors" class="text-danger mt-2" v-html="errors.color"></div>
          </div>

          <div class="flex justify-between align-items-center mt-5">
            <div>
            <button v-if="!loading && !finish && mode === 'update' && checkPermission(permissions, 'site.calendar.delete')" type="button" class="btn btn-danger w-24" @click="openDeleteModal">刪除</button>
            </div>

            <div>
              <router-link v-if="!loading && !finish" to="/calendar" type="button" class="btn btn-outline-secondary w-24 mr-1">取消</router-link>
              <button v-if="!loading && !finish && (mode === 'create' || (mode === 'update' && checkPermission(permissions, 'site.calendar.edit')))" type="button" class="btn btn-primary w-24" @click="onSave">儲存</button>
            </div>

            <span v-show="loading && !finish"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>
        </div>
        <!-- END: Form Layout -->

        <!-- BEGIN: Delete Confirmation Modal -->
        <div id="delete-calendar-modal" class="modal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body p-0">
                <div class="p-5 text-center">
                  <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger" />
                  <div class="text-3xl mt-5">你確定嗎？</div>
                  <div class="text-slate-500 mt-2">
                    你是否要刪除此日曆？
                    <br>
                    這動作不能還原。
                  </div>
                </div>
                <div class="px-5 pb-8 text-center">
                  <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
                  <button type="button" class="btn btn-danger w-24" @click="onDelete">確定</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Delete Confirmation Modal -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['site', 'data', 'permissions'],
  data() {
    return {
      mode: 'create',
      form: {
        name: null,
        color: '#264d60'
      },
      errors: {},
      loading: false,
      finish: false
    }
  },
  watch: {
    data(val) {
      if (val != null) {
        this.form = this.data
        this.mode = 'update'
      }
    }
  },
  mounted() {
    window.tailwind.svgLoader()
  },
  methods: {
    onSave: async function() {
      this.loading = true
      this.errors = {}

      let response

      if (this.mode === 'create') {
        response = await this.createSiteCalendar(this.site.site_id, this.form)
      } else {
        response = await this.updateSiteCalendar(this.site.site_id, this.data.calendar_id, this.form)
      }

      if ('errors' in response.data) {
        this.errors = response.data.errors
        this.loading = false
      } else {
        let option = {
          title: (this.mode === 'create') ? '已成功新增日曆' : '已成功更新日曆',
          message: '請稍後，將會轉至日曆頁面',
          type: 'success'
        }

        if (response.code === 0) {
          this.finish = true

          setTimeout(() => {
            this.$router.push('/calendar')
          }, 1000)
        } else {
          this.loading = false
          option = {
            title: (this.mode === 'create') ? '未能新增日曆' : '未能更新日曆',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)
      }
    },
    openDeleteModal() {
      const el = document.querySelector('#delete-calendar-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDelete() {
      const el = document.querySelector('#delete-calendar-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      this.loading = true

      const response = await this.deleteSiteCalendar(this.site.site_id, this.data.calendar_id)

      let option = {
        title: '成功',
        message: '日曆已被刪除',
        type: 'success'
      }

      if (response.code === 0) {
        this.finish = true

        setTimeout(() => {
          this.$router.push('/calendar')
        }, 1000)
      } else {
        this.loading = false
        option = {
          title: '未能刪除日曆',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    }
  }
}
</script>

<style scoped lang="scss">
  input[type=color] {
    width: 50px;
  }
</style>
