<template>
  <div id="thread-page" class="chat">

    <div v-if="checkPermission(permissions, 'site.forum.view')" class="mt-8">
      <!-- BEGIN: Chat Content -->
      <div class="intro-y col-span-12 lg:col-span-8 2xl:col-span-9">
        <div class="chat__box box">
          <!-- BEGIN: Chat Active -->
          <div class="h-full flex flex-col">
            <div class="flex flex-col sm:flex-row border-b border-slate-200/60 dark:border-darkmode-400 px-5 py-4">

              <div class="mr-auto" v-if="thread != null">
                <div class="font-medium text-base">{{ thread.title }}</div>
                <div class="html-content" v-html="thread.content"></div>
                <div class="flex align-items-center mt-3">
                  <div class="w-8 h-8 flex-none image-fit relative">
                    <img alt="" class="rounded-full" :src="thread.author?.avatar_url != null && thread.author.avatar_url !== '' ? thread.author.avatar_url : '/images/user.png'">
                  </div>
                  <div class="ml-2 text-slate-500 text-xs sm:text-sm">{{ thread.author?.display_name }} <span class="mx-1">•</span> {{ thread.category?.title }}
                    <span class="mx-1">•</span> {{ $moment(thread.updated_at).format('YYYY-MM-DD HH:mm') }}
                  </div>
                </div>
              </div>

              <div class="flex items-center sm:ml-auto mt-5 sm:mt-0 border-t sm:border-0 border-slate-200/60 pt-3 sm:pt-0 -mx-5 sm:mx-0 px-5 sm:px-0">
                <router-link to="/forum" type="button" class="btn btn-outline-secondary w-24">返回</router-link>
              </div>
            </div>

            <div class="flex-1 h-0 relative">
              <button v-if="showScrollToDown" type="button" @click="scrollToBottom()" class="w-8 h-8 sm:w-12 sm:h-12 block border border-primary bg-white text-primary opacity-3/4 rounded-full flex-none flex items-center justify-center mb-3 mx-auto absolute bottom-0 left-0 right-0 z-50">
                <font-awesome-icon icon="fa-solid fa-arrow-down" class="w-4 h-4"/>
              </button>
              <div class="overflow-y-scroll scrollbar-hidden px-5 pt-5 h-full" id="messages" @scroll="onScroll">
                <div v-for="(item, key) in posts" :key="key">
                  <div class="text-slate-400 dark:text-slate-500 text-xs text-center mb-10 mt-5" v-if="key === 0 || (key > 0 && isDateDifferent(item.created_at, posts[key - 1].created_at))">{{ $moment(item.created_at).format('YYYY-MM-DD') }}</div>

                  <div v-if="profile.user_id !== item.user_id" class="chat__box__text-box flex items-end float-left mb-4 -intro-x" :id="`post-${item.post_id}`">
                    <div class="w-10 h-10 hidden sm:block flex-none image-fit relative mr-5">
                      <img alt="" class="rounded-full" :src="item.author?.avatar_url != null && item.author.avatar_url !== '' ? item.author.avatar_url : '/images/user.png'">
                    </div>
                    <div class="bg-slate-100 dark:bg-darkmode-400 px-4 py-3 text-slate-500 rounded-r-md rounded-t-md">
                      <div class="font-bold mb-2">{{ item.author?.display_name }}</div>

                      <button type="button" @click="scrollTo(item.quote_post_id)" v-if="item.quote_post_id != null && item.quote_post_id !== 0 && getObjectValue(posts, 'post_id', item.quote_post_id) != null" class="other-message-reply-container ml-1 pl-1 mb-2 text-left">
                        <div class="font-bold">
                          {{ getObjectValue(posts, 'post_id', item.quote_post_id).author?.display_name }}
                        </div>
                        <div class="message-reply" v-html="getObjectValue(posts, 'post_id', item.quote_post_id).message"></div>
                      </button>

                      <div class="message pre-line" v-html="(item.message)"></div>

                      <div class="mt-1 text-xs text-slate-500">{{ $moment(item.created_at).format('HH:mm:ss') }}</div>
                    </div>
                    <div v-if="checkPermission(permissions, ['site.forum-post.create', 'site.forum-post.edit', 'site.forum-post.delete'])" class="hidden sm:block dropdown ml-3 my-auto">
                      <button type="button" class="dropdown-toggle w-4 h-4 text-slate-500" aria-expanded="false" data-tw-toggle="dropdown"> <font-awesome-icon icon="fa-solid fa-ellipsis-vertical" class="w-4 h-4" /> </button>
                      <div class="dropdown-menu w-40">
                        <ul class="dropdown-content">
                          <li v-if="checkPermission(permissions, 'site.forum-post.create')">
                            <button type="button" class="w-full dropdown-item" @click="onReply(item, key)"> <font-awesome-icon icon="fa-solid fa-reply"  class="w-4 h-4 mr-2"/> 回覆 </button>
                          </li>
                          <li v-if="checkPermission(permissions, 'site.forum-post.edit')">
                            <button type="button" class="w-full dropdown-item" @click="onUpdate(item, key)"> <font-awesome-icon icon="fa-solid fa-pen-to-square"  class="w-4 h-4 mr-2"/> 修改 </button>
                          </li>
                          <li v-if="checkPermission(permissions, 'site.forum-post.delete')">
                            <button type="button" class="w-full dropdown-item text-danger" @click="openDeletePostModal(item.post_id, key)"> <font-awesome-icon icon="fa-solid fa-trash" class="w-4 h-4 mr-2" /> 刪除 </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div v-else class="chat__box__text-box flex items-end float-right mb-4 intro-x" :id="`post-${item.post_id}`">
                    <div class="hidden sm:block dropdown mr-3 my-auto">
                      <button type="button" class="dropdown-toggle w-4 h-4 text-slate-500" aria-expanded="false" data-tw-toggle="dropdown"> <font-awesome-icon icon="fa-solid fa-ellipsis-vertical" class="w-4 h-4" /> </button>
                      <div class="dropdown-menu w-40">
                        <ul class="dropdown-content">
                          <li v-if="checkPermission(permissions, 'site.forum-post.create')">
                            <button type="button" class="w-full dropdown-item" @click="onReply(item, key)"> <font-awesome-icon icon="fa-solid fa-reply"  class="w-4 h-4 mr-2"/> 回覆 </button>
                          </li>
                          <li>
                            <button type="button" class="w-full dropdown-item" @click="onUpdate(item, key)"> <font-awesome-icon icon="fa-solid fa-pen-to-square"  class="w-4 h-4 mr-2"/> 修改 </button>
                          </li>
                          <li>
                            <button type="button" class="w-full dropdown-item text-danger" @click="openDeletePostModal(item.post_id, key)"> <font-awesome-icon icon="fa-solid fa-trash" class="w-4 h-4 mr-2" /> 刪除 </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="bg-primary px-4 py-3 text-white rounded-l-md rounded-t-md">
                      <button type="button" @click="scrollTo(item.quote_post_id)" v-if="item.quote_post_id != null && item.quote_post_id !== 0 && getObjectValue(posts, 'post_id', item.quote_post_id) != null" class="self-message-reply-container ml-1 pl-1 mb-2 text-left">
                        <div class="font-bold">
                          {{ getObjectValue(posts, 'post_id', item.quote_post_id).author?.display_name }}
                        </div>
                        <div class="message-reply" v-html="getObjectValue(posts, 'post_id', item.quote_post_id).message"></div>
                      </button>
                      <div class="message pre-line" v-html="(item.message)">
                      </div>

                      <div class="mt-1 text-xs text-white text-opacity-80">{{ $moment(item.created_at).format('HH:mm:ss') }}</div>
                    </div>
                    <div class="w-10 h-10 hidden sm:block flex-none image-fit relative ml-5">
                      <img alt="" class="rounded-full" :src="item.author?.avatar_url != null && item.author.avatar_url !== '' ? item.author.avatar_url : '/images/user.png'">
                    </div>
                  </div>

                  <div class="clear-both"></div>
                </div>
              </div>
            </div>

            <div class="reply-container flex align-items-center border-t border-slate-200/60" v-if="posts.length > 0 && replyPost != null">
              <div class="p-5"><font-awesome-icon icon="fa-solid fa-reply" class="fa-2x"/></div>
              <div class="flex-1 p-2 flex flex-column justify-center reply">
                <div class="font-bold mb-2">回覆{{ replyPost.author?.display_name }}</div>
                <div class="reply-message" v-html="replyPost.message"></div>
              </div>
              <button class="p-5 flex items-center justify-center" @click="clearReply"><font-awesome-icon icon="fa-solid fa-xmark" class="fa-2x" /></button>
            </div>

            <div class="reply-container flex align-items-center border-t border-slate-200/60" v-if="posts.length > 0 && updatePost != null">
              <div class="p-5"><font-awesome-icon icon="fa-solid fa-pen" class="fa-2x"/></div>
              <div class="flex-1 p-2 flex flex-column justify-center reply">
                <div class="font-bold mb-2">修改回覆</div>
                <div class="reply-message" v-html="updatePost.message"></div>
              </div>
              <button class="p-5 flex items-center justify-center" @click="clearUpdate"><font-awesome-icon icon="fa-solid fa-xmark" class="fa-2x" /></button>
            </div>

            <forum-post-form v-show="checkPermission(permissions, 'site.forum-post.create') || updatePost != null" :thread="thread" :replyPost="replyPost" :updatePost="updatePost" :site="currentSite" :auth="auth" :current-site="currentSite" :permissions="permissions" :profile="profile"></forum-post-form>

          </div>
          <!-- END: Chat Active -->
        </div>
      </div>
      <!-- END: Chat Content -->

      <!-- BEGIN: Delete Confirmation Modal -->
      <div id="delete-post-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="p-5 text-center">
                <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger"/>
                <div class="text-3xl mt-5">你確定嗎？</div>
                <div class="text-slate-500 mt-2">
                  你是否要刪除此回覆？
                  <br>
                  這動作不能還原。
                </div>
              </div>
              <div class="px-5 pb-8 text-center">
                <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
                <button type="button" class="btn btn-danger w-24" @click="onDeletePost">確定</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Delete Confirmation Modal -->
    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['site', 'auth', 'currentSite', 'permissions', 'profile'],
  data() {
    return {
      mounted: false,
      users: [],
      thread: null,

      posts: [],

      itemPerPage: 0,
      currentPage: 1,
      pages: 0,
      from: 0,
      to: 0,

      deleteId: null,
      deleteKey: null,
      replyPost: null,
      updatePost: null,

      loading: false,
      showScrollToDown: false
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentSite() {
      this.init()
    },
    permissions() {
      this.init()
    },
    currentPage(val) {
      this.loadThreads(false)
    },
    itemPerPage(val) {
      this.loadThreads()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.auth && this.currentSite && this.permissions) {
        await this.loadForumThread()
        await this.loadPosts(true)

        this.mounted = true
      }
    },
    async loadForumThread() {
      this.thread = await this.getSiteForumThread(this.currentSite.site_id, this.$route.params.threadId)
    },
    async loadPosts(scrollToBottom = false, resetCurrentPage = true) {
      const response = await this.getSiteForumPosts(this.currentSite.site_id, this.$route.params.threadId, this.itemPerPage, this.currentPage)
      this.posts = response.data
      // this.pages = response.meta.page.last_page
      // this.from = response.meta.page.from ?? 0
      // this.to = response.meta.page.to ?? 0

      if (resetCurrentPage) {
        this.currentPage = 1
      }
      if (scrollToBottom) {
        this.scrollToBottom()
      }
    },
    onReply(post, key) {
      this.clearReply()
      this.clearUpdate()

      // Hide menu
      const el = document.querySelector('.dropdown-content')
      const dropdown = window.tailwind.Dropdown.getOrCreateInstance(el)
      dropdown.hide()

      this.replyPost = post

      if (key === this.posts.length - 1) {
        this.scrollToBottom()
      }
    },
    clearReply() {
      this.replyPost = null
    },
    onUpdate(post, key) {
      this.clearReply()
      this.clearUpdate()

      // Hide menu
      const el = document.querySelector('.dropdown-content')
      const dropdown = window.tailwind.Dropdown.getOrCreateInstance(el)
      dropdown.hide()

      this.updatePost = post

      if (key === this.posts.length - 1) {
        this.scrollToBottom()
      }
    },
    clearUpdate() {
      this.updatePost = null
    },
    openDeletePostModal(id, key) {
      this.deleteId = id
      this.deleteKey = key

      const el = document.querySelector('#delete-post-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDeletePost() {
      const el = document.querySelector('#delete-post-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      const response = await this.deleteSiteForumPost(this.currentSite.site_id, this.deleteId)

      let option = {
        title: '成功',
        message: '回覆已被刪除',
        type: 'success'
      }

      if (response.code === 0) {
        // this.loadThreads()

        if (this.replyPost != null && this.replyPost.post_id === this.deleteId) {
          this.clearReply()
        }
        if (this.updatePost != null && this.updatePost.post_id === this.deleteId) {
          this.clearUpdate()
        }
        // this.$delete(this.posts, this.deleteKey)
        this.loadPosts()
      } else {
        option = {
          title: '未能刪除回覆',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    },
    scrollTo(postId) {
      document.getElementById(`post-${postId}`).scrollIntoView({
        behavior: 'smooth'
      })
    },
    scrollToBottom(animation = 'smooth') {
      // Scroll to bottom
      setTimeout(() => {
        const lastElement = document.getElementById('messages').lastElementChild
        if (lastElement != null) {
          lastElement.scrollIntoView({ behavior: animation })
        }
      }, 100)
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= (scrollHeight - 5)) {
        this.showScrollToDown = false
      } else {
        this.showScrollToDown = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#thread-page {
  .thread-title {
    font-size: 20px;
  }

  #messages {
    overflow-x: hidden;
  }

  .reply-container {
    background-color: rgb(var(--color-slate-200) / var(--tw-bg-opacity));

    .reply {
      height: 100%;
      border-left: 3px solid rgb(var(--color-primary) / var(--tw-bg-opacity));

      .reply-message {
        width: 100%;
        height: 20px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }

  .other-message-reply-container {
    border-left: 3px solid rgb(var(--color-slate-800) / var(--tw-text-opacity));
    font-size: 0.8rem;
  }

  .self-message-reply-container {
    border-left: 3px solid rgb(var(--color-light) / var(--tw-bg-opacity));
    font-size: 0.8rem;
  }

  .message-reply {
    height: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .pre-line {
    max-width: 300px;
    word-wrap: break-word;
  }
}
</style>
<style lang="scss">
#thread-page {
  .message, .reply-message, .message-reply {
    table {
      width: 100%;
    }
    table, th, td {
      border: 1px solid black;
      border-collapse: collapse;
    }
  }
  .bg-primary {
    .message, .reply-message, .message-reply {
      table, th, td {
        border-color: #fff;
      }
    }
  }
}
</style>
