<template>
  <div id="management-page">
    <h2 class="intro-y text-lg font-medium mt-10">
      系統管理
    </h2>

    <div v-if="checkPermission(permissions, 'site.manage')" class="grid grid-cols-12 gap-6 mt-5">
      <!-- BEGIN: Menu -->
      <router-link v-for="(item, key) in filteredMenu" :key="key" :to="`management/${item.url}`" class="intro-y col-span-12 lg:col-span-3 box border-2 block py-10 text-center">
        <font-awesome-icon :icon="item.icon" class="fa-3x text-primary" />
        <div class="font-medium text-base mt-3">{{ item.name }}</div>
      </router-link>
      <!-- END: Menu -->
    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'permissions', 'currentSite'],
  data() {
    return {
      mounted: false,

      menu: [
        { name: '用戶管理', url: 'users', icon: 'fa-solid fa-users', permission: ['system.user.view'], subSite: false },
        { name: '組別管理', url: 'user-groups', icon: 'fa-solid fa-users-rectangle', permission: ['site.user-group.view'], subSite: true },
        { name: '內聯網分站管理', url: 'sites', icon: 'fa-solid fa-network-wired', permission: ['system.site.view'], subSite: false },
        { name: '系統參數設定', url: 'config', icon: 'fa-solid fa-border-all', permission: ['system.config.view'], subSite: false },
        { name: '橫額', url: 'banners', icon: 'fa-regular fa-images', permission: ['site.banner.view'], subSite: true },
        { name: '報告板類別', url: 'announcement-types', icon: 'fa-solid fa-rss', permission: ['site.announcement-type.view'], subSite: true },
        { name: '部/組/服務單位類別設定', url: 'center-categories', icon: 'fa-solid fa-users-between-lines', permission: ['system.centre-category.view'], subSite: false },
        { name: '部/組/服務單位設定', url: 'centers', icon: 'fa-solid fa-people-group', permission: ['system.centre.view'], subSite: false },
        { name: '職位設定', url: 'positions', icon: 'fa-solid fa-arrows-down-to-people', permission: ['system.position.view'], subSite: false },
        { name: '檔案櫃標籤', url: 'library-tags', icon: 'fa-solid fa-tags', permission: 'system.library-tag.view', subSite: false },
        { name: '通訊錄PDF服務項目管理', url: 'contact-others', icon: 'fa-solid fa-address-book', permission: 'system.contact-other.view', subSite: false },
        { name: '會議室及資源項目', url: 'resources', icon: 'fa-solid fa-clipboard-check', permission: 'site.resource.view', subSite: true },
        { name: 'FAQ類別', url: 'faq-types', icon: 'fa-solid fa-question', permission: ['site.knowledge-type.view'], subSite: true },
        { name: '討論區類別', url: 'forum-categories', icon: 'fa-solid fa-comments', permission: ['site.forum-category.view'], subSite: true },
        { name: '執行報告', url: 'performance-reports', icon: 'fa-regular fa-file', permission: 'system.performance-report.manage', subSite: false },
        { name: '主管經理', url: 'line-of-supervisions', icon: 'fa-solid fa-binoculars', permission: ['system.line-of-supervision.view'], subSite: false },
        { name: '選單捷徑', url: 'menu-shortcut', icon: 'fa-solid fa-link', permission: ['site.menu-shortcut.view'], subSite: true },
        { name: '系統紀錄', url: 'audit-logs', icon: 'fa-solid fa-file-lines', permission: ['system.audit-log.view'], subSite: false }
      ]
    }
  },
  computed: {
    filteredMenu() {
      return this.menu.filter(item => { return (item.permission == null || this.checkPermission(this.permissions, item.permission)) && (item.subSite || this.currentSite.site_id === 1) })
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    permissions(val) {
      this.init()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.permissions) {
        setTimeout(() => {
          this.mounted = true
        }, 2000)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
