import dayjs from 'dayjs'
import { partial } from 'filesize'

const helpers = {
  methods: {
    cutText(text, length) {
      if (text.split(' ').length > 1) {
        const string = text.substring(0, length)
        const splitText = string.split(' ')
        splitText.pop()
        return splitText.join(' ') + '...'
      } else {
        return text
      }
    },
    formatDate(date, format) {
      return dayjs(date).format(format)
    },
    getWeekday(date) {
      const days = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
      return days[dayjs(date).day()]
    },
    getFileSize(fileSize) {
      const size = partial({ base: 10, standard: 'jedec' })
      return size(fileSize)
    },
    capitalizeFirstLetter(string) {
      if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      }
    },
    onlyNumber(number) {
      if (number) {
        return number.replace(/\D/g, '')
      } else {
        return ''
      }
    },
    formatCurrency(number) {
      if (number) {
        const formattedNumber = number.toString().replace(/\D/g, '')
        const rest = formattedNumber.length % 3
        let currency = formattedNumber.substr(0, rest)
        const thousand = formattedNumber.substr(rest).match(/\d{3}/g)
        let separator

        if (thousand) {
          separator = rest ? '.' : ''
          currency += separator + thousand.join('.')
        }

        return currency
      } else {
        return ''
      }
    },
    timeAgo(time) {
      const date = new Date(
        (time || '').replace(/-/g, '/').replace(/[TZ]/g, ' ')
      )
      const diff = (new Date().getTime() - date.getTime()) / 1000
      const dayDiff = Math.floor(diff / 86400)

      if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) {
        return dayjs(time).format('MMMM DD, YYYY')
      }

      return (
        (dayDiff === 0 &&
          ((diff < 60 && 'just now') ||
            (diff < 120 && '1 minute ago') ||
            (diff < 3600 && Math.floor(diff / 60) + ' minutes ago') ||
            (diff < 7200 && '1 hour ago') ||
            (diff < 86400 &&
              Math.floor(diff / 3600) + ' hours ago'))) ||
        (dayDiff === 1 && 'Yesterday') ||
        (dayDiff < 7 && dayDiff + ' days ago') ||
        (dayDiff < 31 && Math.ceil(dayDiff / 7) + ' weeks ago')
      )
    },
    diffTimeByNow(time) {
      const startDate = dayjs(dayjs().format('YYYY-MM-DD HH:mm:ss').toString())
      const endDate = dayjs(
        dayjs(time).format('YYYY-MM-DD HH:mm:ss').toString()
      )

      const duration = dayjs.duration(endDate.diff(startDate))
      const milliseconds = Math.floor(duration.asMilliseconds())

      const days = Math.round(milliseconds / 86400000)
      const hours = Math.round((milliseconds % 86400000) / 3600000)
      let minutes = Math.round(((milliseconds % 86400000) % 3600000) / 60000)
      const seconds = Math.round(
        (((milliseconds % 86400000) % 3600000) % 60000) / 1000
      )

      if (seconds < 30 && seconds >= 0) {
        minutes += 1
      }

      return {
        days: days.toString().length < 2 ? '0' + days : days,
        hours: hours.toString().length < 2 ? '0' + hours : hours,
        minutes: minutes.toString().length < 2 ? '0' + minutes : minutes,
        seconds: seconds.toString().length < 2 ? '0' + seconds : seconds
      }
    },
    isset(obj) {
      return Object.keys(obj).length
    },
    assign(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    randomNumbers(from, to, length) {
      const numbers = [0]
      for (let i = 1; i < length; i++) {
        numbers.push(Math.ceil(Math.random() * (from - to) + to))
      }

      return numbers
    },
    replaceAll(str, find, replace) {
      return str.replace(new RegExp(find, 'g'), replace)
    },
    toRGB(colors) {
      const tempColors = Object.assign({}, colors)
      const rgbColors = Object.entries(tempColors)
      for (const [key, value] of rgbColors) {
        if (typeof value === 'string') {
          if (value.replace('#', '').length === 6) {
            const aRgbHex = value.replace('#', '').match(/.{1,2}/g)
            tempColors[key] = (opacity = 1) =>
              `rgb(${parseInt(aRgbHex[0], 16)} ${parseInt(
                aRgbHex[1],
                16
              )} ${parseInt(aRgbHex[2], 16)} / ${opacity})`
          }
        } else {
          tempColors[key] = helpers.toRGB(value)
        }
      }
      return tempColors
    },
    getValidUrl(url) {
      const pattern = /^((http|https|ftp):\/\/)/

      if (!pattern.test(url)) {
        url = 'http://' + url
      }

      return url
    },
    getUserSearchLabel(user) {
      let centerText = ''
      if (user.centre != null) {
        centerText = `[${user.centre.abbrev}]`
      }

      return `${user.staff_number ?? 'NO STAFF NUMBER'} - ${user.full_name} (${user.email}) ${centerText}`
    },
    datepickerDisabledStartDate(date, endAt) {
      const now = new Date()
      return (endAt && date > new Date(endAt.getFullYear(), endAt.getMonth(), endAt.getDate())) || date < new Date(now.getFullYear(), now.getMonth(), now.getDate())
    },
    datepickerDisabledStartTime(date, endAt) {
      const now = new Date()
      return (endAt && date >= new Date(endAt.getFullYear(), endAt.getMonth(), endAt.getDate(), endAt.getHours(), endAt.getMinutes(), endAt.getSeconds())) || date < new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds())
    },
    datepickerDisabledEndtDate(date, startAt) {
      const compareDate = startAt ?? new Date()
      return date < new Date(compareDate.getFullYear(), compareDate.getMonth(), compareDate.getDate())
    },
    datepickerDisabledEndTime(date, startAt) {
      const compareDate = startAt ?? new Date()
      return date <= new Date(compareDate.getFullYear(), compareDate.getMonth(), compareDate.getDate(), compareDate.getHours(), compareDate.getMinutes(), compareDate.getSeconds())
    }
  }
}

export default helpers
