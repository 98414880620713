<template>
  <div id="menu-shortcuts-page">

    <h2 v-if="currentSite != null" class="intro-y text-lg font-medium mt-10">
      <template v-if="currentSite">{{ currentSite.name }}的</template>捷徑 (共{{ shortcuts.length }}個)
    </h2>

    <div v-if="checkPermission(permissions, 'site.menu-shortcut.view')">
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
          <router-link to="/management/create-menu-shortcut" class="btn btn-primary shadow-md mr-auto" v-if="checkPermission(permissions, 'site.menu-shortcut.create')">新增捷徑</router-link>
          <div v-show="loading" class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0 flex">
            <span><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>
        </div>
        <!-- BEGIN: Data List -->
        <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
          <table class="table table-report -mt-2">
            <thead>
            <tr>
              <th class="whitespace-nowrap">標題</th>
              <th class="whitespace-nowrap">網址</th>
              <th class="text-center whitespace-nowrap">開新視窗</th>
              <th class="whitespace-nowrap">顯示次序</th>
              <th class="text-center whitespace-nowrap">公開</th>
              <th v-if="checkPermission(permissions, 'site.menu-shortcut.edit') || checkPermission(permissions, 'site.menu-shortcut.delete')" class="text-center whitespace-nowrap">動作</th>
            </tr>
            </thead>
            <tbody>
            <tr class="" v-for="(item, key) in shortcuts" :key="key">
              <td class="">{{ item.name }}</td>
              <td class=""><a v-if="item.url != null && item.url !== ''" :href="item.url" target="_blank">{{ item.url }}</a><span v-else>-</span></td>
              <td class="w-40">
                <div v-if="item.is_open_newwindow" class="flex items-center justify-center text-success">
                  <font-awesome-icon icon="fa-regular fa-circle-check" class="fa-2x" />
                </div>
                <div v-else class="flex items-center justify-center text-danger">
                  <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-2x" />
                </div>
              </td>
              <td class="">{{ item.display_order }}</td>
              <td class="w-40">
                <div v-if="item.is_effective" class="flex items-center justify-center text-success">
                  <font-awesome-icon icon="fa-regular fa-circle-check" class="fa-2x" />
                </div>
                <div v-else class="flex items-center justify-center text-danger">
                  <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-2x" />
                </div>
              </td>
              <td v-if="checkPermission(permissions, 'site.menu-shortcut.edit') || checkPermission(permissions, 'site.menu-shortcut.delete')" class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <router-link v-if="checkPermission(permissions, 'site.menu-shortcut.edit')" :to="`/management/update-menu-shortcut/${item.shortcut_id}`" class="flex items-center mr-3">
                    <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                    &nbsp;更改 </router-link>
                  <button v-if="checkPermission(permissions, 'site.menu-shortcut.delete')" class="flex items-center text-danger" @click="openDeleteModal(item.shortcut_id)">
                    <font-awesome-icon icon="fa-regular fa-trash-can" />
                    &nbsp;刪除 </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- END: Data List -->
        <!-- BEGIN: Pagination -->
        <pagination-row :pages="pages" :current-page.sync="currentPage"
                        :item-per-page.sync="itemPerPage"></pagination-row>
        <!-- END: Pagination -->
      </div>

      <!-- BEGIN: Delete Confirmation Modal -->
      <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="p-5 text-center">
                <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger" />
                <div class="text-3xl mt-5">你確定嗎？</div>
                <div class="text-slate-500 mt-2">
                  你是否要刪除此捷徑？
                  <br>
                  這動作不能還原。
                </div>
              </div>
              <div class="px-5 pb-8 text-center">
                <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
                <button type="button" class="btn btn-danger w-24" @click="onDelete">確定</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Delete Confirmation Modal -->

    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'permissions', 'currentSite'],
  data() {
    return {
      mounted: false,
      loading: 0,

      sites: [],
      shortcuts: [],
      filterShortcuts: [],
      deleteId: null,

      itemPerPage: 10,
      currentPage: 0,
      pages: 0
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentPage(val) {
      this.updateList()
    },
    itemPerPage(val) {
      this.pages = Math.ceil(this.filterShortcuts.length / val)
      if (this.currentPage === 1) {
        this.updateList()
      }

      this.currentPage = 1
    },
    currentSite(val) {
      if (val) {
        this.init()
      }
    }
  },
  async mounted() {
    window.tailwind.svgLoader()

    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.loadMenuShortcuts()
        this.mounted = true
      }
    },
    async loadMenuShortcuts() {
      this.loading++

      this.filterShortcuts = this.shortcuts = await this.getSiteMenuShortcuts(this.currentSite.site_id)
      this.pages = Math.ceil(this.filterShortcuts.length / this.itemPerPage)
      this.currentPage = 1

      this.loading--
    },
    updateList() {
      this.shortcuts = this.filterShortcuts.slice((this.currentPage - 1) * this.itemPerPage, (this.currentPage - 1) * this.itemPerPage + this.itemPerPage)
    },
    openDeleteModal(id) {
      this.deleteId = id

      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDelete() {
      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      const response = await this.deleteSiteMenuShortcut(this.currentSite.site_id, this.deleteId)

      let option = {
        title: '成功',
        message: '捷徑已被刪除',
        type: 'success'
      }

      if (response.code === 0) {
        await this.loadMenuShortcuts()
      } else {
        option = {
          title: '未能刪除捷徑',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    }
  }
}
</script>

<style lang="scss" scoped>
#menu-shortcuts-page {
    .tab-content {
        background-color: #FFF;
    }
}
</style>
