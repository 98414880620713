<template>
  <div id="result-page">
    <h2 class="intro-y text-lg font-medium mt-10">
      「{{ search }}」的搜尋結果
      <span v-show="!resultLoaded"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
    </h2>

    <div>
      <div class="box mt-5 p-5">
        <h3 class="intro-y text-lg font-medium">
          檔案櫃
          <span v-show="resultLoaded">(共{{ libraryItems.length }}個結果)</span>
        </h3>

        <div class="leading-relaxed">
          <div v-for="(item, key) in libraryItems" :key="key" class="result library-result py-2">
            <div class="flex flex-row align-items-center">
              <span>位置：<router-link to="/library">檔案櫃</router-link></span>
              <span v-for="(folder, i) in item.parentFolders" :key="i">
                <span class="ml-1 mr-1">/</span>

                <span class="">
                  <router-link :to="`/library/${folder.item_code}`">{{ folder.item_name }}</router-link>
                </span>
              </span>
            </div>

            <div class="mb-2">
              網址：<router-link :to="item.item_type === 'DIRECTORY' ? `/library/${item.item_code}` : (item.parentFolder ? `/library/${item.parentFolder.item_code}` : '/library')">{{ host + (item.item_type === 'DIRECTORY' ? `/library/${item.item_code}` : (item.parentFolder ? `/library/${item.parentFolder.item_code}` : '/library'))  }}</router-link>
            </div>

            <button type="button" class="w-full flex align-items-center" @click="onClickItem(item)">
              <div class="mr-3 item-icon bg-contain"
                   :class="{'folder': item.item_type === 'DIRECTORY', 'file': item.item_type === 'FILE'}"><span
                v-if="item.item_type === 'FILE'">{{ getExtension(item) }}</span></div>
              {{ item.item_name ?? '-' }}
            </button>

            <div v-if="item.tags != null && item.tags.length > 0" class="">
              <div class="flex align-items-center tag-containers">
                <button type="button" class="bg-primary text-white tag" v-for="(tag, j) in item.tags" :key="j" @click="onClickTag(tag)"> {{ tag.tag_name }} </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box mt-5 p-5">
        <h3 class="intro-y text-lg font-medium" v-if="currentSite != null">
          {{ currentSite.name }}
          <span v-show="resultLoaded">(共{{ others.total }}個結果)</span>
        </h3>

        <div class="mb-5">
          <div class="leading-relaxed">
            <div v-for="(result, key) in others.data" :key="key" class="result other-result py-2">
              <router-link :to="getLink(result)">
                <div class="ml-2 type"><font-awesome-icon :icon="getObjectValue(types, 'value', result.type, 'icon')" class="fa-1x" /> {{ getObjectValue(types, 'value', result.type, 'name') }}</div>
                <div class="ml-2 title" v-html="getContent(result.item, ['thread', 'type', 'title', 'full_name'])">
                </div>
                <div class="ml-2 search-content" v-html="getContent(result.item, ['content', 'message'])">
                </div>
              </router-link>
            </div>
          </div>
        </div>

        <div v-if="resultLoaded">
          <!-- BEGIN: Pagination -->
          <pagination-row :pages="pages" :current-page.sync="currentPage"
                          :item-per-page.sync="itemPerPage"></pagination-row>
          <!-- END: Pagination -->
        </div>
      </div>
    </div>

    <!-- BEGIN: View Modal -->
    <div id="view-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="flex justify-between align-items-center view-toolbar">
        <button type="button" @click="onDownload(viewItem)" class="btn-download text-white">
          <font-awesome-icon icon="fa-solid fa-cloud-arrow-down" class="fa-2x"/>
        </button>

        <button type="button" data-tw-dismiss="modal" class="btn-close text-white">
          <font-awesome-icon icon="fa-solid fa-xmark" class="fa-2x"/>
        </button>
      </div>

      <div class="modal-dialog">
        <img v-if="viewItem != null && viewItem.file.format.mime_type.startsWith('image/')" :src="viewItem.view_url">
      </div>

      <div v-if="viewItem != null && !viewItem.file.format.mime_type.startsWith('image/')">
        <embed v-if="viewItem.file.format.mime_type === 'application/pdf'" :src="viewItem.view_url" class="iframe-viewer"/>
        <iframe
          v-else-if="isViewable(viewItem)"
          :src="`https://view.officeapps.live.com/op/embed.aspx?src=${viewItem.view_url}`" frameborder="0"
          class="iframe-viewer"></iframe>
      </div>

    </div>
    <!-- END: View Modal -->
  </div>
</template>

<script>
export default {
  props: ['auth', 'currentSite', 'permissions'],
  data() {
    return {
      mounted: false,

      libraryItems: [],
      others: { data: [], total: 0 },

      downloading: false,
      resultLoaded: false,

      search: '',

      itemPerPage: 50,
      currentPage: 1,
      pages: 0,

      types: [
        { name: '報告板', url: '/announcements', icon: 'fa-solid fa-rss', value: 'announcements', id: 'announcement_id' },
        // { name: '行事曆', url: '/calendar', icon: 'fa-regular fa-calendar', value: '' },
        { name: '通訊錄', url: '/address-books', icon: 'fa-regular fa-address-book', value: 'contacts', id: 'contact_id' },
        // { name: '檔案櫃', url: '/drive', icon: 'fa-regular fa-hard-drive', value: '' },
        // { name: '申請表', url: '/workflows', icon: 'fa-solid fa-table-columns', value: null },
        // { name: '系統管理', url: '/management', icon: 'fa-solid fa-gear', value: null },
        // { name: '問巻/電子表格', url: '/questionnaires', icon: 'fa-regular fa-file-lines', value: '' },
        // { name: '會議室及資源預借', url: '/resource-bookings', icon: 'fa-solid fa-clipboard-check', value: '' },
        { name: 'FAQ', url: '/faq', icon: 'fa-regular fa-circle-question', value: 'knowledge_bases', id: 'knowledge_id' },
        { name: '討論區主題', url: '/forum/threads', icon: 'fa-regular fa-comment', value: 'forum_threads', id: 'thread_id' },
        { name: '討論區留言', url: '/forum/threads', icon: 'fa-regular fa-comment', value: 'forum_posts', id: 'thread_id' }
        // { name: '執行報告', url: '/report', icon: 'fa-regular fa-file', value: null }
      ],

      viewItem: null,

      host: null
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentSite() {
      this.init()
    },
    '$route.params'() {
      this.libraryItems = []
      this.others = { data: [], total: 0 }

      this.search = this.$route.query.search ?? ''
      this.loadSearch()
    },
    currentPage(val) {
      this.loadSearch(false)
    },
    itemPerPage(val) {
      this.loadSearch()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    window.tailwind.svgLoader()

    await this.init()
  },
  methods: {
    async init() {
      this.host = window.location.protocol + '//' + window.location.host
      this.search = this.$route.query.search ?? ''
      this.lastSearch = this.search

      if (this.currentSite != null) {
        await this.loadSearch()
        this.mounted = true
      }
    },
    async loadSearch(resetPage = true) {
      this.resultLoaded = false

      if (resetPage) {
        this.currentPage = 1
      }

      if (this.search == null || this.search === '') {
        this.libraryItems = []
        this.others = { data: [], total: 0 }
        return
      }

      this.search = this.search.trim()
      const response = await this.getSearchResult(this.currentSite.site_id, this.search, this.itemPerPage, this.currentPage)
      this.libraryItems = response.library_items
      for (let i = 0; i < this.libraryItems.length; i++) {
        this.libraryItems[i].parentFolders = []
        this.getAllParentFolders(this.libraryItems[i], this.libraryItems[i].parentFolder)
      }
      this.others = response.others
      this.pages = response.others.total_pages

      this.resultLoaded = true
    },
    getAllParentFolders(item, parentFolder) {
      if (parentFolder != null) {
        item.parentFolders.unshift(parentFolder)

        this.getAllParentFolders(item, parentFolder.parentFolder)
      }
    },
    async onClickItem(item) {
      if (item.item_type === 'DIRECTORY') {
        this.$router.push(`/library/${item.item_code}`)
      } else {
        const response = await this.getLibraryItem(this.currentSite.site_id, item.item_code)

        if (response.code === 0) {
          this.viewItem = response.data

          if (this.isViewable(this.viewItem)) {
            const el = document.querySelector('#view-modal')
            const modal = window.tailwind.Modal.getOrCreateInstance(el)
            modal.show()
          } else {
            this.onDownload(this.viewItem)
          }
        }
      }
    },
    async onDownload(item, filename = null, versionId = '') {
      this.downloading = true
      const response = await this.downloadLibraryItem(this.currentSite.site_id, item.item_code, versionId)
      const blob = new Blob([response], { type: item.file.format.mime_type })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = filename ?? item.file.file_name
      link.click()
      URL.revokeObjectURL(link.href)
      this.downloading = false
    },
    onClickTag(tag) {
      this.search = tag.tag_name
      this.loadSearch()

      if (this.lastSearch !== this.search) {
        this.$router.push({
          path: '/result',
          query: { search: this.search }
        })
      }

      this.lastSearch = this.search
    },
    getLink(result) {
      if (result.type === 'knowledge_bases') {
        return this.getObjectValue(this.types, 'value', result.type, 'url') + `?type_id=${result.item.type_id}&knowledge_id=${result.item.knowledge_id}`
      }

      return this.getObjectValue(this.types, 'value', result.type, 'url') + '/' + result.item[this.getObjectValue(this.types, 'value', result.type, 'id')]
    },
    getContent(item, types = []) {
      let key = null

      for (let i = 0; i < types.length; i++) {
        if (item[types[i]] != null) {
          key = types[i]
          break
        }
      }

      if (key == null) {
        return
      }

      let searchContent = item[key] ?? ''

      if (typeof searchContent === 'object') {
        searchContent = searchContent.title ?? searchContent.name ?? ''
      }

      searchContent = searchContent.replace(/<\/?[^>]+(>|$)/g, '').replaceAll('&nbsp;', '')

      const position = searchContent.toLowerCase().search(this.search.toLowerCase())

      if (position >= 0) {
        let front = searchContent.slice(0, position)
        let back = searchContent.slice(position + this.search.length)

        if (front.length > 100) {
          front = '......' + front.slice(-100)
        }

        if (back.length > 100) {
          back = back.slice(0, 100) + '......'
        }

        return front + '<span class="high-light">' + searchContent.slice(position, position + this.search.length) + '</span>' + back
      }

      if (searchContent !== '') {
        return (searchContent.length > 100) ? searchContent.slice(0, 100) + '......' : searchContent
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#result-page {
  .result {
    margin: 4px 0;
    border-bottom: 1px solid rgb(var(--color-slate-200));

    &:last-child {
      border: none;

      .title {
        border: none;
      }
    }
  }

  .library-result {
    .item-icon {
      width: 40px;
      height: 40px;
    }

    .bg-contain {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    .folder {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='786' height='786' viewBox='0 0 786 786'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%238a97ac'/%3E%3Cstop offset='1' stop-color='%235d6c83'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg id='Group_3' data-name='Group 3' transform='translate(-567 -93)'%3E%3Crect id='Rectangle_4' data-name='Rectangle 4' width='418' height='681' rx='40' transform='translate(896 109)' fill='%2395a5b9'/%3E%3Crect id='Rectangle_3' data-name='Rectangle 3' width='433' height='681' rx='40' transform='translate(606 93)' fill='%23a0aec0'/%3E%3Crect id='Rectangle_2' data-name='Rectangle 2' width='742' height='734' rx='40' transform='translate(590 145)' fill='%23bec8d9'/%3E%3Crect id='Rectangle_5' data-name='Rectangle 5' width='786' height='692' rx='40' transform='translate(567 187)' fill='url(%23linear-gradient)'/%3E%3C/g%3E%3C/svg%3E%0A");
    }

    .file {
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='628.027' height='786.012' viewBox='0 0 628.027 786.012'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%238a97ac'/%3E%3Cstop offset='1' stop-color='%235d6c83'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg id='Group_5' data-name='Group 5' transform='translate(-646 -92.988)'%3E%3Cpath id='Union_2' data-name='Union 2' d='M40,786A40,40,0,0,1,0,746V40A40,40,0,0,1,40,0H501V103h29v24h98V746a40,40,0,0,1-40,40Z' transform='translate(646 93)' fill='url(%23linear-gradient)'/%3E%3Cpath id='Intersection_2' data-name='Intersection 2' d='M.409,162.042l.058-109.9c31.605,29.739,125.37,125.377,125.37,125.377l-109.976.049A20.025,20.025,0,0,1,.409,162.042Z' transform='translate(1147 42)' fill='%23bec8d9' stroke='%23bec8d9' stroke-width='1'/%3E%3C/g%3E%3C/svg%3E%0A");

      span {
        color: #fff;
        font-size: 11px;
      }
    }
  }

  .other-result {
    .type {
      font-size: 15px;
      color: rgb(var(--color-primary) / var(--tw-bg-opacity));
    }

    .title {
      position: relative;
      width: 100%;
      font-size: 17px;
      color: rgb(var(--color-primary) / var(--tw-bg-opacity));
      font-weight: bold;
    }
  }

  ::v-deep .high-light {
    background-color: rgba(245, 158, 11, 0.6);
  }

  .tag-containers {
    .tag {
      margin-top: 8px;
      padding: 2px 6px;
      border-radius: 4px;
      margin-left: 8px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
</style>
