<template>
  <div id="side-menu">
    <!-- BEGIN: Side Menu -->
    <nav class="side-nav" v-if="currentSite">
      <ul>
        <li v-for="(item, key) in menuItems" :key="key">
          <a v-if="item.externalLink" :href="item.url" :target="item.target" class="side-menu">
            <div class="side-menu__icon"><font-awesome-icon :icon="item.icon" class="fa-2x fa-fw" /></div>
            <div class="side-menu__title"> {{ item.name }}</div>
          </a>
          <router-link v-else-if="item.sitePermission && (item.permission == null || checkPermission(permissions, item.permission))" :to="item.url" :target="item.target" class="side-menu" :class="{'side-menu--active': isActive(item)}">
            <div class="side-menu__icon"><font-awesome-icon :icon="item.icon" class="fa-2x fa-fw" /></div>
            <div class="side-menu__title"> {{ item.name }}</div>
          </router-link>
        </li>
      </ul>
    </nav>
    <!-- END: Side Menu -->
  </div>
</template>

<script>
export default {
  props: ['currentSite', 'permissions'],
  data() {
    return {
      menuItems: []
    }
  },
  watch: {
    currentSite(val) {
      this.init()
    }
  },
  mounted() {
    this.sideMenu()
    this.$emit('pass-pages', this.pages)
    this.init()
  },
  methods: {
    init() {
    },
    isActive(item) {
      return this.$route.path.startsWith(item.url)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
