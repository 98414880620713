import { render, staticRenderFns } from "./CreateUserPage.vue?vue&type=template&id=28071749&scoped=true&"
import script from "./CreateUserPage.vue?vue&type=script&lang=js&"
export * from "./CreateUserPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28071749",
  null
  
)

export default component.exports